.tools-container {
  position: relative;
  max-width: 90%;
  width: 100%;
  margin: 0 auto;
  padding: 50px;
  height: 100vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  scrollbar-width: none;
  /* Firefox */
  -ms-overflow-style: none;
  /* IE and Edge */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.tools-container::-webkit-scrollbar {
  display: none;
}

.tools-title {
  margin-bottom: 32px;
  color: var(--color-text);
  font-weight: var(--font-weight-bold);
  color: black;
  margin-top: 0;
  margin-bottom: 24px;
  font-size: 1.8rem;
  font-weight: 600;
}

.tools-title-upcoming {
  margin-top: 64px;
}

.tool-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  margin-bottom: 12px;
}

.beta-tag {
  background-color: rgba(255, 255, 255, 0.3);
  padding: 2px 8px;
  border-radius: 4px;
  font-size: 0.7rem;
  font-weight: 600;
  letter-spacing: 0.5px;
  transform: translateY(-2px);
  margin-left: auto;
  margin-bottom: 10px;
}

.tool-description {
  font-weight: 400;
}

.tools-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
  margin: 0 24px;
}

.tool-card {
  background: linear-gradient(
    135deg,
    hsla(30, 89%, 54%, 0.9),
    hsla(294, 80%, 58%, 0.9)
  );
  border-radius: 24px;
  padding: 32px;
  min-height: 180px;
  transition: all 0.2s ease;
  cursor: pointer;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.tool-card.coming-soon {
  background: linear-gradient(
    135deg,
    hsla(0, 0%, 60%, 0.9),
    hsla(0, 0%, 40%, 0.9)
  );
  cursor: not-allowed;
  position: relative;
}

.tool-card.coming-soon:hover::after {
  content: "Coming Soon!";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 8px 16px;
  border-radius: 4px;
  font-size: 14px;
  white-space: nowrap;
  z-index: 1;
}

.tool-card.coming-soon .tool-content {
  opacity: 0.7;
}

.tool-content {
  color: white;
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-semibold);
  line-height: 1.4;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.tool-content h3 {
  margin-bottom: 12px;
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-semibold);
}

.tool-content p {
  font-size: var(--font-size-md);
  color: rgba(255, 255, 255, 0.9);
  line-height: 1.5;
}

.tool-card:hover {
  transform: translateY(-4px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
}

.tool-tags {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  margin-top: 16px;
}

.tool-tag {
  font-size: var(--font-size-xs);
  padding: 4px 12px;
  border-radius: 12px;
  background-color: rgba(255, 255, 255, 0.2);
  color: white;
  font-weight: var(--font-weight-medium);
  backdrop-filter: blur(4px);
}

/* Add responsive design for smaller screens */
@media screen and (max-width: 1024px) {
  .tools-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 640px) {
  .tools-grid {
    grid-template-columns: 1fr;
  }

  .tools-container {
    padding: 20px;
  }

  .tools-title-upcoming {
    margin-top: 40px;
  }
}

.file-upload-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1100;
}
