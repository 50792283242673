:root {
  /* Brand Colors - Same for both themes */
  --color-primary: hsl(30, 89%, 54%);
  --color-secondary: hsl(294, 80%, 58%);
  --color-secondary-light: rgb(239, 233, 240);
  --color-error: #c4002f;
  --color-success: #4caf50;

  /* Light Theme Colors */
  --light-text: #222;
  --light-text-medium: #444;
  --light-text-light: #666;
  --light-text-secondary-light: hsl(290, 57%, 82%);
  --light-gray-extra-light: #f1f1f1;
  --light-gray-light: #e9e9e9;
  --light-gray-medium-light: #d4d4d4;
  --light-gray-medium: rgb(211, 211, 211);
  --light-gray-medium-dark: #b9b9b9;
  --light-gray-dark: #9c9c9c;
  --light-border: #c9c9c9;
  --light-link: #c4002f;
  --light-background: #dddddd;
  --light-background-dark: var(--color-background-dark) 333;
  --light-highlight: #939393;
  --light-selection: rgba(51, 51, 51, 0.2);
  --light-selection-edge: var(--color-background-dark);
  --light-background-transparent: rgba(242, 138, 34, 0.22);
  --light-text-dark: #111;
  --light-topbar-background: #e0e0e0;
  --light-tab-background: #dadada;
  --light-tab-active: var(--light-gray-medium);
  --light-tab-hover: #e8e8e8;
  --light-project-group-background: #d7d7d787;
  --light-shadow-soft: rgba(0, 0, 0, 0.05);
  --light-shadow-medium: rgba(0, 0, 0, 0.1);
  --light-border-transparent: rgba(51, 51, 51, 0.5);
  --light-hover-overlay: rgba(0, 0, 0, 0.05);
  --light-divider: #e0e0e0;
  --light-input-background: #dfdfdf;
  --light-input-border: #b9b9b9;
  --light-secondary-shadow: rgba(217, 61, 234, 0.2);
  --light-handle-hover: #999;
  --light-empty-background: #f8f9fa;
  --light-loading-overlay: rgba(255, 255, 255, 0.8);
  --light-spec-background: #f5f5f5;
  --light-spec-border: #ddd;
  --light-overlay-background: rgba(0, 0, 0, 0.5);
  --light-tooltip-background: rgba(0, 0, 0, 0.8);
  --light-tooltip-text: #fff;
  --light-disabled: #ccc;
  --light-tooltip-border: #666;
  --light-button-primary: #f28a22;
  --light-button-primary-hover: #d97a1c;
  --light-button-primary-active: #c06a18;

  /* Dark Theme Colors - VSCode-like */
  --dark-text: #d4d4d4;
  --dark-text-medium: #c8c8c8;
  --dark-text-light: #a6a6a6;
  --dark-text-secondary-light: #c586c0;
  --dark-text-dark: #2d2d2d;
  --dark-gray-extra-light: #252526;
  --dark-gray-light: #1e1e1e;
  --dark-gray-medium-light: var(--color-background-dark) 333;
  --dark-gray-medium: #111;
  --dark-gray-medium-dark: #454545;
  --dark-gray-dark: #4d4d4d;
  --dark-border: #3f3f3f;
  --dark-link: #3794ff;
  --dark-background: #1e1e1e;
  --dark-background-dark: #181818;
  --dark-highlight: #3e3e3e;
  --dark-selection: rgba(56, 127, 240, 0.3);
  --dark-selection-edge: #add6ff;
  --dark-background-transparent: rgba(30, 30, 30, 0.85);
  --dark-topbar-background: #2d2d2d;
  --dark-tab-background: #252526;
  --dark-tab-active: var(--dark-gray-light);
  --dark-tab-hover: var(--color-background-dark) 333;
  --dark-project-group-background: rgba(51, 51, 51, 0.5);
  --dark-shadow-soft: rgba(0, 0, 0, 0.15);
  --dark-shadow-medium: rgba(0, 0, 0, 0.2);
  --dark-border-transparent: rgba(80, 80, 80, 0.5);
  --dark-hover-overlay: rgba(255, 255, 255, 0.05);
  --dark-divider: #3f3f3f;
  --dark-input-background: #2d2d2d;
  --dark-input-border: #3f3f3f;
  --dark-secondary-shadow: rgba(217, 61, 234, 0.1);
  --dark-handle-hover: #666;
  --dark-empty-background: #252526;
  --dark-loading-overlay: rgba(30, 30, 30, 0.8);
  --dark-spec-background: #2d2d2d;
  --dark-spec-border: #3f3f3f;
  --dark-overlay-background: rgba(0, 0, 0, 0.7);
  --dark-tooltip-background: rgba(0, 0, 0, 0.9);
  --dark-tooltip-text: #d4d4d4;
  --dark-disabled: #666;
  --dark-tooltip-border: #888;
  --dark-button-primary: #d97a1c;
  --dark-button-primary-hover: #c06a18;
  --dark-button-primary-active: #a85915;

  /* Default to light theme */
  --color-text: var(--light-text);
  --color-text-medium: var(--light-text-medium);
  --color-text-light: var(--light-text-light);
  --color-text-secondary-light: var(--light-text-secondary-light);
  --color-text-dark: var(--light-text-dark);
  --color-gray-extra-light: var(--light-gray-extra-light);
  --color-gray-light: var(--light-gray-light);
  --color-gray-medium-light: var(--light-gray-medium-light);
  --color-gray-medium: var(--light-gray-medium);
  --color-gray-medium-dark: var(--light-gray-medium-dark);
  --color-gray-dark: var(--light-gray-dark);
  --color-border: var(--light-border);
  --color-link: var(--light-link);
  --color-background: var(--light-background);
  --color-background-dark: var(--light-background-dark);
  --color-highlight: var(--light-highlight);
  --color-selection: var(--light-selection);
  --color-selection-edge: var(--light-selection-edge);
  --color-background-transparent: var(--light-background-transparent);
  --color-topbar-background: var(--light-topbar-background);
  --color-tab-background: var(--light-tab-background);
  --color-tab-active: var(--light-tab-active);
  --color-tab-hover: var(--light-tab-hover);
  --color-project-group-background: var(--light-project-group-background);
  --color-shadow-soft: var(--light-shadow-soft);
  --color-shadow-medium: var(--light-shadow-medium);
  --color-border-transparent: var(--light-border-transparent);
  --color-hover-overlay: var(--light-hover-overlay);
  --color-divider: var(--light-divider);
  --color-input-background: var(--light-input-background);
  --color-input-border: var(--light-input-border);
  --color-secondary-shadow: var(--light-secondary-shadow);
  --color-handle-hover: var(--light-handle-hover);
  --color-empty-background: var(--light-empty-background);
  --color-loading-overlay: var(--light-loading-overlay);
  --color-spec-background: var(--light-spec-background);
  --color-spec-border: var(--light-spec-border);
  --color-overlay-background: var(--light-overlay-background);
  --color-tooltip-background: var(--light-tooltip-background);
  --color-tooltip-text: var(--light-tooltip-text);
  --color-disabled: var(--light-disabled);
  --color-tooltip-border: var(--light-tooltip-border);
  --color-button-primary: var(--light-button-primary);
  --color-button-primary-hover: var(--light-button-primary-hover);
  --color-button-primary-active: var(--light-button-primary-active);

  --sidebar-dropdown-hover: #ffffff1d;
  --file-icon-fill: #686868;

  --color-shadow: rgba(0, 0, 0, 0.2);

  /* Spacing and other constants - same for both themes */
  --padding-small: 0.5rem;
  --padding-medium: 1rem;
  --padding-large: 1.5rem;
  --padding-xlarge: 2rem;
  --border-radius-small: 0.3rem;
  --font-size-xs: 0.8rem;
  --font-size-sm: 0.9rem;
  --font-size-md: 1rem;
  --font-size-md-lg: 1.1rem;
  --font-size-lg: 1.2rem;
  --font-size-xl: 2rem;
  --font-weight-normal: 400;
  --font-weight-medium: 500;
  --font-weight-semibold: 600;
  --font-weight-bold: 700;
}

/* Dark theme (inverted grays, keep primary/secondary/link colors) */
:root[data-theme="dark"] {
  --dark-text: #dddddd;
  --dark-text-medium: #bbbbbb;
  --dark-text-light: #999999;
  /* Approximate inversion of hsl(290, 57%, 82%) -> #0d260d */
  --dark-text-secondary-light: #0d260d;
  /* Invert #111 -> #eee */
  --dark-text-dark: #eeeeee;

  --dark-gray-extra-light: #0e0e0e;
  /* Invert #f1f1f1 */
  --dark-gray-light: #161616;
  /* Invert #e9e9e9 */
  --dark-gray-medium-light: #2b2b2b;
  /* Invert #d4d4d4 */
  --dark-gray-medium: #2c2c2c;
  /* Invert rgb(211,211,211) */
  --dark-gray-medium-dark: #464646;
  /* Invert #b9b9b9 */
  --dark-gray-dark: #636363;
  /* Invert #9c9c9c */

  /* Keep link color per instructions (original dark-link: #3794ff) */
  --dark-link: #3794ff;

  --dark-border: #363636;
  /* Invert #c9c9c9 */
  --dark-background: #222222;
  /* Invert #dddddd */
  --dark-background-dark: #cccccc;
  /* Invert #333 */
  --dark-highlight: #6c6c6c;
  /* Invert #939393 */
  --dark-selection: rgba(204, 204, 204, 0.2);
  /* Invert rgba(51,51,51,0.2) */
  --dark-selection-edge: #cccccc;
  /* Invert #333 */
  --dark-background-transparent: rgba(13, 117, 221, 0.22);
  /* Invert rgba(242,138,34,0.22) */
  --dark-topbar-background: #1f1f1f;
  /* Invert #e0e0e0 */
  --dark-tab-background: #252525;
  /* Invert #dadada */
  --dark-tab-active: #2c2c2c;
  /* Invert #d3d3d3 */
  --dark-tab-hover: #171717;
  /* Invert #e8e8e8 */
  --dark-project-group-background: #28282887;
  /* Invert #d7d7d787 */
  --dark-shadow-soft: rgba(255, 255, 255, 0.05);
  /* Invert rgba(0,0,0,0.05) */
  --dark-shadow-medium: rgba(255, 255, 255, 0.1);
  /* Invert rgba(0,0,0,0.1) */
  --dark-border-transparent: rgba(204, 204, 204, 0.5);
  /* Invert rgba(51,51,51,0.5) */
  --dark-hover-overlay: rgba(255, 255, 255, 0.05);
  /* Invert rgba(0,0,0,0.05) */
  --dark-divider: #1f1f1f;
  /* Invert #e0e0e0 */
  --dark-input-background: #202020;
  /* Invert #dfdfdf */
  --dark-input-border: #464646;
  /* Invert #b9b9b9 */

  /* Keep secondary shadow as-is (brand color) */
  --dark-secondary-shadow: rgba(217, 61, 234, 0.2);

  --dark-handle-hover: #666666;
  /* Invert #999 */
  --dark-empty-background: #070606;
  /* Invert #f8f9fa */
  --dark-loading-overlay: rgba(0, 0, 0, 0.8);
  /* Invert rgba(255,255,255,0.8) => #000 with same alpha */
  --dark-spec-background: #0a0a0a;
  /* Invert #f5f5f5 */
  --dark-spec-border: #222222;
  /* Invert #ddd */
  --dark-overlay-background: rgba(255, 255, 255, 0.5);
  /* Invert rgba(0,0,0,0.5) */
  --dark-tooltip-background: rgba(255, 255, 255, 0.8);
  /* Invert rgba(0, 0, 0, 0.8) */
  --dark-tooltip-text: #000000;
  /* Invert #fff */
  --dark-disabled: #333333;
  /* Invert #ccc */
  --dark-tooltip-border: #999999;
  /* Invert #666 */

  /* Keep primary button colors the same */
  --dark-button-primary: #f28a22;
  --dark-button-primary-hover: #d97a1c;
  --dark-button-primary-active: #c06a18;

  /* Apply to overall dark theme */
  --color-text: var(--dark-text);
  --color-text-medium: var(--dark-text-medium);
  --color-text-light: var(--dark-text-light);
  --color-text-secondary-light: var(--dark-text-secondary-light);
  --color-text-dark: var(--dark-text-dark);
  --color-gray-extra-light: var(--dark-gray-extra-light);
  --color-gray-light: var(--dark-gray-light);
  --color-gray-medium-light: var(--dark-gray-medium-light);
  --color-gray-medium: var(--dark-gray-medium);
  --color-gray-medium-dark: var(--dark-gray-medium-dark);
  --color-gray-dark: var(--dark-gray-dark);
  --color-border: var(--dark-border);
  --color-link: var(--dark-link);
  --color-background: var(--dark-background);
  --color-background-dark: var(--dark-background-dark);
  --color-highlight: var(--dark-highlight);
  --color-selection: var(--dark-selection);
  --color-selection-edge: var(--dark-selection-edge);
  --color-background-transparent: var(--dark-background-transparent);
  --color-topbar-background: var(--dark-topbar-background);
  --color-tab-background: var(--dark-tab-background);
  --color-tab-active: var(--dark-tab-active);
  --color-tab-hover: var(--dark-tab-hover);
  --color-project-group-background: var(--dark-project-group-background);
  --color-shadow-soft: var(--dark-shadow-soft);
  --color-shadow-medium: var(--dark-shadow-medium);
  --color-border-transparent: var(--dark-border-transparent);
  --color-hover-overlay: var(--dark-hover-overlay);
  --color-divider: var(--dark-divider);
  --color-input-background: var(--dark-input-background);
  --color-input-border: var(--dark-input-border);
  --color-secondary-shadow: var(--dark-secondary-shadow);
  --color-handle-hover: var(--dark-handle-hover);
  --color-empty-background: var(--dark-empty-background);
  --color-loading-overlay: var(--dark-loading-overlay);
  --color-spec-background: var(--dark-spec-background);
  --color-spec-border: var(--dark-spec-border);
  --color-overlay-background: var(--dark-overlay-background);
  --color-tooltip-background: var(--dark-tooltip-background);
  --color-tooltip-text: var(--dark-tooltip-text);
  --color-disabled: var(--dark-disabled);
  --color-tooltip-border: var(--dark-tooltip-border);
  --color-button-primary: var(--dark-button-primary);
  --color-button-primary-hover: var(--dark-button-primary-hover);
  --color-button-primary-active: var(--dark-button-primary-active);
  --sidebar-dropdown-hover: rgba(255, 255, 255, 0.1);
  --file-icon-fill: #a0a0a0;
  --color-shadow: rgba(0, 0, 0, 0.4);
}

/* Layout styles for everything */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Font styles for everything except MD editor */
body,
div:not(.w-md-editor-text):not(.w-md-editor-text-pre):not(
    .w-md-editor-text-input
  ),
p:not(.w-md-editor *),
span:not(.w-md-editor *),
button:not(.w-md-editor *),
input:not(.w-md-editor *),
select:not(.w-md-editor *),
textarea:not(.w-md-editor *) {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  /* color: var(--color-text); */
}

ul {
  list-style-type: none;
}

button {
  border: none;
  background-color: unset;
  cursor: pointer;
  font-weight: var(--font-weight-medium);
  color: var(--color-text-dark);
}

.container {
  height: 100%;
  min-height: 0;
  display: flex;
  background-color: var(--color-gray-light);
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease;
  flex: 1;
}

.sidebar {
  width: 16rem;
  height: 100%;
  flex-shrink: 0;
  transition: width 0.3s ease;
}

.sidebar-collapsed .sidebar {
  width: 0;
  overflow: hidden;
}

.main-content-wrapper {
  flex-grow: 1;
  display: flex;
  overflow: hidden;
  position: relative;
  transition: margin-left 0.3s ease;
}

.sidebar-collapsed .main-content-wrapper {
  margin-left: 0;
}

.main {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: var(--padding-medium);
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  transition: max-width 0.3s ease;
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
}

.main.with-artifact {
  max-width: 50%;
  width: 50%;
}

.fullscreen-panel {
  max-width: 100% !important;
  flex: 1;
  width: 100%;
  padding-left: var(--padding-xlarge);
  padding-top: calc(var(--padding-xlarge) * 1.5);
  max-width: 100% !important;
  flex-basis: auto;
}

.main.panel-closed {
  margin: 0 !important;
  padding: 0 !important;
}

.chat-messages-list,
.form-container,
.main-bottom {
  width: 100%;
  max-width: 48rem;
  margin: 0 auto;
  box-sizing: border-box;
  transition: max-width 0.3s ease;
}

.main.with-artifact .chat-messages-list,
.main.with-artifact .form-container,
.main.with-artifact .main-bottom {
  max-width: 100%;
}

ul.chat-messages-list {
  margin: auto !important;
}

.main-bottom {
  margin-right: 0px !important;
  padding-left: 0px !important;
}

.main h1 {
  font-size: var(--font-size-xl);
}

.main-header {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-bottom: var(--padding-medium);
  overflow: auto;
}

.main-header li {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  background-color: var(--color-gray-medium-dark);
  padding: var(--padding-medium);
  margin: var(--padding-medium) 0;
  border-radius: var(--border-radius-small);
}

.main-header li:nth-child(odd) {
  background-color: unset;
}

.main-header li:nth-child(even) {
  background-color: var(--color-gray-medium-dark);
}

.main-header img:not(:nth-child(even)) {
  display: block;
  border-radius: var(--border-radius-small);
  width: 1.8rem;
  height: 1.8rem;
}

.role-title {
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-semibold);
  margin-bottom: 0.5rem;
}

.main-bottom {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
  max-width: 48rem;
  margin: 0 auto;
  box-sizing: border-box;
  padding-bottom: var(--padding-medium);
}

.main-bottom p:first-child {
  padding-top: var(--padding-small);
}

.main-bottom p {
  font-size: var(--font-size-xs);
  text-align: center;
  color: var(--color-text-light);
}

.empty-chat-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
}

/* .empty-chat-container img {
  margin-bottom: 1rem;
} */

.empty-chat-container h1 {
  font-size: var(--font-size-xl);
  margin-bottom: 0.5rem;
}

.empty-chat-container h3 {
  font-size: var(--font-size-lg);
  color: var(--color-text-light);
  margin-bottom: 2rem;
}

.large-buttons-container {
  display: flex;
  justify-content: center;
  gap: 1.5rem;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}

.large-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.75rem;
  padding: 0.75rem 1.25rem;
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-medium);
  color: var(--color-text);
  background-color: var(--color-gray-ultra-light);
  border: 1px solid var(--color-border);
  border-radius: 24px;
  cursor: pointer;
  transition: all 0.2s ease;
  width: fit-content;
  box-shadow: 0 2px 4px var(--color-shadow-soft);
}

.large-button:hover {
  background-color: var(--color-primary);
  transform: translateY(-2px);
  border-color: var(--color-primary);
  color: var(--color-background);
}

.button-icon {
  width: 24px;
  height: 24px;
  margin-top: 2px;
  margin-left: -4px;
}

.errorText {
  margin: 0 auto;
}

#errorTextHint {
  margin: 0 auto;
  opacity: 0.6;
}

.form-container {
  width: 100%;
  max-width: 48rem;
  margin: 0 auto;
  border-radius: var(--border-radius-small);
  display: flex;
  align-items: center;
  background-color: var(--color-gray-medium);
  box-sizing: border-box;
  height: fit-content;
}

.form-container input {
  width: 100%;
  height: 3rem;
  font-size: var(--font-size-sm);
  padding-right: var(--padding-medium);
  background-color: var(--color-gray-medium-dark);
  outline: none;
  border: none;
  color: var(--color-text);
}

.form-container input::placeholder {
  color: var(--color-text-light);
}

.form-container svg {
  fill: var(--color-text-light);
  transform: rotate(-45deg);
}

.form-container svg:hover {
  fill: var(--color-primary);
}

@media screen and (min-width: 1280px) {
  .main-header li {
    margin: var(--padding-medium) auto;
    width: 50rem;
  }

  .input-design-container {
    margin: var(--padding-medium) auto;
  }

  .form-container {
    max-width: 48rem;
  }
}

@media screen and (max-width: 640px) {
  .main-header li {
    gap: 1rem;
  }
}

div.chat-messages-list {
  max-width: none;
  width: 100%;
}

@media screen and (min-width: 1280px) {
  .main:not(.with-artifact) ul.chat-messages-list,
  .main:not(.with-artifact) .form-container,
  .main:not(.with-artifact) .main-bottom {
    max-width: none;
  }

  .message {
    max-width: none;
    margin: 4px 0;
  }
}

@media screen and (max-width: 1279px) {
  .chat-messages-list,
  .form-container,
  .main-bottom {
    max-width: 100%;
  }
}

.main.with-artifact .chat-messages-list,
.main.with-artifact .form-container,
.main.with-artifact .main-bottom {
  max-width: 100%;
}

.main-content {
  position: relative;
  z-index: 1;
}

.artifact-link {
  color: var(--color-link);
  text-decoration: underline;
  cursor: pointer;
}

.artifact-link:hover {
  color: var(--color-secondary);
}

.test-artifact-button {
  margin-top: 10px;
  padding: 5px 10px;
  background-color: var(--color-primary);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
}

.test-artifact-button:hover {
  background-color: var(--color-secondary);
  color: white;
  font-weight: var(--font-weight-semibold);
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: var(--color-text);
}

.artifact-content {
  flex-grow: 1;
  overflow-y: auto;
}

/* Chat message styles */
.chat-messages-list {
  list-style-type: none;
  padding: 0;
  width: 100%;
  max-width: none;
  margin: 0 auto;
  overflow-y: auto;
}

.message {
  display: flex;
  align-items: flex-start;
  margin: var(--padding-medium) 0;
  width: 100%;
  padding-left: 0;
  padding-right: var(--padding-medium);
  /* padding: 0 var(--padding-medium); */
  box-sizing: border-box;
}

.user-message {
  justify-content: flex-end;
  background-color: unset !important;
  padding: 0 !important;
}

.ai-message {
  justify-content: flex-start;
}

.message-content {
  max-width: 80%;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.message-content p {
  /* white-space: pre-wrap; */
  margin: 0;
}

.clonie-logo {
  width: 1.8rem;
  height: 1.8rem;
  margin-right: var(--padding-medium);
  border-radius: var(--border-radius-small);
  flex-shrink: 0;
}

@media screen and (min-width: 1280px) {
  .message {
    max-width: none;
    margin: 4px 0;
  }
}

/* Adjust for smaller screens */
@media screen and (max-width: 768px) {
  .message-content {
    max-width: 90%;
  }
}

/* Adjust when artifact is present */
.main.with-artifact .message-content {
  max-width: 90%;
}

/* Adjust for both smaller screens and artifact presence */
@media screen and (max-width: 768px) {
  .main.with-artifact .message-content {
    max-width: 95%;
  }
}

/* Add these new styles for the toggle button */
.sidebar-toggle {
  position: absolute;
  top: var(--padding-medium);
  left: var(--padding-medium);
  z-index: 1001;
  background: none;
  border: none;
  cursor: pointer;
}

.sidebar-toggle img {
  width: 30px;
  height: 30px;
}

/* Adjust the sidebar content visibility */
.sidebar-content {
  opacity: 1;
  transition: opacity 0.3s ease;
}

.sidebar-collapsed .sidebar-content {
  opacity: 0;
  pointer-events: none;
}

/* Ensure the toggle button is always visible */
.sidebar-collapsed .sidebar-toggle {
  left: var(--padding-medium);
  top: var(--padding-small);
}

/* Adjust the main content when sidebar is collapsed */
.sidebar-collapsed .main-content-wrapper {
  margin-left: 0;
}

/* Replace the existing .loading-overlay and .loading-message styles with these */
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--color-overlay-background);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000;
  /* Ensure it's above all other elements */
}

.loading-message {
  background-color: var(--color-gray-medium-dark);
  padding: var(--padding-large);
  border-radius: 8px;
  max-width: 400px;
  text-align: center;
  color: var(--color-text);
  box-shadow: 0 4px 6px var(--color-shadow-soft);
}

.loading-message img {
  width: 50px;
  height: 50px;
  margin-bottom: var(--padding-medium);
}

.loading-message p {
  margin-top: var(--padding-small);
  font-size: var(--font-size-sm);
}

.container.loading {
  pointer-events: none;
}

.container.loading .loading-overlay {
  pointer-events: auto;
}

/* Add this new rule */
.container.loading * {
  pointer-events: none !important;
}

.container.loading .loading-overlay,
.container.loading .loading-overlay * {
  pointer-events: auto !important;
}

.input-design-container {
  display: flex;
  align-items: flex-end;
  width: 100%;
  max-width: 48rem;
  margin: 0 auto;
  padding: 0 0 0 var(--padding-small);
  box-sizing: border-box;
}

.design-button {
  width: fit-content;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--border-radius-small);
  padding: calc(var(--padding-small) / 2) var(--padding-medium);
  cursor: pointer;
  transform: scale(1);
  transition: background-color 0.2s ease, color 0.2s ease, transform 0.2s ease;
  white-space: nowrap;
  font-weight: var(--font-weight-semibold);
  background-color: var(--color-gray-light);
  color: var(--color-text);
  font-size: var(--font-size-sm);
}

.design-button path {
  color: var(--color-secondary);
  transition: color 0.2s ease;
}

.design-button-container {
  display: flex;
  justify-content: center;
  padding-bottom: var(--padding-medium);
  padding-top: 0.2rem;
  border-bottom: 2px solid var(--color-border);
  width: 100%;
}

.design-button:hover {
  transform: scale(1.01);
  background-color: var(--color-secondary) !important;
  color: var(--color-gray-light);
}

.design-button:hover path {
  color: var(--color-gray-light);
}

.design-button svg {
  margin-right: var(--padding-small);
  margin-bottom: -1px;
}

*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background: var(--color-gray-medium);
}

*::-webkit-scrollbar-thumb {
  background-color: var(--color-gray-dark);
  border-radius: 6px;
  border: 3px solid var(--color-gray-medium);
}

.popup-message {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: var(--color-gray-medium-dark);
  color: var(--color-text);
  padding: var(--padding-medium);
  border-radius: var(--border-radius-small);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  max-width: 300px;
  max-height: 100px;
  z-index: 1001;
  gap: var(--padding-small);
}

.popup-message p {
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  flex: 1;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  line-clamp: 4;
  -webkit-box-orient: vertical;
  line-height: 1.3;
}

.popup-message button {
  background: none;
  border: none;
  font-size: 1.2rem;
  cursor: pointer;
  color: var(--color-text);
}

.popup-message button:hover {
  color: var(--color-primary);
}

/* Add these new styles */
.initial-loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--color-gray-light);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.sidebar-collapsed .initial-loading-overlay {
  left: 0;
}

.loading-spinner {
  width: 50px;
  height: 50px;
  border: 5px solid var(--color-gray-medium);
  border-top: 5px solid var(--color-primary);
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* Add these styles for the top bar and app container */
.app-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
}

.top-bar {
  height: 32px;
  min-height: 32px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  background-color: var(--color-topbar-background);
  padding: 0 0 0 8px;
  gap: 4px;
  flex-shrink: 0;
  z-index: 1000;
  position: relative;
  border-bottom: 1px solid var(--color-border);
  width: 100%;
}

.top-bar-button {
  min-width: 160px;
  max-width: 240px;
  height: 28px;
  padding: 0 16px;
  background-color: var(--color-tab-background);
  border: 1px solid var(--color-border);
  border-bottom: none;
  border-radius: 8px 8px 0 0;
  color: var(--color-text-light);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  cursor: pointer;
  transition: all 0.15s ease;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  margin-bottom: -1px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  /* Curved edges effect */
  &::before,
  &::after {
    content: "";
    position: absolute;
    width: 8px;
    height: 8px;
    bottom: 0;
    background-color: transparent;
  }
}

.top-bar-button.active {
  background-color: var(--color-tab-active);
  color: var(--color-text);
  height: 29px;
  border-bottom: none;
  z-index: 1;
}

.top-bar-button:hover:not(.active) {
  background-color: var(--color-tab-hover);

  &::before {
    box-shadow: 4px 4px 0 4px var(--color-tab-hover);
  }

  &::after {
    box-shadow: -4px 4px 0 4px var(--color-tab-hover);
  }
}

.lims-button.active,
.tools-button.active {
  background-color: var(--color-gray-light);
}

.lims-button:before,
.lims-button:after,
.tools-button:before,
.tools-button:after {
  box-shadow: none !important;
  border-bottom: none !important;
}

.page-content {
  flex: 1;
  overflow: hidden;
  display: flex;
  position: relative;
  height: 100%;
  background-color: var(--color-gray-light);
  margin-top: 0;
  padding-top: 0;
}

/* Add these styles after your existing .top-bar styles */

.top-bar-right {
  margin-left: auto;
  padding-right: 16px;
  display: flex;
  align-items: center;
}

.notifications-container {
  position: relative;
}

.notifications-button {
  background: none;
  border: none;
  padding: 4px;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-text);
}

.notifications-button:hover {
  color: var(--color-primary);
}

.notifications-badge {
  position: absolute;
  top: 0;
  right: 0;
  background-color: var(--color-primary);
  color: white;
  font-size: 10px;
  padding: 2px 4px;
  border-radius: 8px;
  min-width: 14px;
  height: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.notifications-dropdown {
  position: absolute;
  top: 100%;
  right: 0;
  width: 320px;
  /* background: var(--color-gray-ultra-light); */
  border: 1px solid var(--color-border);
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  margin-top: 8px;
  max-height: calc(100vh - 100px);
  display: flex;
  flex-direction: column;
}

.notifications-header {
  padding: 12px 16px;
  border-bottom: 1px solid var(--color-border);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.notifications-header h3 {
  margin: 0;
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-medium);
}

.notifications-close-all {
  background: none;
  border: none;
  padding: 4px;
  cursor: pointer;
  color: var(--color-text-light);
  display: flex;
  align-items: center;
  justify-content: center;
}

.notifications-close-all:hover {
  color: var(--color-error);
}

.notifications-list {
  max-height: 400px;
  overflow-y: auto;
}

.notification-item {
  padding: 12px 16px;
  border-bottom: 1px solid var(--color-border);
  display: flex;
  gap: 12px;
  align-items: flex-start;
}

.notification-item:last-child {
  border-bottom: none;
}

.notification-content {
  flex: 1;
}

.notification-content h4 {
  margin: 0 0 4px 0;
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
}

.notification-content p {
  margin: 0 0 8px 0;
  font-size: var(--font-size-sm);
  color: var(--color-text-light);
}

.notification-open-button {
  background-color: var(--color-gray-light);
  border: 1px solid var(--color-border);
  border-radius: 4px;
  padding: 4px 12px;
  font-size: var(--font-size-xs);
  cursor: pointer;
}

.notification-open-button:hover {
  background-color: var(--color-primary);
  border-color: var(--color-primary);
  color: white;
}

.notification-close-button {
  background: none;
  border: none;
  padding: 2px;
  cursor: pointer;
  color: var(--color-text-light);
}

.notification-close-button:hover {
  color: var(--color-error);
}

.no-notifications {
  padding: 16px;
  text-align: center;
  color: var(--color-text-light);
  font-size: var(--font-size-sm);
}

/* Add these new styles */
.notifications-footer {
  padding: 8px 16px;
  border-top: 1px solid var(--color-border);
  display: flex;
  justify-content: center;
}

.notifications-toggle-dismissed {
  background: none;
  border: none;
  padding: 4px 8px;
  font-size: var(--font-size-xs);
  color: var(--color-text-light);
  cursor: pointer;
}

.notifications-toggle-dismissed:hover {
  color: var(--color-primary);
}

.notification-actions {
  display: flex;
  gap: 8px;
  align-items: center;
}

.notification-restore-button {
  display: flex;
  align-items: center;
  gap: 4px;
  background: none;
  border: none;
  padding: 4px 8px;
  font-size: var(--font-size-xs);
  color: var(--color-text-light);
  cursor: pointer;
}

.notification-restore-button:hover {
  color: var(--color-primary);
}

.notification-timestamp {
  display: block;
  font-size: var(--font-size-xs);
  color: var(--color-text-light);
  margin: 4px 0;
}

/* Update existing loading styles to work with all components */
.initial-loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--color-gray-light);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.loading-spinner {
  width: 50px;
  height: 50px;
  border: 5px solid var(--color-gray-medium);
  border-top: 5px solid var(--color-primary);
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* Ensure proper sidebar handling with loading overlay */
.sidebar-collapsed .initial-loading-overlay {
  left: 0;
}

.container .initial-loading-overlay {
  left: 16rem;
  /* Width of sidebar */
}

.container.sidebar-collapsed .initial-loading-overlay {
  left: 0;
}

.chat-empty-state {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background-color: var(--color-gray-extra-light);
  padding: 20px;
}

.chat-empty-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.chat-empty-content p {
  font-size: var(--font-size-md);
  color: var(--color-text-light);
}
