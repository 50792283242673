.tool-primerdesign-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 32px;
  background-color: var(--color-gray-ultra-light);
  border-radius: 24px;
}

.tool-primerdesign-title {
  font-size: 2.4rem;
  font-weight: 600;
  margin-bottom: 16px;
  color: var(--color-text);
}

.tool-primerdesign-description {
  font-size: 1.2rem;
  color: var(--color-text-secondary);
  margin-bottom: 32px;
}

.tool-primerdesign-upload {
  margin-bottom: 24px;
}

.tool-primerdesign-extensions {
  display: flex;
  gap: 24px;
  margin-bottom: 24px;
}

.tool-primerdesign-extension-box {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.tool-primerdesign-extension-box label {
  margin-bottom: 8px;
  font-size: 1rem;
  color: var(--color-text);
}

.tool-primerdesign-extension-box input {
  padding: 10px;
  border: 1px solid var(--color-gray-medium-light);
  border-radius: 8px;
  font-size: 1rem;
}

.tool-primerdesign-goal {
  margin-bottom: 24px;
}

.tool-primerdesign-goal label {
  display: block;
  margin-bottom: 8px;
  font-size: 1rem;
  color: var(--color-text);
}

.tool-primerdesign-goal textarea {
  width: 100%;
  height: 80px;
  border: 1px solid var(--color-gray-medium-light);
  border-radius: 8px;
  padding: 10px;
  resize: vertical;
  font-size: 1rem;
}

.tool-primerdesign-button {
  background-color: var(--color-primary);
  color: white;
  border: none;
  padding: 12px 24px;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.tool-primerdesign-button:hover {
  background-color: var(--color-secondary);
}

.tool-primerdesign-button:disabled {
  background-color: var(--color-gray-light);
  cursor: not-allowed;
}

.tool-primerdesign-output {
  margin-top: 24px;
}

.tool-primerdesign-output h3 {
  margin-bottom: 16px;
  font-size: 1.4rem;
  color: var(--color-text);
}

.tool-primerdesign-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 24px;
  font-size: 0.9rem;
}

.tool-primerdesign-table th,
.tool-primerdesign-table td {
  padding: 12px;
  border: 1px solid var(--color-gray-medium-light);
  text-align: left;
}

.tool-primerdesign-table th {
  background-color: var(--color-gray-ultra-light);
  font-weight: 600;
}

.tool-primerdesign-table td {
  font-family: monospace;
}

.tool-primerdesign-table td:nth-child(3) {
  max-width: 200px;
  overflow-x: auto;
  white-space: nowrap;
}

.toolprimerdesign-loading {
  display: flex;
  align-items: center;
  gap: 8px;
}

.toolprimerdesign-spinner {
  width: 20px;
  height: 20px;
  border: 3px solid #f3f3f3;
  border-top: 3px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.toolprimerdesign-order {
  margin-top: 24px;
}

.toolprimerdesign-order-header {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 16px;
  position: relative;
}

.toolprimerdesign-copy-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.7;
  transition: opacity 0.2s;
}

.toolprimerdesign-copy-button:hover {
  opacity: 1;
}

.toolprimerdesign-copy-button img {
  width: 24px;
  height: 24px;
}

.toolprimerdesign-csv {
  background-color: var(--color-gray-ultra-light);
  padding: 16px;
  border-radius: 8px;
  font-family: monospace;
  white-space: pre;
  overflow-x: auto;
}

/* Change the tooltip class name */
.toolprimerdesign-copy-tooltip {
  position: absolute;
  background-color: var(--color-background);
  color: #ffffff !important;
  padding: 6px 12px;
  border-radius: 4px;
  font-size: 12px;
  pointer-events: none;
  z-index: 1000;
  animation: tooltipFade 2s ease;
  left: 42px;
  top: 40px;
  white-space: nowrap;
}

@keyframes tooltipFade {
  0% {
    opacity: 0;
  }

  10% {
    opacity: 1;
  }

  90% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
