.notifications-container {
  position: relative;
}

.notifications-button {
  background: none;
  border: none;
  padding: 4px;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-text);
}

.notifications-button:hover {
  color: var(--color-primary);
}

.notifications-badge {
  position: absolute;
  top: 0;
  right: 0;
  background-color: var(--color-primary);
  color: var(--color-background);
  font-size: var(--font-size-xs);
  padding: 2px 4px;
  border-radius: 8px;
  min-width: 14px;
  height: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.notifications-dropdown {
  position: absolute;
  top: 100%;
  right: 0;
  width: 400px;
  background-color: var(--color-background);
  border: 1px solid var(--color-border);
  border-radius: 12px;
  box-shadow: 0 4px 20px var(--color-shadow-medium);
  z-index: 1000;
  margin-top: 8px;
  max-height: calc(100vh - 100px);
  display: flex;
  flex-direction: column;
}

.notifications-header {
  padding: 16px 20px;
  border-bottom: 1px solid var(--color-border);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.notifications-header h3 {
  margin: 0;
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-semibold);
  color: var(--color-text);
}

.notifications-close-all {
  background: none;
  border: none;
  padding: 4px;
  cursor: pointer;
  color: var(--color-text-light);
  display: flex;
  align-items: center;
  justify-content: center;
}

.notifications-close-all:hover {
  color: var(--color-error);
}

.notifications-list {
  max-height: 600px;
  overflow-y: auto;
}

.notification-item {
  padding: 16px 20px;
  border-bottom: 1px solid var(--color-border);
  display: flex;
  gap: 16px;
  align-items: flex-start;
  transition: background-color 0.2s ease;
}

.notification-item:hover {
  background-color: var(--color-gray-ultra-light);
}

.notification-item:last-child {
  border-bottom: none;
}

.notification-content {
  flex: 1;
}

.notification-content h4 {
  margin: 0 0 8px 0;
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-semibold);
  color: var(--color-text);
}

.notification-content p {
  margin: 0 0 12px 0;
  font-size: var(--font-size-sm);
  color: var(--color-text-light);
  line-height: 1.4;
}

.notification-timestamp {
  display: block;
  font-size: var(--font-size-xs);
  color: var(--color-text-light);
  margin: 0 0 12px 0;
}

.notification-open-button {
  background-color: var(--color-gray-light);
  border: 1px solid var(--color-border);
  border-radius: 6px;
  padding: 6px 16px;
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  cursor: pointer;
  transition: all 0.2s ease;
}

.notification-open-button:hover {
  background-color: var(--color-primary);
  border-color: var(--color-primary);
  color: var(--color-background);
}

.notification-close-button {
  background: none;
  border: none;
  padding: 4px;
  cursor: pointer;
  color: var(--color-text-light);
  opacity: 0;
  transition: opacity 0.2s ease;
}

.notification-item:hover .notification-close-button {
  opacity: 1;
}

.notification-close-button:hover {
  color: var(--color-error);
}

.notifications-footer {
  padding: 12px 20px;
  border-top: 1px solid var(--color-border);
  display: flex;
  justify-content: center;
}

.notifications-toggle-dismissed {
  background: none;
  border: none;
  padding: 6px 12px;
  font-size: var(--font-size-sm);
  color: var(--color-text-light);
  cursor: pointer;
  font-weight: var(--font-weight-medium);
  transition: color 0.2s ease;
}

.notifications-toggle-dismissed:hover {
  color: var(--color-primary);
}

.no-notifications {
  padding: 24px;
  text-align: center;
  color: var(--color-text-light);
  font-size: var(--font-size-sm);
}

.notification-actions {
  display: flex;
  gap: 12px;
  align-items: center;
}

.notification-restore-button {
  display: flex;
  align-items: center;
  gap: 6px;
  background: none;
  border: none;
  padding: 6px 12px;
  font-size: var(--font-size-sm);
  color: var(--color-text-light);
  cursor: pointer;
  font-weight: var(--font-weight-medium);
  transition: color 0.2s ease;
}

.notification-restore-button:hover {
  color: var(--color-primary);
}
