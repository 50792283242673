/* src/components/tools/ToolSequenceFinder.css */
.tool-sequencefinder-container {
  padding: 20px;
}

.tool-sequencefinder-title {
  font-size: 2.4rem;
  font-weight: 600;
  margin-bottom: 16px;
  color: var(--color-text);
}

.tool-sequencefinder-description {
  font-size: 1.2rem;
  color: var(--color-text-secondary);
  margin-bottom: 32px;
}

.tool-sequencefinder-upload {
  margin-bottom: 24px;
}

.tool-sequencefinder-query {
  margin-bottom: 24px;
}

.tool-sequencefinder-textarea {
  width: 100%;
  height: 100px;
  font-size: 1rem;
  padding: 12px;
  border: 1px solid var(--color-gray-medium-light);
  border-radius: 8px;
  resize: vertical;
}

.tool-sequencefinder-databases {
  margin: 24px 0;
  padding: 20px;
  background-color: white;
  border-radius: 8px;
  border: 1px solid var(--color-gray-medium-light);
}

.tool-sequencefinder-databases h3 {
  margin-bottom: 16px;
  font-size: 18px;
  color: var(--color-text);
}

.tool-sequencefinder-databases-content {
  display: flex;
  gap: 24px;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.checkbox-group {
  display: flex;
  align-items: center;
  padding: 8px 12px;
  flex: 0 1 auto;
  min-width: 0;
  position: relative;
}

.checkbox-group input[type="checkbox"] {
  width: 18px;
  height: 18px;
  margin-right: 12px;
  cursor: pointer;
  accent-color: var(--color-secondary);
}

.checkbox-group label {
  font-size: 14px;
  color: var(--color-text);
  cursor: pointer;
}

.tool-sequencefinder-outputcount {
  margin: 16px 0;
  font-size: 14px;
  color: var(--color-text-secondary);
  display: flex;
  align-items: center;
  gap: 12px;
}

.tool-sequencefinder-outputcount select {
  padding: 4px;
  border: 1px solid var(--color-gray-medium-light);
  border-radius: 4px;
}

.tool-sequencefinder-button {
  background-color: var(--color-primary);
  color: white;
  border: none;
  padding: 12px 24px;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 24px;
  transition: background-color 0.2s ease;
  min-width: 120px;
  min-height: 40px;
}

.tool-sequencefinder-button:hover {
  background-color: var(--color-secondary);
}

.tool-sequencefinder-button:disabled {
  background-color: var(--color-gray-light);
  cursor: not-allowed;
  opacity: 0.7;
}

.tool-sequencefinder-results {
  margin-top: 20px;
}

.tool-sequencefinder-results h3 {
  margin-bottom: 16px;
  font-size: 18px;
  color: var(--color-text);
}

.tool-sequencefinder-table {
  width: 100%;
  margin-top: 20px;
  border-collapse: collapse;
}

.tool-sequencefinder-table th,
.tool-sequencefinder-table td {
  padding: 12px;
  text-align: left;
  border: 1px solid #ddd;
}

.tool-sequencefinder-table th {
  background-color: #f5f5f5;
}

.tool-sequencefinder-success {
  margin: 20px 0;
  padding: 10px;
  background-color: #e6ffe6;
  border: 1px solid #b3ffb3;
  border-radius: 4px;
}

.tool-sequencefinder-noresults {
  margin: 20px 0;
  padding: 10px;
  background-color: #fff3e6;
  border: 1px solid #ffcc80;
  border-radius: 4px;
}

.tool-sequencefinder-loading {
  display: flex;
  align-items: center;
  gap: 8px;
}

.tool-sequencefinder-spinner {
  width: 20px;
  height: 20px;
  border: 3px solid #f3f3f3;
  border-top: 3px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.tsf-tooltip-container {
  position: relative;
}

.tsf-tooltip {
  display: none;
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  padding: 8px 12px;
  background-color: var(--color-background);
  color: white !important;
  border-radius: 4px;
  font-size: 12px;
  white-space: normal;
  z-index: 1000;
  margin-bottom: 8px;
  width: max-content;
  max-width: 200px;
  text-align: center;
  line-height: 1.4;
  transition: opacity 0.2s ease;
}

.tsf-tooltip * {
  color: white !important;
}

.tsf-tooltip::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-width: 5px;
  border-style: solid;
  border-color: var(--color-background) transparent transparent transparent;
}
