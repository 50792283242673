.chatbar-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: var(--color-gray-extra-light);
  border-bottom: 1px solid var(--color-border);
  height: 34px;
  padding: 0 8px;
  flex-shrink: 0;
  position: relative;
  top: 0;
  z-index: 1;
}

.chatbar-container.collapsed {
  width: 32px;
  padding: 0;
  justify-content: center;
  border-left: 1px solid var(--color-border);
}

.chatbar-open-button {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  cursor: pointer;
  color: var(--color-text-light);
  border-radius: 4px;
  padding: 0;
  margin: 0;
}

.chatbar-open-button:hover {
  background-color: var(--color-hover-overlay);
  color: var(--color-background);
}

.chatbar-open-button svg {
  width: 14px;
  height: 14px;
}

.chat-panel-container.collapsed {
  width: 32px !important;
  min-width: 32px !important;
}

.chat-panel-container.collapsed .chatbar-container {
  border-bottom: none;
}

.chatbar-options {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.chatbar-option {
  display: flex;
  align-items: center;
  padding: 0 12px;
  height: 100%;
  cursor: pointer;
  user-select: none;
  color: var(--color-text-light);
  font-size: 13px;
  margin-right: 16px;
  position: relative;
}

.chatbar-option.active {
  color: var(--color-text);
}

.chatbar-option.active::after {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: black;
}

.chatbar-controls {
  display: flex;
  align-items: center;
  margin-left: auto;
  gap: 8px;
}

.chatbar-controls button {
  background: none;
  border: none;
  height: 28px;
  width: 28px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  color: var(--light-tooltip-border);
  padding: 0;
  margin: 0;
}

.chatbar-controls button:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.chatbar-new-chat-button {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
    Arial, sans-serif;
  display: flex;
  align-items: center;
  padding: 0 6px !important;
  width: auto !important;
}

.chatbar-cmd-symbol {
  font-size: 14px;
  margin-right: 1px;
}

.chatbar-n-symbol {
  font-size: 12px;
  color: var(--color-text-light);
  margin-right: 1px;
}

.chatbar-plus-symbol {
  font-size: 16px;
  font-weight: bold;
  transform: translateY(-1px);
  margin-left: 1px;
}

.chatbar-history-button {
  display: flex;
  align-items: center;
  justify-content: center;
}

.chatbar-history-button svg,
.chatbar-close-button svg {
  width: 14px;
  height: 14px;
}

.chatbar-close-button {
  display: flex;
  align-items: center;
  justify-content: center;
}

.chatbar-history-dropdown {
  position: relative;
  z-index: 10001;
}

.chatbar-dropdown-menu {
  position: fixed;
  background-color: var(--color-background);
  border: 1px solid var(--color-border);
  border-radius: 4px;
  box-shadow: 0 2px 8px var(--color-shadow-medium);
  min-width: 200px;
  max-height: 400px;
  overflow-y: auto;
  z-index: 2;
  margin-top: 4px;
}

.chatbar-dropdown-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 12px;
  border-bottom: 1px solid var(--color-border);
  background-color: var(--color-background);
}

.chatbar-dropdown-item:last-child {
  border-bottom: none;
}

.chatbar-dropdown-item:hover {
  background-color: var(--color-gray-light);
}

.chatbar-dropdown-item input {
  width: 100%;
  padding: 4px 8px;
  border: 1px solid var(--color-border);
  border-radius: 4px;
  font-size: 13px;
}

.chatbar-dropdown-item-name {
  flex-grow: 1;
  font-size: 13px;
  color: var(--color-text);
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 8px;
}

.chatbar-dropdown-item-actions {
  display: flex;
  gap: 4px;
  opacity: 0;
  transition: opacity 0.2s;
}

.chatbar-dropdown-item:hover .chatbar-dropdown-item-actions {
  opacity: 1;
}

.chatbar-dropdown-item-actions button {
  padding: 4px;
  font-size: 12px;
  color: var(--color-text-light);
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}

.chatbar-dropdown-item-actions button:hover {
  background-color: var(--color-hover-overlay);
  color: var(--color-text);
}

.chatbar-shortcut {
  margin-left: 8px;
  font-size: 0.8em;
  opacity: 0.7;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif;
}

.chatbar-option {
  display: flex;
  align-items: center;
  justify-content: center;
}
