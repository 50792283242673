.file-explorer-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--color-overlay-background);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.file-explorer-modal-overlay.loading {
  pointer-events: none;
}

.file-explorer-content.modal {
  background-color: var(--color-input-background);
  padding: 32px 40px;
  border-radius: 12px;
  width: 90%;
  min-width: 800px;
  max-width: 1400px;
  height: 80%;
  box-shadow: 0 8px 30px var(--color-shadow-medium);
  border: 1px solid var(--color-border-transparent);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  overflow: auto;
  position: relative;
  z-index: 1001;
  margin: 20px;
}

.file-explorer-content h2 {
  color: var(--color-text);
  margin-top: 0;
  margin-bottom: 16px;
  font-size: var(--font-size-xl);
  font-weight: var(--font-weight-semibold);
}

.search-and-upload-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0px;
  margin-bottom: 20px;
  gap: 10px;
  flex-wrap: wrap;
}

.search-bar-container {
  width: 300px;
  min-width: 200px;
}

.search-bar {
  display: flex;
  gap: 8px;
  align-items: center;
}

.search-icon {
  width: 18px;
  height: 18px;
  margin-right: 10px;
}

.search-bar input[type="text"] {
  width: 100%;
  padding: 8px 15px;
  font-size: var(--font-size-md);
  border: 1px solid var(--color-input-border);
  border-radius: 4px;
  background-color: var(--color-input-background);
  color: var(--color-text);
  transition: border-color 0.3s ease;
}

.search-bar input[type="text"]::placeholder {
  color: var(--color-text-light);
}

.search-bar input[type="text"]:focus {
  outline: none;
  border-color: var(--color-primary);
}

.upload-file-button {
  background-color: var(--color-secondary);
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-size: var(--font-size-sm);
  transition: background-color 0.3s ease;
  white-space: nowrap;
  margin-left: auto;
}

.upload-file-button:hover {
  background-color: var(--color-secondary);
}

.table-container {
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: auto;
  width: 100%;
  padding: 0 1px;
}

.file-explorer-content table {
  width: fit-content;
  min-width: 100%;
  border-collapse: collapse;
}

.file-explorer-content th,
.file-explorer-content td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid var(--color-input-border);
  color: var(--color-text);
}

.file-explorer-content th {
  background-color: var(--color-gray-medium);
  font-weight: var(--font-weight-bold);
}

.file-explorer-content tr:hover,
.dropdown-button:hover {
  background-color: var(--color-hover-overlay);
}

.file-actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
}

.file-menu-icon {
  cursor: pointer;
  width: 12px;
  height: 12px;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.file-explorer-content tr:hover .file-menu-icon {
  opacity: 1;
}

.file-dropdown {
  position: absolute;
  right: 0;
  top: 100%;
  background-color: var(--color-input-background);
  border: 1px solid var(--color-input-border);
  border-radius: 4px;
  box-shadow: 0 2px 10px var(--color-shadow-soft);
  z-index: 1000;
}

.dropdown-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 8px 12px;
  background: none;
  border: none;
  cursor: pointer;
  text-align: left;
}

.dropdown-button:hover {
  background-color: var(--color-hover-overlay);
}

.dropdown-icon {
  width: 14px;
  height: 14px;
  margin-left: 8px;
}

.file-explorer-content input[type="text"] {
  background-color: var(--color-input-background);
  color: var(--color-text);
  border: 1px solid var(--color-input-border);
  padding: 4px 8px;
  border-radius: 4px;
  width: calc(100% - 20px);
}

.pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  color: var(--color-text);
}

.pagination-controls {
  display: flex;
  align-items: center;
}

.pagination input[type="number"],
.pagination select {
  background-color: var(--color-input-background);
  color: var(--color-text);
  border: 1px solid var(--color-input-border);
  padding: 4px 8px;
  border-radius: 4px;
  margin: 0 4px;
}

.pagination button {
  background-color: var(--color-gray-medium);
  color: var(--color-text);
  border: none;
  padding: 8px 12px;
  margin: 0 4px;
  cursor: pointer;
  border-radius: 4px;
}

.pagination button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.close-button {
  margin-top: 0;
  padding: 0px 8px;
  background-color: var(--color-gray-medium);
  color: var(--color-text);
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.close-button:hover {
  background-color: var(--color-secondary);
}

.delete-confirmation-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--color-overlay-background);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1100;
}

.delete-confirmation-dialog {
  background-color: var(--color-input-background);
  padding: 24px;
  border-radius: 8px;
  max-width: 400px;
  text-align: center;
  color: var(--color-text);
}

.delete-confirmation-buttons {
  display: flex;
  justify-content: center;
  gap: 16px;
  margin-top: 20px;
}

.delete-confirm-button,
.delete-cancel-button {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: var(--font-size-sm);
}

.delete-confirm-button {
  background-color: var(--color-error);
  color: var(--color-text);
}

.delete-cancel-button {
  background-color: var(--color-gray-medium);
  color: var(--color-text);
}

.modal-footer {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}

.add-file-action {
  display: flex;
  justify-content: center;
  align-items: center;
}

.add-file-icon {
  width: 16px;
  height: 16px;
  cursor: pointer;
  opacity: 0.7;
  transition: opacity 0.3s ease;
}

.add-file-icon:hover {
  opacity: 1;
}

.container.loading .file-explorer-modal-overlay,
.container.loading .file-explorer-content {
  pointer-events: none;
}

.create-project-modal-content h2,
.rename-project-modal-content h2,
.delete-project-modal-content h2,
.upload-files-modal-content h2,
.modal-content h2 {
  color: var(--color-text);
}

.create-project-input,
.rename-project-input,
.delete-project-input,
.delete-file-input,
.upload-files-input,
.modal-content input {
  color: var(--color-text);
}

.create-project-submit-button,
.rename-project-submit-button,
.delete-project-submit-button,
.delete-file-submit-button,
.upload-files-submit-button,
.modal-buttons button[type="submit"] {
  color: var(--color-text);
}

.create-project-cancel-button,
.rename-project-cancel-button,
.delete-project-cancel-button,
.delete-file-cancel-button,
.upload-files-cancel-button,
.modal-buttons button[type="button"] {
  color: var(--color-text);
}

.create-project-modal-content p,
.rename-project-modal-content p,
.delete-project-modal-content p,
.delete-file-modal-content p,
.upload-files-modal-content p,
.modal-content p {
  color: var(--color-text);
}

.multi-action-buttons {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  flex-grow: 1;
  justify-content: flex-start;
}

.multi-action-button {
  background-color: var(--color-secondary);
  color: var(--color-text);
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-size: var(--font-size-sm);
  transition: background-color 0.3s ease;
  white-space: nowrap;
  min-width: fit-content;
}

.multi-action-button:disabled {
  background-color: var(--color-gray-medium);
  cursor: not-allowed;
}

.multi-action-button:hover:not(:disabled) {
  background-color: var(--color-secondary);
}

.topology-select {
  width: 100px;
  background-color: var(--color-input-background);
  border: 1px solid var(--color-input-border);
  border-radius: 4px;
  color: var(--color-text);
  font-size: var(--font-size-sm);
  padding: 5px;
}

.parent-file-select {
  width: 200px;
}

.parent-file-select__control {
  background-color: var(--color-input-background) !important;
  border-color: var(--color-input-border) !important;
  min-height: 32px !important;
  height: 32px !important;
}

.parent-file-select__value-container {
  height: 32px !important;
  padding: 0 8px !important;
}

.parent-file-select__input {
  margin: 0 !important;
}

.parent-file-select__indicators {
  height: 32px !important;
}

.parent-file-select__menu {
  background-color: var(--color-input-background) !important;
  color: var(--color-text) !important;
}

.parent-file-select__option {
  background-color: var(--color-input-background) !important;
  color: var(--color-text) !important;
}

.parent-file-select__option--is-focused {
  background-color: var(--color-secondary) !important;
}

.parent-file-select__single-value {
  color: var(--color-text) !important;
}

.file-explorer-content td {
  overflow: visible;
  max-width: 500px;
}

.artifact-confirmation-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--color-overlay-background);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1100;
}

.artifact-confirmation-popup-dialog {
  background-color: var(--color-input-background);
  padding: 24px;
  border-radius: 8px;
  max-width: 400px;
  text-align: center;
  color: var(--color-text);
}

.artifact-confirmation-popup-buttons {
  display: flex;
  justify-content: center;
  gap: 16px;
  margin-top: 20px;
}

.artifact-confirm-button,
.artifact-cancel-button {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: var(--font-size-sm);
}

.artifact-confirm-button {
  background-color: var(--color-secondary);
  color: var(--color-text);
}

.artifact-confirm-button:hover {
  background-color: var(--color-secondary);
}

.artifact-cancel-button {
  background-color: var(--color-gray-medium);
  color: var(--color-text);
}

.artifact-cancel-button:hover {
  background-color: var(--color-secondary);
}

.file-name-cell {
  max-width: 500px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.projects-cell {
  max-width: 400px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.file-menu-icon-always-visible {
  cursor: pointer;
  width: 12px;
  height: 12px;
  opacity: 1 !important;
}

.search-button {
  background-color: var(--color-secondary);
  color: var(--color-text);
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-size: var(--font-size-sm);
  transition: background-color 0.3s ease;
}

.search-button:hover {
  background-color: var(--color-secondary);
}

.search-filter-tag {
  display: inline-flex;
  align-items: center;
  background-color: var(--color-secondary);
  border-radius: 4px;
  padding: 6px 12px;
  font-size: var(--font-size-sm);
  color: var(--color-text);
  transition: background-color 0.3s ease;
}

.clear-search-button {
  background: none;
  border: none;
  color: var(--color-text);
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-bold);
  padding: 0 0 0 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: color 0.3s ease;
}

.clear-search-button:hover {
  color: var(--color-secondary);
}

.search-filter-tag span {
  margin-right: 4px;
}

.filter-tag-row {
  width: 100%;
  padding: 8px 0;
  margin-bottom: 16px;
  border-top: 1px solid var(--color-input-border);
  border-bottom: 1px solid var(--color-input-border);
}

.file-type-toggle {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  padding: 0;
  gap: 0;
  width: 100%;
}

.file-type-toggle .toggle-button {
  background-color: var(--color-gray-medium);
  color: var(--color-text);
  border: none;
  padding: 10px 20px;
  border-radius: 0;
  cursor: pointer;
  font-size: var(--font-size-md);
  transition: background-color 0.3s ease;
  flex: 1;
  white-space: nowrap;
}

.file-type-toggle .toggle-button.active {
  background-color: var(--color-secondary);
}

.file-type-toggle .toggle-button:hover:not(.active) {
  background-color: var(--color-secondary);
}

.file-type-toggle .toggle-button:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.file-type-toggle .toggle-button:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.large-upload-button:hover {
  background: #0056b3 !important;
}

.large-upload-button:active {
  transform: scale(0.98);
}

.empty-state-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
  min-height: 300px;
}

.empty-state-upload-button {
  display: flex;
  align-items: center;
  gap: 12px;
  background-color: var(--color-secondary);
  color: var(--color-text);
  border: none;
  padding: 16px 32px;
  border-radius: 4px;
  cursor: pointer;
  font-size: var(--font-size-lg);
  transition: background-color 0.3s ease;
  white-space: nowrap;
}

.empty-state-upload-button:hover {
  background-color: var(--color-secondary);
}

.empty-state-upload-button:active {
  transform: scale(0.98);
}

.empty-state-upload-icon {
  width: 24px;
  height: 24px;
  filter: var(--filter-icon);
}

.empty-state-text {
  margin-top: 20px;
  color: var(--color-text);
  text-align: center;
  font-size: var(--font-size-md);
}
