.app-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.page-content {
  flex: 1;
  display: flex;
  min-height: 0;
  margin-left: 16rem;
  transition: margin-left 0.3s ease;
}

.page-content.sidebar-hidden {
  margin-left: 32px;
}

.sidebar {
  width: 16rem;
  position: fixed;
  top: 32px;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  background-color: var(--color-gray-medium);
  overflow: hidden;
  transition: all 0.3s ease;
  z-index: 10;
  font-size: var(--font-size-sm);
  color: var(--color-text-medium);
}

.sidebar.hidden {
  width: 32px;
  min-width: 32px;
  border-right: 1px solid var(--color-border);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.sidebar-content,
.sidebar-info {
  color: var(--color-text-medium);
  width: 16rem;
  transition: all 0.3s ease;
}

.sidebar.hidden .sidebar-content,
.sidebar.hidden .sidebar-info {
  transform: translateX(-100%);
}

.sidebar-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  min-height: 0;
}

.sidebar-top {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  min-height: 0;
}

.sidebar-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: default;
  padding: 0 var(--padding-medium);
  border: none;
  border-radius: 0;
  margin-bottom: var(--padding-small);
}

.sidebar-icon,
.new-project-icon {
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.sidebar-icon {
  cursor: pointer;
}

.sidebar-icon path {
  stroke: var(--color-primary);
  stroke-width: 1.5;
}

.new-project-icon {
  margin-left: auto;
  width: 24px !important;
  margin-right: -3px;
}

.sidebar-icon:hover,
.new-project-icon:hover {
  opacity: 0.8;
}

.project-list {
  flex: 1;
  overflow-y: auto;
  min-height: 0;
  max-height: calc(100vh - 250px);
  padding: var(--padding-small);
  margin: 5px;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.project-list::-webkit-scrollbar {
  display: none;
}

.sidebar-history {
  width: 100%;
  height: 100vh;
  overflow: auto;
}

.sidebar-history p {
  position: sticky;
  top: 0;
  background-color: var(--color-background);
  padding: var(--padding-small);
  color: var(--color-text);
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-semibold);
  z-index: 1;
}

.sidebar ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.sidebar li {
  position: relative;
  overflow: visible !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-top: var(--padding-small);
  padding-bottom: var(--padding-small);
}

.sidebar .li-overflow-shadow:not(:hover)::after {
  content: "";
  position: absolute;
  bottom: 0.5rem;
  right: -5px;
  padding: 0 5px;
  left: calc(100% - 50px);
  border-radius: 0.3rem;
  background: linear-gradient(
    to right,
    transparent,
    var(--color-gray-medium) 100%,
    transparent
  );
  pointer-events: none;
  z-index: 1;
}

.sidebar-info {
  flex-shrink: 0;
  width: 100%;
  padding: var(--padding-small) 0;
  border-top: 0.05rem solid var(--color-border-transparent);
  background-color: var(--color-gray-medium);
  box-shadow: inset 0 2px 4px 0 var(--color-shadow-soft);
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: var(--padding-small);
  position: relative;
  z-index: 12;
  margin-top: auto;
  margin-bottom: 32px;
}

.sidebar-info button {
  width: 90%;
}

.sidebar-info-item {
  display: flex;
  width: calc(100% - 20px);
  align-items: center;
  padding: var(--padding-small);
  cursor: pointer;
  color: var(--color-text);
  padding: var(--padding-small) 0;
}

.sidebar-info-item:hover,
.project-item.selected:hover {
  background-color: var(--sidebar-dropdown-hover);
  border-radius: 0.3rem;
}

.sidebar li:hover,
.sidebar-info-upgrade:hover,
.sidebar-info-user:hover {
  background-color: var(--color-gray-medium);
  border-radius: 0.3rem;
  cursor: pointer;
}

.sidebar-info-upgrade:hover,
.sidebar-info-user:hover {
  cursor: not-allowed;
}

.project-item {
  display: flex;
  align-items: center;
  padding: var(--padding-small) var(--padding-small);
  cursor: pointer;
  position: relative;
  transition: background-color 0.2s ease;
}

.project-item:hover,
.project-item.selected {
  background-color: var(--color-gray-medium);
  border-radius: 0.3rem;
}

.project-group {
  margin-bottom: 8px;
  background: var(--color-project-group-background);
  box-shadow: var(--color-shadow-soft) 0px 4px 12px;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.project-header-name {
  font-variant: all-small-caps;
  font-size: var(--font-size-md-lg);
  font-weight: var(--font-weight-semibold);
  color: var(--color-text);
}

.project-header {
  display: flex;
  padding: 0 var(--padding-medium);
  flex-direction: row;
  height: 100%;
  flex: 0;
  display: flex;
  padding-bottom: var(--padding-small);
  align-items: center;
}

.project-header .new-project-item {
  height: 28px;
}

.project-group.selected {
  border-top: 2px solid var(--color-secondary);
  background-color: none;
  border-radius: 0 0 0.5rem 0.5rem;
}

.project-group.selected .project-item {
  background-color: transparent;
  padding-bottom: 0px;
}

.project-group.selected:has(.project-item.selected:hover):hover {
  background-color: var(--sidebar-dropdown-hover);
}

Apply styles to child element .project-group.selected .project-item.selected {
  pointer-events: auto !important;
}

.file-tree {
  padding-left: 20px;
  color: var(--color-text-medium);
  display: flex;
  flex-direction: column;
  width: 100%;
}

.project-group.selected .file-tree {
  padding-top: 5px;
}

.project-menu-icon {
  flex-shrink: 0;
  width: 16px;
  height: 16px;
  opacity: 0;
  transition: opacity 0.2s ease;
  margin-left: auto;
}

.project-item:hover .project-menu-icon {
  opacity: 1;
}

.project-dropdown {
  position: fixed;
  background-color: var(--color-gray-medium);
  border: 1px solid var(--color-gray-medium);
  border-radius: 0px;
  z-index: 99999;
  min-width: 120px;
  display: block;
  box-shadow: 0 2px 10px var(--color-shadow-medium);
  max-height: 80vh;
  overflow-y: auto;
}

.project-dropdown button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: var(--padding-small) var(--padding-medium);
  text-align: left;
  border: none;
  background: none;
  color: var(--color-text);
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.dropdown-icon {
  width: 14px;
  height: 14px;
  opacity: 0.7;
}

.project-dropdown button:hover {
  background-color: var(--sidebar-dropdown-hover);
}

.project-dropdown button:hover .dropdown-icon {
  opacity: 1;
}

.file-item,
.folder-item {
  display: flex;
  align-items: center;
  padding: var(--padding-small) 0;
  cursor: pointer;
}

.file-item .file-item-name {
  color: var(--color-text-light) !important;
}

.file-item svg {
  fill: var(--color-text-medium);
}

.file-item-icon,
.folder-item-icon {
  flex-shrink: 0;
  margin-right: 5px;
  width: 16px;
  height: 16px;
}

.file-item-name,
.folder-item-name {
  flex-grow: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.file-item svg,
.folder-item svg {
  margin-right: 10px;
}

.file-item:hover,
.folder-item:hover {
  background-color: var(--color-primary);
  border-radius: 0.3rem;
}

.indented {
  margin-left: 12px !important;
}

.folder-item {
  padding-left: 10px;
}

.file-item {
  padding-left: 10px;
}

.file-delete-icon {
  width: 14px;
  height: 14px;
  opacity: 0.7;
  transition: opacity 0.3s ease;
  cursor: pointer;
  margin-left: 5px;
}

.file-item:hover .file-delete-icon {
  opacity: 1;
}

.file-item:hover .file-delete-icon:hover {
  opacity: 0.7;
}

.sidebar-bottom-text,
.sidebar-bottom-img {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: var(--font-weight-medium);
}

.sidebar-bottom-img-container {
  width: 30px;
  margin-right: 10px;
  display: flex;
}

.sidebar-bottom-img {
  margin: auto;
}

.selected .project-name {
  font-weight: var(--font-weight-semibold);
}

.auth-button {
  width: 100%;
  padding: var(--padding-small);
  background-color: var(--color-primary);
  border: none;
  border-radius: var(--border-radius-small);
  cursor: pointer;
  transition: background-color 0.2s ease;
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
}

.auth-button:hover {
  background-color: var(--color-secondary);
}

.user-dropdown-trigger {
  position: relative;
}

.user-dropdown {
  position: absolute;
  bottom: 100%;
  right: 0;
  background-color: var(--color-gray-medium);
  border: 1px solid var(--color-gray);
  border-radius: 4px;
  z-index: 99999;
  min-width: 160px;
  display: block;
  box-shadow: 0 2px 10px var(--color-shadow-medium);
  max-height: 80vh;
  overflow-y: auto;
}

.user-dropdown .dropdown-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: var(--padding-small) var(--padding-medium);
  text-align: left;
  border: none;
  background: none;
  color: var(--color-text);
  cursor: pointer;
  transition: background-color 0.2s ease;
  font-size: var(--font-size-sm);
}

.user-dropdown .dropdown-button:hover {
  background-color: var(--sidebar-dropdown-hover);
}

.user-picture {
  border-radius: 50%;
  object-fit: cover;
}

.design-item {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 5px 10px;
}

.file-item-icon {
  display: inline-flex;
  align-items: center;
  margin-right: 8px;
  width: 16px;
  height: 16px;
}

.file-item-icon img {
  width: 14px;
  height: 14px;
  position: relative;
  top: 1px;
  vertical-align: middle;
}

.file-item {
  display: flex;
  align-items: center;
  padding: 4px 8px;
  cursor: pointer;
  position: relative;
}

.file-item:hover {
  background-color: var(--color-hover-overlay);
}

.file-item-name {
  flex-grow: 1;
  color: var(--color-text-medium);
}

.project-group.selected .file-item:hover,
.project-group.selected .folder-item:hover,
.project-group.selected .design-item:hover,
.project-group:not(.selected) .project-item:hover,
.project-group .file-item:hover,
.project-group .folder-item:hover,
.project-group .design-item:hover,
.project-item:hover,
.file-item:hover,
.folder-item:hover,
.design-item:hover {
  background-color: var(--sidebar-dropdown-hover);
  border-radius: 0;
}

.folder-item {
  font-weight: var(--font-weight-semibold);
}

.design-item-name:hover {
  color: var(--color-text);
}

.file-item .file-delete-icon {
  margin-right: 3px;
}

.sidebar-info .line-color {
  color: var(--color-primary);
  stroke: var(--color-primary);
}

.sidebar-info-item-dna .sidebar-bottom-img {
  width: 10px;
}

.file-menu-icon {
  flex-shrink: 0;
  width: 16px;
  height: 16px;
  opacity: 0;
  transition: opacity 0.2s ease;
  margin-left: auto;
  margin-right: 6px;
  cursor: pointer;
  position: absolute;
  right: 10px;
}

.file-item:hover .file-menu-icon,
.folder-item:hover .file-menu-icon {
  opacity: 0.5;
}

.file-item:hover .file-menu-icon:hover,
.folder-item:hover .file-menu-icon:hover {
  opacity: 1;
}

.file-dropdown {
  position: absolute;
  right: 0;
  background-color: var(--color-gray-medium);
  border: 1px solid var(--color-gray-medium);
  border-radius: 0px;
  z-index: 99999;
  min-width: 120px;
  display: block;
  box-shadow: 0 2px 10px var(--color-shadow-medium);
  max-height: 80vh;
  overflow-y: auto;
}

.file-dropdown button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: var(--padding-small) var(--padding-medium);
  text-align: left;
  border: none;
  background: none;
  color: var(--color-text);
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.file-dropdown button:hover {
  background-color: var(--sidebar-dropdown-hover);
}

.folder-add-icon {
  width: 16px;
  height: 16px;
  margin-left: auto;
  margin-right: 6px;
  cursor: pointer;
  opacity: 0.7;
  position: absolute;
  right: 0;
}

.folder-item {
  display: flex;
  align-items: center;
  padding: var(--padding-small) 0;
  cursor: pointer;
  width: 100%;
  position: relative;
  padding-right: 26px;
}

.folder-item:hover .folder-add-icon {
  opacity: 0.8;
}

.folder-add-icon:hover {
  opacity: 1;
}

.load-more-item {
  display: flex;
  justify-content: center;
  padding: var(--padding-small);
  margin-top: var(--padding-small);
}

.load-more-button {
  background-color: var(--color-gray-medium);
  border: 1px solid var(--color-gray-medium);
  border-radius: var(--border-radius-small);
  color: var(--color-text);
  cursor: pointer;
  padding: var(--padding-small) var(--padding-medium);
  width: 100%;
  transition: background-color 0.2s ease;
}

.load-more-button:hover {
  background-color: var(--sidebar-dropdown-hover);
}

.load-more-button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.design-button-container {
  display: flex;
  justify-content: center;
  padding-bottom: var(--padding-medium);
  padding-top: 0.2rem;
  border-bottom: 2px solid var(--color-border);
  width: 100%;
}

.file-tree-content {
  border-top: 2px solid var(--color-border);
  padding-top: var(--padding-small);
  width: 100%;
}

.design-button-container + .file-tree-content {
  border-top: none;
}

.sidebar-toggle {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  cursor: pointer;
  width: 24px;
  height: 24px;
  padding: 0;
  margin: 0;
  margin-left: -12px;
}

.sidebar-toggle:hover {
  background-color: var(--color-hover-overlay);
}

.sidebar-toggle img {
  width: 24px;
  height: 24px;
}

.settings-dropdown {
  position: absolute;
  background-color: var(--color-gray-medium);
  border: 1px solid var(--color-gray);
  border-radius: 4px;
  z-index: 99999;
  min-width: 160px;
  display: block;
  box-shadow: 0 2px 10px var(--color-shadow-medium);
  max-height: 80vh;
  overflow-y: auto;
}

.settings-dropdown .dropdown-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: var(--padding-small) var(--padding-medium);
  text-align: left;
  border: none;
  background: none;
  color: var(--color-text);
  cursor: pointer;
  transition: background-color 0.2s ease;
  font-size: var(--font-size-sm);
}

.settings-dropdown .dropdown-button:hover {
  background-color: var(--sidebar-dropdown-hover);
}
