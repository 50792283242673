.create-project-modal-overlay,
.rename-project-modal-overlay,
.delete-project-modal-overlay,
.delete-file-modal-overlay,
.upload-files-modal-overlay,
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--color-overlay-background);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.create-project-modal-content,
.rename-project-modal-content,
.delete-project-modal-content,
.delete-file-modal-content,
.upload-files-modal-content,
.modal-content {
  background-color: var(--color-input-background);
  padding: 32px;
  border-radius: 12px;
  width: 400px;
  max-width: 90%;
  box-shadow: 0 8px 30px var(--color-shadow-medium);
  border: 1px solid var(--color-border-transparent);
  box-sizing: border-box;
}

.create-project-modal-title,
.rename-project-modal-title,
.delete-project-modal-title,
.delete-file-modal-title,
.upload-files-modal-title,
.modal-title {
  margin-top: 0;
  margin-bottom: 24px;
  font-size: var(--font-size-xl);
  color: var(--color-text);
  font-weight: var(--font-weight-semibold);
  word-wrap: break-word;
  overflow-wrap: break-word;
  hyphens: auto;
}

.dropzone {
  border: 2px dashed var(--color-input-border);
  border-radius: 6px;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  margin-bottom: 20px;
  transition: background-color 0.3s ease;
}

.dropzone:hover {
  background-color: var(--color-button-primary);
}

.file-list {
  margin-top: 20px;
  color: var(--color-text);
}

.file-list h3 {
  font-size: var(--font-size-lg);
  margin-bottom: 10px;
}

.file-list ul {
  list-style-type: none;
  padding: 0;
}

.file-list li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.file-list li button {
  background-color: var(--color-error);
  color: var(--color-text);
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  font-size: var(--font-size-sm);
}

.file-list li button:hover {
  background-color: var(--color-secondary);
}

.create-project-modal-content h2,
.rename-project-modal-content h2,
.delete-project-modal-content h2,
.upload-files-modal-content h2,
.modal-content h2 {
  margin-top: 0;
  margin-bottom: 24px;
  font-size: var(--font-size-xl);
  color: var(--color-text);
  font-weight: var(--font-weight-semibold);
  word-wrap: break-word;
  overflow-wrap: break-word;
  hyphens: auto;
}

.create-project-modal-content form,
.rename-project-modal-content form,
.delete-project-modal-content form,
.delete-file-modal-content form,
.upload-files-modal-content form,
.modal-content form {
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
}

.create-project-modal-content form {
  margin-bottom: 24px;
}

.create-project-input,
.rename-project-input,
.delete-project-input,
.delete-file-input,
.upload-files-input,
.modal-content input {
  padding: 12px 16px;
  margin-bottom: 12px;
  border: 1px solid var(--color-input-border);
  border-radius: 6px;
  font-size: var(--font-size-lg);
  background-color: var(--color-input-background);
  color: var(--color-text);
  transition:
    border-color 0.3s ease,
    box-shadow 0.3s ease;
}

.create-project-input:focus,
.rename-project-input:focus,
.delete-project-input:focus,
.delete-file-input:focus,
.upload-files-input:focus,
.modal-content input:focus {
  outline: none;
  border-color: var(--color-button-primary);
  box-shadow: 0 0 0 2px var(--color-button-primary-transparent);
}

.create-project-modal-buttons,
.rename-project-modal-buttons,
.delete-project-modal-buttons,
.delete-file-modal-buttons,
.upload-files-modal-buttons,
.modal-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  margin-top: 8px;
  margin-bottom: 0;
}

.create-project-modal-buttons button,
.rename-project-modal-buttons button,
.delete-project-modal-buttons button,
.delete-file-modal-buttons button,
.upload-files-modal-buttons button,
.modal-buttons button {
  padding: 10px 20px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: var(--font-size-md);
  transition:
    background-color 0.3s ease,
    transform 0.1s ease;
}

.create-project-modal-buttons button:active,
.rename-project-modal-buttons button:active,
.delete-project-modal-buttons button:active,
.delete-file-modal-buttons button:active,
.upload-files-modal-buttons button:active,
.modal-buttons button:active {
  transform: scale(0.98);
}

.create-project-submit-button,
.rename-project-submit-button,
.delete-project-submit-button,
.delete-file-submit-button,
.upload-files-submit-button,
.modal-buttons button[type="submit"] {
  background-color: var(--color-button-primary);
  color: var(--color-text);
  font-weight: var(--font-weight-semibold);
}

.create-project-submit-button:hover,
.rename-project-submit-button:hover,
.delete-project-submit-button:hover,
.delete-file-submit-button:hover,
.upload-files-submit-button:hover,
.modal-buttons button[type="submit"]:hover {
  background-color: var(--color-secondary);
}

.create-project-cancel-button,
.rename-project-cancel-button,
.delete-project-cancel-button,
.delete-file-cancel-button,
.upload-files-cancel-button,
.modal-buttons button[type="button"] {
  background-color: var(--color-gray-medium);
  color: var(--color-text);
}

.create-project-cancel-button:hover,
.rename-project-cancel-button:hover,
.delete-project-cancel-button:hover,
.delete-file-cancel-button:hover,
.upload-files-cancel-button:hover,
.modal-buttons button[type="button"]:hover {
  background-color: var(--color-button-primary);
}

.create-project-modal-content p,
.rename-project-modal-content p,
.delete-project-modal-content p,
.delete-file-modal-content p,
.upload-files-modal-content p,
.modal-content p {
  color: var(--color-text);
  margin-bottom: 12px;
  font-size: var(--font-size-md);
}

.delete-project-loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.delete-project-loading p {
  margin-bottom: 20px;
  color: var(--color-text);
}

.delete-project-loading-spinner {
  width: 40px;
  height: 40px;
  border: 4px solid var(--color-gray-medium);
  border-top: 4px solid var(--color-primary);
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.delete-project-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--color-overlay-background);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.delete-project-modal-content {
  background-color: var(--color-background);
  padding: 32px;
  border-radius: 12px;
  width: 90%;
  max-width: 500px;
  box-shadow: 0 8px 30px var(--color-shadow-medium);
  border: 1px solid var(--color-border);
}

.delete-project-modal-overlay.deleting {
  pointer-events: none;
}

.delete-project-modal-content {
  pointer-events: auto;
}

.modal-error-message {
  background-color: var(--color-error-light);
  color: var(--color-error);
  padding: 12px;
  border-radius: 4px;
  margin-bottom: 16px;
  border: 1px solid var(--color-error-border);
}

.modal-large-buttons-container {
  display: flex;
  justify-content: center;
  gap: 1.5rem;
  width: 100%;
  margin: 20px 0;
}

.modal-large-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.75rem;
  padding: 0.75rem 1.25rem;
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-medium);
  color: var(--color-text);
  background-color: var(--color-gray-ultra-light);
  border: 1px solid var(--color-border);
  border-radius: 24px;
  cursor: pointer;
  transition: all 0.2s ease;
  width: fit-content;
  box-shadow: 0 2px 4px var(--color-shadow-soft);
}

.modal-large-button:hover {
  background-color: var(--color-primary);
  transform: translateY(-2px);
  border-color: var(--color-primary);
  color: var(--color-background);
}

.modal-large-button img {
  width: 24px;
  height: 24px;
  margin-top: 2px;
  margin-left: -4px;
}

.create-project-modal-content {
  width: 550px !important;
  max-width: 90% !important;
  padding: 32px !important;
}

.create-project-buttons-container {
  display: flex;
  justify-content: center;
  gap: 1.5rem;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}

.create-project-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.75rem;
  padding: 0.75rem 1.25rem;
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-medium);
  color: var(--color-text);
  background-color: var(--color-gray-ultra-light);
  border: 1px solid var(--color-border);
  border-radius: 24px;
  cursor: pointer;
  transition: all 0.2s ease;
  width: fit-content;
  box-shadow: 0 2px 4px var(--color-shadow-soft);
}

.create-project-button:hover {
  background-color: var(--color-primary);
  transform: translateY(-2px);
  border-color: var(--color-primary);
  color: var(--color-background);
}

.create-project-button img {
  width: 24px;
  height: 24px;
  margin-top: 2px;
  margin-left: -4px;
}

.create-project-alternative {
  margin-top: 12px;
  margin-bottom: 24px;
  text-align: left;
  color: var(--color-text-medium);
  font-size: var(--font-size-sm);
  padding: 0 16px;
}

.project-type-selector {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  gap: 12px;
}

.project-type-label {
  color: var(--color-text);
  font-size: var(--font-size-sm);
}

.project-type-buttons {
  display: flex;
  gap: 8px;
}

.project-type-button {
  padding: 8px 16px;
  border: 1px solid var(--color-input-border);
  border-radius: 4px;
  background-color: var(--color-input-background);
  color: var(--color-text);
  cursor: pointer;
  font-size: var(--font-size-sm);
  transition: all 0.3s ease;
}

.project-type-button:hover {
  background-color: var(--color-button-primary);
}

.project-type-button.active {
  background-color: var(--color-button-primary);
  border-color: var(--color-button-primary);
}

.project-type-button.active:hover {
  background-color: var(--color-secondary);
  border-color: var(--color-secondary);
}
