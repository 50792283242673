/* src/components/tools/ToolDigestVisualizer.css */
.tool-digest-visualizer-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px 32px;
  background-color: var(--color-gray-ultra-light);
  border-radius: 24px;
}

.tool-digest-title {
  font-size: 2.4rem;
  font-weight: 600;
  margin-bottom: 16px;
  color: var(--color-text);
}

.tool-digest-description {
  font-size: 1.2rem;
  color: var(--color-text-secondary);
  margin-bottom: 32px;
}

.tool-digest-upload {
  margin-bottom: 24px;
}

.tool-digest-warning {
  background-color: var(--color-error-light);
  color: var(--color-error);
  padding: 12px 16px;
  border-radius: 8px;
  margin: 16px 0;
  font-size: 14px;
  font-weight: 500;
  animation: fadeIn 0.3s ease;
}

.tool-digest-inputs {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 24px;
}

.dropdown-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: 500px;
  align-items: flex-start;
}

.dropdown-container label strong {
  font-size: 16px;
  font-weight: 500;
  color: var(--color-text);
  margin-bottom: 4px;
}

.dropdown-container label {
  color: var(--color-text);
}

/* React Select Styling */
.protocol-dropdown {
  width: 100%;
  /* max-width: 100%; */
  background-color: white;
  align-self: stretch;
}

.protocol-dropdown__control {
  background-color: white !important;
  border-color: var(--color-gray-medium-light) !important;
  min-height: 32px !important;
  height: 32px !important;
  box-shadow: none !important;
}

.protocol-dropdown__control:hover {
  border-color: var(--color-secondary) !important;
}

.protocol-dropdown__value-container {
  height: 32px !important;
  padding: 0 8px !important;
}

.protocol-dropdown__input {
  margin: 0 !important;
}

.protocol-dropdown__indicators {
  height: 32px !important;
}

.protocol-dropdown__menu {
  background-color: white !important;
  color: black !important;
  border: none !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1) !important;
}

.protocol-dropdown__option {
  background-color: white !important;
  color: black !important;
}

.protocol-dropdown__option--is-focused {
  background-color: var(--color-primary) !important;
  color: white !important;
}

.protocol-dropdown__single-value {
  color: black !important;
}

.protocol-dropdown__multi-value {
  background-color: var(--color-gray-ultra-light) !important;
  border-radius: 4px !important;
}

.protocol-dropdown__multi-value__label {
  color: var(--color-text) !important;
  font-size: 12px !important;
}

.protocol-dropdown__multi-value__remove {
  color: var(--color-text-secondary) !important;
}

.protocol-dropdown__multi-value__remove:hover {
  background-color: var(--color-error-light) !important;
  color: var(--color-error) !important;
}

.tool-digest-button {
  background-color: var(--color-primary);
  color: white;
  border: none;
  padding: 12px 24px;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.2s ease;
  margin-top: 24px;
}

.tool-digest-button:hover {
  background-color: var(--color-secondary);
}

.tool-digest-button:disabled {
  background-color: var(--color-gray-light);
  cursor: not-allowed;
  opacity: 0.7;
}

.tool-digest-results {
  margin-top: 32px;
  animation: fadeIn 0.3s ease;
}

.tool-digest-success {
  background-color: var(--color-success-light);
  color: var(--color-success);
  padding: 12px 16px;
  border-radius: 8px;
  margin-bottom: 24px;
  font-size: 14px;
  font-weight: 500;
}

.tool-digest-fragments {
  background-color: white;
  border: 1px solid var(--color-gray-medium-light);
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 24px;
}

.tool-digest-fragments h3 {
  margin-bottom: 12px;
  font-size: 18px;
  color: var(--color-text);
}

.tool-digest-fragments ul {
  list-style-type: disc;
  margin-left: 16px;
  padding-left: 16px;
}

.tool-digest-gel {
  background-color: white;
  border: 1px solid var(--color-gray-medium-light);
  border-radius: 8px;
  padding: 16px;
}

.tool-digest-gel h3 {
  margin-bottom: 12px;
  font-size: 18px;
  color: var(--color-text);
}

.tool-digest-gel img {
  max-width: 100%;
  height: auto;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.tool-digest-error {
  background-color: var(--color-error-light);
  color: var(--color-error);
  padding: 12px 16px;
  border-radius: 8px;
  margin: 16px 0;
  font-size: 14px;
  font-weight: 500;
  animation: fadeIn 0.3s ease;
}

.fragments-table {
  width: 100%;
  border-collapse: collapse;
  margin: 10px 0;
}

.fragments-table th,
.fragments-table td {
  padding: 8px;
  text-align: left;
  border: 1px solid var(--color-gray-medium-light);
}

.fragments-table th {
  background-color: var(--color-gray-ultra-light);
  font-weight: 500;
}

.fragments-table tr:nth-child(even) {
  background-color: var(--color-gray-ultra-light);
}

.fragments-table tr:hover {
  background-color: var(--color-gray-light);
}
