.tool-annotation-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 32px;
  background-color: var(--color-gray-ultra-light);
  border-radius: 24px;
}

.tool-annotation-title {
  font-size: 2.4rem;
  font-weight: 600;
  margin-bottom: 16px;
  color: var(--color-text);
}

.tool-annotation-description {
  font-size: 1.2rem;
  color: var(--color-text-secondary);
  margin-bottom: 32px;
}

.tool-annotation-upload {
  margin-bottom: 24px;
}

.tool-annotation-files {
  margin: 24px 0;
  padding: 20px;
  background-color: var(--color-gray-ultra-light);
  border-radius: 8px;
  border: 1px solid var(--color-gray-medium-light);
}

.tool-annotation-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 12px;
}

.tool-annotation-tag {
  background-color: white;
  padding: 6px 12px;
  border-radius: 16px;
  font-size: 0.9rem;
  color: var(--color-text);
  border: 1px solid var(--color-gray-medium-light);
}

.tool-annotation-button {
  background-color: var(--color-primary);
  color: white;
  border: none;
  padding: 12px 24px;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.2s ease;
  margin-top: 24px;
}

.tool-annotation-button:hover {
  background-color: var(--color-secondary);
}

.tool-annotation-button:disabled {
  background-color: var(--color-gray-light);
  cursor: not-allowed;
}

.tool-annotation-databases {
  margin: 24px 0;
  padding: 20px;
  background-color: white;
  border-radius: 8px;
  border: 1px solid var(--color-gray-medium-light);
}

.tool-annotation-databases h3 {
  margin-bottom: 16px;
  font-size: 18px;
  color: var(--color-text);
}

.tool-annotation-databases-content {
  display: flex;
  gap: 24px;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.checkbox-group {
  display: flex;
  align-items: center;
  padding: 8px 12px;
  flex: 0 1 auto;
  min-width: 0;
  position: relative;
}

.checkbox-group:last-child {
  border-right: none;
}

.checkbox-group input[type="checkbox"] {
  width: 18px;
  height: 18px;
  margin-right: 12px;
  cursor: pointer;
  accent-color: var(--color-secondary);
}

.checkbox-group label {
  font-size: 14px;
  color: var(--color-text);
  cursor: pointer;
  border-bottom: 1px dotted transparent;
  transition: color 0.2s ease;
}

.checkbox-group:hover label {
  color: var(--color-secondary);
}

/* Remove the old tooltip styles */
.up-tooltip,
[data-tooltip-id],
[data-floating],
.MuiTooltip-tooltip,
.MuiTooltip-arrow {
  display: none !important;
}

/* Update the tooltip styles */
.ta-tooltip-container {
  position: relative;
}

.ta-tooltip {
  display: none;
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  padding: 8px 12px;
  background-color: var(--color-background);
  color: white !important;
  border-radius: 4px;
  font-size: 12px;
  white-space: normal;
  z-index: 1000;
  margin-bottom: 8px;
  width: max-content;
  max-width: 200px;
  text-align: center;
  line-height: 1.4;
  transition: opacity 0.2s ease;
}

.ta-tooltip * {
  color: white !important;
}

.ta-tooltip a {
  color: var(--color-primary) !important;
  text-decoration: none !important;
  transition: color 0.2s ease !important;
}

.ta-tooltip a:hover {
  color: white !important;
  text-decoration: underline !important;
}

.ta-tooltip::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-width: 5px;
  border-style: solid;
  border-color: var(--color-background) transparent transparent transparent;
}

.tool-annotation-warning {
  background-color: var(--color-error-light);
  color: var(--color-error);
  padding: 12px 16px;
  border-radius: 8px;
  margin: 16px 0;
  font-size: 14px;
  font-weight: 500;
  animation: fadeIn 0.3s ease;
}

.tool-annotation-success {
  background-color: var(--color-success-light);
  color: var(--color-success);
  padding: 12px 16px;
  border-radius: 8px;
  margin-top: 16px;
  font-size: 14px;
  font-weight: 500;
  animation: fadeIn 0.3s ease;
}

.tool-annotation-credits {
  margin: 16px 0 8px;
  font-size: 14px;
  color: var(--color-text-secondary);
  text-align: left;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}
