/* ToolSynthesisSuccessPredictor.css */
.toolsynthesis-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 32px;
  background-color: var(--color-gray-ultra-light);
  border-radius: 24px;
}

.toolsynthesis-title {
  font-size: 2.4rem;
  font-weight: 600;
  margin-bottom: 16px;
  color: var(--color-text);
}

.toolsynthesis-description {
  font-size: 1.2rem;
  color: var(--color-text-secondary);
  margin-bottom: 32px;
}

.toolsynthesis-upload {
  margin-bottom: 24px;
}

.toolsynthesis-vendors {
  margin: 24px 0;
  padding: 20px;
  background-color: white;
  border-radius: 8px;
  border: 1px solid var(--color-gray-medium-light);
}

.toolsynthesis-vendors h3 {
  margin-bottom: 16px;
  font-size: 18px;
  color: var(--color-text);
}

.toolsynthesis-vendors-options {
  display: flex;
  gap: 16px;
}

.radio-group {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.radio-group input[type="radio"] {
  width: 18px;
  height: 18px;
  margin-right: 8px;
  cursor: pointer;
  accent-color: var(--color-secondary);
}

.toolsynthesis-button {
  background-color: var(--color-primary);
  color: white;
  border: none;
  padding: 12px 24px;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 24px;
  transition: background-color 0.2s ease;
  min-width: 200px;
  min-height: 44px;
}

.toolsynthesis-button:hover {
  background-color: var(--color-secondary);
}

.toolsynthesis-button:disabled {
  background-color: var(--color-gray-light);
  cursor: not-allowed;
  opacity: 0.7;
}

.toolsynthesis-results {
  margin-top: 32px;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  border: 1px solid var(--color-gray-medium-light);
}

.toolsynthesis-results h3 {
  margin-bottom: 16px;
  font-size: 18px;
  color: var(--color-text);
}

.toolsynthesis-table {
  width: 100%;
  border-collapse: collapse;
  font-size: 14px;
  margin-bottom: 16px;
}

.toolsynthesis-table th,
.toolsynthesis-table td {
  border-bottom: 1px solid var(--color-gray-medium-light);
  padding: 8px;
  text-align: left;
  color: var(--color-text);
}

.toolsynthesis-table th {
  font-weight: 600;
  background-color: var(--color-gray-ultra-light);
}

.toolsynthesis-noresults {
  font-size: 14px;
  color: var(--color-text-secondary);
}

.toolsynthesis-loading {
  display: flex;
  align-items: center;
  gap: 8px;
}

.toolsynthesis-spinner {
  width: 20px;
  height: 20px;
  border: 3px solid #f3f3f3;
  border-top: 3px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.toolsynthesis-error {
  color: var(--color-error);
  padding: 12px;
  border-radius: 4px;
  background-color: var(--color-error-light);
  margin-bottom: 16px;
}

.toolsynthesis-table td {
  font-family: monospace;
  white-space: pre-wrap;
}

/* Add styling for array displays */
.toolsynthesis-table td ul {
  margin: 0;
  padding-left: 20px;
}
