.tool-page {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--color-gray-light);
  z-index: 2;
  padding: 0;
  overflow-y: auto;
  height: 100%;
}

.tool-page-back {
  position: absolute;
  left: 24px;
  top: 24px;
  background: none;
  border: none;
  font-size: 14px;
  cursor: pointer;
  color: var(--color-text);
  z-index: 10;
  transition: color 0.3s ease;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px;
  margin-top: 0;
}

.tool-page-back:hover {
  color: var(--color-primary);
}

.tool-page-back:hover svg {
  fill: var(--color-primary);
}

.tool-page-back svg {
  fill: var(--color-text);
  transition: fill 0.3s ease;
}

.tool-page-content {
  max-width: 1200px;
  margin: 80px auto 0;
  padding: 32px;
  background-color: var(--color-gray-ultra-light);
  border-radius: 24px;
  box-shadow: 0 4px 6px var(--color-shadow-soft);
}

.tool-page-title {
  font-size: 2.4rem;
  font-weight: 600;
  margin-bottom: 16px;
  color: var(--color-text);
}

.tool-page-description {
  font-size: 1.2rem;
  color: var(--color-text-light);
  margin-bottom: 32px;
}

.tool-page-close {
  position: absolute;
  right: 24px;
  top: 24px;
  background: none;
  border: none;
  font-size: 24px;
  font-weight: 200;
  cursor: pointer;
  color: var(--color-text);
  z-index: 10;
  transition: color 0.3s ease;
  padding: 8px;
}

.tool-page-close:hover {
  color: var(--color-primary);
}

/* Responsive design */
@media screen and (max-width: 640px) {
  .tool-page-content {
    margin-top: 60px;
    padding: 0 16px 16px;
  }

  .tool-page-back,
  .tool-page-close {
    top: 16px;
  }
}
