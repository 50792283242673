.pm-overlay {
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.pm-container {
  background: white;
  border-radius: 12px;
  width: 90%;
  max-width: 1000px;
  max-height: 90vh;
  overflow-y: auto;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
}

.pm-header {
  padding: 24px 32px;
  border-bottom: 1px solid #eee;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pm-header h1 {
  margin: 0;
  font-size: 28px;
  color: #333;
}

.pm-subheader {
  padding: 16px 32px;
  margin: 0;
  color: #666;
  font-weight: normal;
  border-bottom: 1px solid #eee;
}

.pm-content {
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.pm-top-row {
  display: flex;
  gap: 32px;
}

.pm-profile-box {
  flex: 0 0 300px;
  background: #f8f9fa;
  padding: 20px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 16px;
}

.pm-profile-image {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  object-fit: cover;
}

.pm-profile-info h4 {
  margin: 0 0 4px 0;
  color: #333;
}

.pm-profile-info p {
  margin: 0;
  color: #666;
  font-size: 14px;
}

.pm-usage-box {
  flex: 1;
  background: #f8f9fa;
  padding: 32px;
  border-radius: 8px;
}

.pm-bottom-row {
  display: flex;
  gap: 32px;
}

.pm-account-box {
  flex: 0 0 300px;
  background: #f8f9fa;
  padding: 32px;
  border-radius: 8px;
}

.pm-account-box h3 {
  margin-bottom: 24px;
  font-size: 18px;
  color: var(--color-text-dark);
}

.pm-subscription-controls {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 16px;
}

.pm-cancel-link {
  background: none;
  border: none;
  color: var(--color-error);
  text-decoration: underline;
  cursor: pointer;
  padding: 8px 0;
  font-size: 14px;
  text-align: left;
  margin-top: 8px;
}

.pm-cancel-link:hover {
  color: var(--color-error);
  opacity: 0.8;
}

.pm-progress-container {
  background: var(--color-secondary-light);
  height: 24px;
  border-radius: 12px;
  position: relative;
  overflow: hidden;
  margin-top: 16px;
}

.pm-progress-bar {
  background-color: var(--color-secondary);
  height: 100%;
  transition: width 0.3s ease;
}

.pm-progress-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #333;
  font-size: 14px;
  font-weight: 500;
}

.pm-usage-settings {
  flex: 1;
  background: #f8f9fa;
  padding: 32px;
  border-radius: 8px;
}

.pm-usage-settings .pm-button {
  margin-top: 8px;
  width: fit-content;
  align-self: flex-end;
}

.pm-toggle-container {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 24px;
}

.pm-toggle {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.pm-toggle input {
  opacity: 0;
  width: 0;
  height: 0;
}

.pm-toggle-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
}

.pm-toggle-slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

.pm-toggle input:checked + .pm-toggle-slider {
  background-color: var(--color-secondary);
}

.pm-toggle input:checked + .pm-toggle-slider:before {
  transform: translateX(26px);
}

.pm-toggle input:disabled + .pm-toggle-slider {
  background-color: #ccc;
  cursor: not-allowed;
}

.pm-spend-limit {
  margin-top: 16px;
}

.pm-input-group {
  display: flex;
  align-items: center;
  margin-top: 12px;
  margin-bottom: 8px;
}

.pm-currency {
  padding: 8px 12px;
  background: #e9ecef;
  border: 1px solid #ced4da;
  border-right: none;
  border-radius: 4px 0 0 4px;
}

.pm-input-group input {
  padding: 8px 12px;
  border: 1px solid #ced4da;
  border-radius: 0 4px 4px 0;
  width: 100px;
}

.pm-input-group input:focus {
  border-color: var(--color-secondary);
  outline: none;
}

.pm-input-group input:disabled {
  background-color: #f8f9fa;
  cursor: not-allowed;
}

.pm-current-spend {
  margin-top: 8px;
  color: #666;
  font-size: 14px;
}

.pm-close {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #666;
  padding: 4px 8px;
}

.pm-plan-info {
  margin-bottom: 32px;
}

.pm-tier-display {
  background: #f8f9fa;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
}

.pm-amount {
  font-size: 32px;
  font-weight: bold;
  color: #333;
}

.pm-messages {
  display: block;
  color: #666;
  margin-top: 8px;
}

.pm-tier-controls {
  margin-top: 32px;
}

.pm-tier-buttons {
  display: flex;
  gap: 16px;
  margin-top: 16px;
}

.pm-button {
  padding: 12px 24px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  flex: 1;
  transition: background-color 0.2s;
}

.pm-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.pm-button.pm-primary {
  background-color: var(--color-secondary);
  color: white;
  padding: 14px 24px;
  font-weight: var(--font-weight-medium);
  transition: background-color 0.2s ease;
}

.pm-button.pm-primary:hover:not(:disabled) {
  background-color: hsl(294, 80%, 48%);
}

.pm-button:not(.pm-primary) {
  background-color: #e9ecef;
  color: #333;
}

.pm-button:not(.pm-primary):hover:not(:disabled) {
  background-color: #dee2e6;
}

.pm-message {
  margin-top: 24px;
  padding: 12px;
  border-radius: 6px;
  text-align: center;
}

.pm-error {
  background-color: #fee2e2;
  color: #dc2626;
}

.pm-success {
  background-color: #d1fae5;
  color: #059669;
}

.pm-footer {
  padding: 24px 32px;
  border-top: 1px solid #eee;
  text-align: center;
  color: #666;
}

.pm-footer a {
  color: #007bff;
  text-decoration: none;
}

.pm-footer a:hover {
  text-decoration: underline;
}

.pm-renewal-date {
  color: var(--color-text-light);
  font-size: 14px;
  text-align: left;
  margin: 0;
  padding: 8px 0;
}

@media (max-width: 768px) {
  .pm-top-row,
  .pm-bottom-row {
    flex-direction: column;
  }

  .pm-profile-box,
  .pm-account-box {
    flex: none;
    width: 100%;
  }
}

.pm-modal-overlay {
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1100;
}

.pm-confirm-modal {
  background: white;
  border-radius: 8px;
  padding: 24px;
  width: 90%;
  max-width: 400px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
}

.pm-confirm-modal h3 {
  margin: 0 0 16px 0;
  color: var(--color-text-dark);
}

.pm-confirm-modal p {
  margin: 0 0 24px 0;
  color: var(--color-text-light);
  line-height: 1.5;
}

.pm-confirm-buttons {
  display: flex;
  gap: 16px;
}

.pm-confirm-buttons .pm-button {
  flex: 1;
}

.pm-disabled {
  opacity: 0.5;
  pointer-events: none;
}

.pm-upgrade-notice {
  color: var(--color-text-light);
  font-size: 14px;
  margin: 8px 0 16px;
  font-style: italic;
}

.pm-usage-note {
  font-size: 0.75rem;
  color: #666;
  margin-top: 16px;
  line-height: 1.4;
  font-style: italic;
}
