.design-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;
  margin: 0;
  overflow: hidden;
  position: relative;
}

.design-specification {
  flex-shrink: 0;
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 1;
}

.design-specification-box {
  width: 260px;
  min-height: 120px;
  padding: 10px;
  border-radius: 5px;
  background-color: var(--color-spec-background);
  border: 1px solid var(--color-spec-border);
  margin-bottom: 20px;
  box-shadow: 0 2px 4px var(--color-shadow-soft);
}

.design-specification-box .header {
  border-bottom: 2px solid var(--color-gray-extra-light);
  margin-bottom: 5px;
  padding-bottom: 5px;
  font-weight: 500;
  font-size: 12px;
  margin: -15px -15px 15px -15px;
  padding: 15px;
  border-radius: 5px 5px 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.design-specification-box p {
  font-size: 12px;
  margin: 10px 0;
  display: grid;
  grid-template-columns: 120px 1fr;
  gap: 12px;
  align-items: start;
  min-height: 24px;
}

.design-specification-box .label {
  font-weight: 500;
  text-align: right;
  display: inline-block;
  font-size: 12px;
  white-space: nowrap;
}

.design-specification-box span:not(.label) {
  text-align: left;
  font-size: 12px;
  word-wrap: break-word;
  word-break: break-word;
  hyphens: auto;
  overflow-wrap: break-word;
  line-height: 1.4;
  padding-right: 5px;
}

.design-specification-box .icon {
  margin-right: 5px !important;
  vertical-align: middle;
}

.design-specification-box .icon svg {
  stroke: var(--color-secondary);
  fill: var(--color-secondary);
}

.design-specification-box .circle svg path {
  stroke-width: 4;
}

.design-specification-box .line:nth-of-type(2) {
  stroke-width: 2;
}

.design-content-container {
  flex: 1;
  display: flex;
  position: relative;
  min-height: 0;
  width: 100%;
  margin-bottom: 10%;
  margin-top: 20px;
}

.design-content-container svg {
  width: 100%;
  height: 100%;
  display: block;
  max-height: 100vh;
}

.instructions-section {
  flex-shrink: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  padding: 0 20px;
  width: 100%;
}

.generate-instructions-button {
  background-color: var(--color-secondary);
  color: var(--color-gray-extra-light);
  border: none;
  padding: 8px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease, color 0.3s ease, border 0.3s ease;
  display: flex !important;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 40px;
  font-weight: 600;
  gap: 0.5rem;
  margin: 0;
  line-height: 1;
  position: relative;
}

.generate-instructions-button.regenerate {
  background-color: var(--color-secondary-light);
  color: var(--color-text);
}

.generate-instructions-button.regenerate span {
  color: var(--color-text);
}

.generate-instructions-button.regenerate svg {
  fill: var(--color-text);
}

.generate-instructions-button:hover {
  background-color: var(--color-background);
}

.generate-instructions-button:hover span {
  color: var(--color-secondary);
}

.generate-instructions-button:hover svg {
  fill: var(--color-secondary);
}

.generate-instructions-button.regenerate:hover {
  background-color: white;
}

.generate-instructions-button.regenerate:hover span {
  color: var(--color-secondary);
}

.generate-instructions-button.regenerate:hover svg {
  fill: var(--color-secondary);
}

.generate-instructions-button span {
  margin: auto;
  color: var(--color-gray-extra-light);
  white-space: nowrap;
}

.generate-instructions-button svg {
  fill: var(--color-gray-extra-light);
  width: 16px;
  flex-shrink: 0;
  stroke-width: 1px;
  transition: fill 0.3s ease;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.generate-instructions-button .spinning {
  animation: spin 1s linear infinite;
}

.error-message {
  background-color: var(--color-error);
  color: var(--color-gray-extra-light);
  padding: 10px;
  margin-top: 10px;
  border-radius: 4px;
  font-size: 14px;
}

.back-button,
.forward-button {
  position: absolute;
  top: 14px;
  background: none;
  border: none;
  cursor: pointer;
  transition: fill 0.3s ease;
}

.back-button {
  left: 3.5rem;
}

.forward-button {
  left: 5rem;
}

.back-button:hover svg,
.forward-button:hover svg {
  fill: var(--color-secondary);
}

.back-button svg,
.forward-button svg {
  height: 16px;
  margin-top: 1px;
}

.confirmation-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--color-overlay-background);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.confirmation-popup-dialog {
  background-color: var(--color-background);
  padding: 20px;
  border-radius: 5px;
  max-width: 400px;
}

.confirmation-popup-buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.confirmation-popup-buttons button {
  margin-left: 10px;
  padding: 5px 10px;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

.confirm-button {
  background-color: var(--color-success);
  color: var(--color-background);
}

.cancel-button {
  background-color: var(--color-error);
  color: var(--color-background);
}

.custom-tooltip {
  position: fixed;
  z-index: 1000;
  background-color: var(--color-tooltip-background);
  color: var(--color-tooltip-text);
  padding: 8px 12px;
  border-radius: 4px;
  font-size: 12px;
  max-width: 250px;
  line-height: 1.4;
  pointer-events: none;
  white-space: normal;
  word-wrap: break-word;
}

/* Ensure text doesn't get clipped */
.design-specification-box span {
  white-space: normal;
  word-wrap: break-word;
  max-width: 100%;
  max-width: 250px;
  white-space: normal;
  line-height: 1.4;
}

.artifact-confirmation-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--color-overlay-background);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.artifact-confirmation-popup-dialog {
  background-color: var(--color-background);
  padding: 32px;
  border-radius: 8px;
  max-width: 600px;
  width: 90%;
  box-shadow: 0 4px 20px var(--color-shadow-medium);
  position: relative;
}

.artifact-confirmation-popup-dialog h3 {
  font-size: 24px;
  margin: 0 0 20px 0;
  color: var(--color-secondary);
  font-weight: 600;
  text-align: left;
}

.artifact-confirmation-popup-dialog p {
  font-size: 18px;
  line-height: 1.6;
  margin: 16px 0;
  color: var(--color-text);
  text-align: left;
}

.artifact-confirmation-popup-dialog p:nth-child(2),
.artifact-confirmation-popup-dialog p:nth-child(3) {
  font-size: 20px;
  font-weight: 500;
}

.artifact-confirmation-popup-dialog .protocol-dropdown {
  width: 100%;
  margin: 8px 0 20px 0;
  text-align: left;
}

.artifact-confirmation-popup-dialog .group-selection-label {
  font-size: 16px;
  margin: 24px 0 12px 0;
  color: var(--color-background);
  font-weight: 500;
}

.artifact-confirmation-popup-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  margin-top: 32px;
}

.artifact-confirmation-popup-buttons button {
  padding: 10px 20px;
  font-size: 16px;
  font-weight: 500;
  min-width: 120px;
}

/* Improve dropdown styling */
.artifact-confirmation-popup-dialog .protocol-dropdown {
  width: 100%;
  margin: 8px 0 20px 0;
}

.protocol-dropdown__control {
  min-height: 40px !important;
  height: 40px !important;
}

.protocol-dropdown__value-container {
  height: 40px !important;
  padding: 0 12px !important;
}

.protocol-dropdown__single-value {
  font-size: 15px !important;
}

.artifact-confirm-button {
  background-color: var(--color-secondary);
  color: var(--color-gray-extra-light);
}

.artifact-confirm-button:hover {
  background-color: var(--color-primary);
}

.artifact-cancel-button {
  background-color: var(--color-error);
  color: var(--color-gray-extra-light);
}

.artifact-cancel-button:hover {
  background-color: var(--color-error);
  opacity: 0.8;
}

.design-error-message {
  background-color: var(--color-error);
  color: var(--color-gray-extra-light);
  padding: 10px;
  border-radius: 4px;
  font-size: 14px;
  position: absolute;
  bottom: 80px;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 40px);
  text-align: center;
  font-weight: var(--font-weight-bold);
}

.error-message-box {
  background-color: var(--color-error);
  border: 1px solid var(--color-primary);
  border-radius: 4px;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.error-title {
  color: var(--color-text);
  font-weight: bold;
  margin: 0 0 5px 0;
}

.error-content {
  color: var(--color-text);
  margin: 0;
}

.group-selection-label {
  margin-top: 15px;
  margin-bottom: 8px;
  font-weight: 500;
  text-align: left;
  width: 100%;
}

.group-select {
  display: none;
}

.artifact-confirmation-credits-table {
  background-color: var(--color-gray-light);
  border-radius: 8px;
  padding: 20px;
  margin-top: 20px;
  margin-bottom: 32px;
}

.artifact-confirmation-credits-table h3 {
  margin-bottom: 12px;
}

.credits-explanation {
  font-size: 12px !important;
  text-align: left;
  margin-bottom: 20px;
  line-height: 1.1 !important;
  color: var(--color-text);
}

.artifact-confirmation-credits-table table {
  width: 100%;
  border-collapse: collapse;
  font-size: 0.9rem;
}

.artifact-confirmation-credits-table th {
  padding: 8px;
  text-align: left;
  border-bottom: 2px solid var(--color-gray-medium-light);
  font-weight: 600;
  font-size: 0.85rem;
  white-space: nowrap;
}

.artifact-confirmation-credits-table td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid var(--color-gray-medium-light);
}

.artifact-confirmation-popup-summary {
  display: flex;
  flex-direction: column;
  gap: 16px;
  background-color: var(--color-gray-light);
  border-radius: 8px;
  padding: 20px;
}

.artifact-confirmation-popup-summary-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
  border-bottom: 1px solid var(--color-gray-medium-light);
}

.artifact-confirmation-popup-value-light {
  font-weight: var(--font-weight-normal);
  color: var(--color-text);
}

.artifact-confirmation-popup-value-bold {
  font-weight: var(--font-weight-bold);
  color: var(--color-text);
}

.artifact-confirmation-group-section {
  margin: 20px 0;
  min-height: 90px;
  visibility: visible;
  text-align: left;
}

.artifact-confirmation-group-section.hidden {
  visibility: hidden;
}

.artifact-confirmation-group-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}

.artifact-confirmation-total-credits {
  font-weight: 500;
  color: var(--color-text);
}

.artifact-confirmation-popup-close {
  position: absolute;
  top: 16px;
  right: 16px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: var(--color-text);
  padding: 8px;
}

.design-content-container svg text {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.design-content-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.design-navigation-buttons {
  display: flex;
  gap: 10px;
}

.nav-button {
  background: none;
  border: none;
  padding: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
  position: relative;
}

.nav-button:hover {
  color: var(--color-secondary);
}

.design-tooltip-text {
  cursor: default;
}

.design-tooltip-text.design-has-tooltip {
  border-bottom: 1px dotted var(--color-text);
  cursor: help;
}

.artifact-confirmation-popup-summary-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
  border-bottom: 1px solid var(--color-gray-medium-light);
}

.artifact-confirmation-popup-summary-item span {
  display: inline-block;
}

.tooltip-trigger {
  border-bottom: 2px dashed var(--color-tooltip-border);
  cursor: help;
  position: relative;
  display: inline-block;
  transition: border-bottom-color 0.2s ease;
  padding-bottom: 2px;
  width: fit-content;
  margin-left: auto;
}

.tooltip-trigger:hover {
  border-bottom-color: var(--color-secondary);
  border-bottom-style: dashed;
}

.button-container {
  position: absolute;
  bottom: 20px;
  width: calc(100% - 40px);
  display: flex;
  justify-content: flex-end;
  z-index: 1;
}

.button-container.dual-buttons {
  justify-content: space-between;
}

.button-container.single-button {
  justify-content: flex-end;
}

.artifact-confirmation-popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.artifact-confirmation-popup-header h2 {
  margin: 0;
  font-size: 24px;
  /* color: var(--color-secondary); */
  font-weight: 600;
  text-align: left;
}

/* Add styles for disabled nav buttons */
.nav-button.disabled {
  color: var(--color-disabled);
  cursor: not-allowed;
}

.nav-button.disabled:hover {
  color: var(--color-disabled);
}

.header {
  position: relative;
  display: flex;
  align-items: center;
  gap: 8px;
}

.nav-tooltip {
  position: absolute;
  top: calc(100% + 8px);
  right: 0;
  background-color: var(--color-background) !important;
  background: var(--color-background) !important;
  border: 1px solid var(--color-secondary);
  border-radius: 4px;
  padding: 6px 10px;
  font-size: 12px;
  color: var(--color-text);
  white-space: nowrap;
  z-index: 1000;
  box-shadow: 0 2px 4px var(--color-shadow-soft);
  pointer-events: none;
}
