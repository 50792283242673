.markdown-content {
  width: 100%;
  max-width: 1200px;
  padding: 20px 20px 20px;
  /* Added top padding */
  color: black;
  margin: 0 auto;
}

.markdown-content h1 {
  color: var(--color-secondary);
  margin-bottom: 20px;
  font-size: 24px;
  /* Reduced font size */
}

.markdown-content h2 {
  font-size: 20px;
  padding: 10px 0;
  /* Added vertical padding */
}

.markdown-content h3 {
  font-size: 18px;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 8px 0;
  /* Added vertical padding */
}

.markdown-content .input-group,
.markdown-content .checkbox-group,
.markdown-content .dropdown-group {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  flex-wrap: nowrap;
  /* Prevent wrapping */
}

.markdown-content label {
  min-width: 150px;
  /* Changed from width to min-width */
  margin-right: 10px;
  margin-bottom: 0;
  color: black;
  font-weight: 500;
  white-space: nowrap;
  flex-shrink: 0;
}

.markdown-content input[type="text"],
.markdown-content input[type="number"],
.markdown-content select,
.markdown-content .editable-table input[type="text"] {
  flex: 0 0 auto;
  /* Don't grow or shrink, use auto basis */
  width: 100px;
  /* Set a fixed width */
  max-width: 100px;
  /* Set max-width to 200px as requested */
  padding: 8px 15px;
  font-size: 14px;
  border: 1px solid var(--color-gray-medium);
  border-radius: 4px;
  background-color: white;
  color: var(--color-text);
  transition: border-color 0.3s ease;
}

.markdown-content input[type="text"]:focus,
.markdown-content input[type="number"]:focus,
.markdown-content select:focus,
.markdown-content .editable-table input[type="text"]:focus {
  outline: none;
  border-color: var(--color-secondary);
  box-shadow: 0 0 0 2px var(--color-secondary-shadow);
}

.markdown-content input[type="checkbox"] {
  margin-right: 10px;
}

.markdown-content table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
  padding: 10px;
  /* Added padding around tables */
}

.markdown-content th,
.markdown-content td {
  padding: 10px;
  text-align: left;
  border: 1px solid var(--color-gray-medium);
  font-size: 13px;
  /* Reduced font size in tables */
}

.markdown-content th {
  background-color: var(--color-gray-light);
  /* Lighter background for header */
  font-weight: bold;
  color: var(--color-text);
}

.markdown-content tr:nth-child(even) {
  background-color: var(--color-gray-light);
}

.markdown-content tr:hover {
  background-color: var(--color-gray-light);
}

.markdown-content .editable-table input[type="text"] {
  width: 100%;
  padding: 4px 8px;
  font-size: 14px;
  border: 1px solid var(--color-gray-medium);
  border-radius: 4px;
  background-color: white;
  color: var(--color-text);
}

.markdown-content .dropdown-group input[type="text"] {
  padding-right: 30px;
}

.markdown-content .dropdown-group {
  position: relative;
}

.markdown-content .dropdown-group::after {
  content: "▼";
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  color: black;
}

.markdown-content .result {
  margin-top: 20px;
  padding: 10px;
  background-color: var(--color-gray-light);
  border-radius: 4px;
  font-weight: bold;
  color: var(--color-secondary);
}

.artifact-panel-submit-button {
  background-color: var(--color-secondary);
  color: var(--color-background);
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
  white-space: nowrap;
  margin-top: 20px;
}

.artifact-panel-submit-button:hover {
  background-color: var(--color-primary);
}

.toggle-exclamation {
  padding: 5px 10px;
  background-color: var(--color-secondary);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
  transition: background-color 0.3s ease;
}

.toggle-exclamation:hover {
  background-color: var(--color-primary);
}

.markdown-content textarea {
  width: 100%;
  height: 100%;
  min-height: 400px;
  padding: 15px;
  font-size: 14px;
  line-height: 1.6;
  border: 1px solid var(--color-gray-medium);
  border-radius: 4px;
  background-color: var(--color-gray-extra-light);
  color: var(--color-text);
  resize: vertical;
}

.markdown-content textarea:focus {
  outline: none;
  border-color: var(--color-secondary);
  box-shadow: 0 0 0 2px var(--color-secondary-shadow);
}

.bmi-calculator {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  background-color: var(--color-gray-extra-light);
  border-radius: 8px;
  box-shadow: 0 2px 4px var(--color-shadow-medium);
}

.bmi-calculator h2 {
  text-align: center;
  color: var(--color-secondary);
  margin-bottom: 20px;
}

.input-group {
  margin-bottom: 15px;
}

.input-group label {
  display: block;
  margin-bottom: 5px;
  color: var(--color-text);
}

.input-group input {
  width: 100%;
  padding: 8px;
  border: 1px solid var(--color-gray-medium);
  border-radius: 4px;
  font-size: 14px;
}

.calculate-button {
  width: 100%;
  padding: 10px;
  background-color: var(--color-secondary);
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.calculate-button:hover {
  background-color: var(--color-primary);
}

.result {
  margin-top: 20px;
  text-align: center;
}

.result p {
  margin: 5px 0;
  font-size: 16px;
  color: var(--color-text);
}

.result p:first-child {
  font-weight: bold;
  color: var(--color-secondary);
}

.protocol-dropdown {
  width: 100%;
  max-width: 300px;
  background-color: white;
  /* min-width: 500px; */
}

.protocol-dropdown__control {
  background-color: white !important;
  border-color: transparent !important;
  min-height: 32px !important;
  height: 32px !important;
  box-shadow: none !important;
}

.protocol-dropdown__control:hover {
  border-color: transparent !important;
}

.protocol-dropdown__value-container {
  height: 32px !important;
  padding: 0 8px !important;
}

.protocol-dropdown__input {
  margin: 0 !important;
}

.protocol-dropdown__indicators {
  height: 32px !important;
}

.protocol-dropdown__menu {
  background-color: white !important;
  color: black !important;
  border: none !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1) !important;
}

.protocol-dropdown__option {
  background-color: white !important;
  color: black !important;
}

.protocol-dropdown__option--is-focused {
  background-color: var(--color-secondary) !important;
  color: white !important;
}

.protocol-dropdown__single-value {
  color: black !important;
}

/* Ensure the dropdown is visible */
.protocol-content td {
  overflow: visible;
}

.markdown-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;
  padding-top: 20px;
  /* Added padding to the top */
}

.protocol-selection {
  display: flex;
  align-items: center;
  flex-grow: 1;
  margin-left: 20px;
  gap: 20px;
  justify-content: flex-end;
}

.protocol-selection label {
  margin-right: 10px;
  white-space: nowrap;
  text-align: right;
}

.protocol-dropdown {
  width: 300px;
  min-width: 200px;
}

.artifact-panel-submit-button {
  background-color: var(--color-secondary);
  color: var(--color-background);
  border: none;
  padding: 12px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
  white-space: nowrap;
  margin-right: 20px;
  margin-top: 5px;
}

.artifact-panel-submit-button:hover {
  background-color: var(--color-primary);
}

.table-wrapper {
  margin-top: 20px;
}

.markdown-content ul {
  padding-left: 20px;
  list-style-type: disc;
  margin-bottom: 10px;
}

.markdown-content ul ul {
  list-style-type: circle;
  margin-top: 5px;
  margin-bottom: 5px;
}

.markdown-content ul ul ul {
  list-style-type: square;
}

.markdown-content li {
  padding: 2px 0;
  margin-left: 20px;
  display: list-item;
}

.markdown-content li li {
  margin-left: 0;
}

/* Add styles for hyperlinks */
.markdown-content a {
  color: var(--color-secondary);
  text-decoration: none;
  transition: color 0.3s ease;
}

.markdown-content a:hover {
  color: var(--color-primary);
  text-decoration: underline;
}

/* Add this new class to your existing CSS file */
.markdown-content .input-line {
  padding-top: 5px;
  padding-bottom: 5px;
}

/* Adjust the existing input styles to remove any conflicting margins */
.markdown-content input[type="text"],
.markdown-content input[type="number"] {
  margin-top: 0;
  margin-bottom: 0;
}

.protocol-markdown-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;
  padding-top: 20px;
}

.protocol-button-group {
  display: flex;
  align-items: center;
}

.protocol-download-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 8px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.protocol-download-icon {
  width: 24px;
  height: 24px;
}

.protocol-submit-button {
  background-color: var(--color-secondary);
  color: var(--color-background);
  border: none;
  padding: 12px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
  white-space: nowrap;
  margin-bottom: -11px;
}

.protocol-submit-button:hover {
  background-color: var(--color-primary);
}

.table-checkbox {
  width: 18px !important;
  height: 18px !important;
  cursor: pointer;
  accent-color: var(--color-secondary);
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

/* Update the dropdown-container class */
.dropdown-container {
  display: flex;
  align-items: center;
  flex: 0 1 auto;
  min-width: 200px;
  max-width: 300px;
}

/* Style for the labels inside dropdown containers */
.dropdown-container label {
  margin-right: 15x;
  white-space: nowrap;
  flex-shrink: 0;
  /* Prevents label from shrinking */
}

/* Base protocol-selection styling */
.protocol-selection {
  display: flex;
  align-items: center;
  flex-grow: 1;
  margin-left: 20px;
  gap: 20px;
}

/* Base dropdown-container styling */
.dropdown-container {
  display: flex;
  align-items: center;
  flex: 1;
  min-width: 200px;
}

/* Add specific classes for synthesis_to_order */
.protocol-selection.synthesis-to-order {
  justify-content: flex-end;
}

.synthesis-to-order .dropdown-container {
  flex: 0 1 auto;
  width: 300px;
}

.synthesis-to-order .dropdown-container .protocol-dropdown {
  margin-top: 10px;
}

.synthesis-to-order .dropdown-container label {
  margin-bottom: 8px;
}

/* Regular protocol label styling */
.protocol-selection:not(.synthesis-to-order) label {
  margin-right: 10px;
  white-space: nowrap;
}

/* Base protocol-markdown-controls styling */
.protocol-markdown-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* Back to center for default case */
  margin-bottom: 20px;
  width: 100%;
  padding-top: 20px;
  max-width: 1200px;
}

/* Base protocol-selection styling */
.protocol-selection {
  display: flex;
  align-items: center;
  flex-grow: 1;
  margin-left: 20px;
  gap: 20px;
}

/* Protocol button group */
.protocol-button-group {
  display: flex;
  align-items: center;
}

/* Synthesis to order specific styles */
.protocol-selection.synthesis-to-order {
  justify-content: flex-end;
}

.synthesis-to-order .dropdown-container {
  flex: 0 1 auto;
  width: 300px;
}

.synthesis-to-order .dropdown-container label {
  margin-bottom: 8px;
}

/* Regular protocol label styling */
.protocol-selection:not(.synthesis-to-order) label {
  margin-right: 10px;
  white-space: nowrap;
}

/* Adjust button alignment for synthesis to order */
.synthesis-to-order + .protocol-button-group {
  margin-top: 32px;
}

.csv-content {
  width: 100%;
  white-space: pre;
  font-family: monospace;
  padding: 20px;
  background-color: white;
  border: 1px solid var(--color-gray-medium);
  border-radius: 4px;
  overflow-x: auto;
}

.csv-header {
  display: flex;
  align-items: center;
  gap: 10px;
}

.copy-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.7;
  transition: opacity 0.2s;
}

.copy-button:hover {
  opacity: 1;
}

.copy-button img {
  width: 16px;
  height: 16px;
}

.copy-button-container {
  position: relative;
  display: inline-block;
}

.copy-notification {
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--color-background);
  color: var(--color-text);
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
  animation: fadeInOut 2s ease-in-out;
  z-index: 1000;
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }

  15% {
    opacity: 1;
  }

  85% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

/* Add these new styles for the collapsible section */
.collapsible-header {
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
  margin: 10px 0;
}

.collapsible-header h2 {
  margin: 0;
  padding: 0;
  display: inline-flex;
  align-items: center;
}

.collapsible-toggle {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  margin-left: 10px;
  font-size: 18px;
  font-weight: bold;
  color: var(--color-secondary);
}

.preferences-content {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 8px 16px;
  padding: 10px 0;
}

.preference-label {
  font-weight: 500;
  color: var(--color-text-light);
}

.preference-value {
  color: black;
}

.carousel-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin: 30px 0;
  position: relative;
  padding: 10px;
}

.carousel-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.carousel-button {
  background: none;
  color: var(--color-secondary);
  border: none;
  padding: 0;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 32px;
  cursor: pointer;
  transition: color 0.3s ease;
  z-index: 2;
}

.carousel-button:hover {
  color: var(--color-primary);
}

.carousel-button.prev {
  margin-right: 10px;
}

.carousel-button.next {
  margin-left: 10px;
}

.carousel-indicators {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  gap: 5px;
}

.image-counter {
  background: rgba(0, 0, 0, 0.6);
  color: var(--color-text);
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 14px;
}

/* Update existing protocol-image class */
.protocol-image {
  max-width: 100%;
  height: auto;
  margin: 20px 0;
  border-radius: 4px;
  box-shadow: 0 2px 4px var(--color-shadow-medium);
  transition: opacity 0.3s ease;
}

@media (max-width: 768px) {
  .carousel-button {
    width: 32px;
    height: 32px;
    font-size: 20px;
  }

  .image-counter {
    font-size: 12px;
  }
}

/* Add specific styling for ladder select */
.markdown-content #ladder_select {
  margin: 15px 0;
  min-width: 200px;
  width: 100%;
  max-width: 300px;
}

/* Update Select component container */
.protocol-dropdown__container {
  margin: 15px 0;
  padding: 5px 0;
}

.copy-tooltip {
  position: absolute;
  background-color: var(--color-background);
  color: var(--color-text);
  padding: 6px 12px;
  border-radius: 4px;
  font-size: 12px;
  pointer-events: none;
  z-index: 1000;
  animation: tooltipFade 2s ease;
  left: 82px;
  top: 40px;
  white-space: nowrap;
}

@keyframes tooltipFade {
  0% {
    opacity: 0;
  }

  10% {
    opacity: 1;
  }

  90% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.protocol-download-button,
.protocol-copy-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 8px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.protocol-download-icon,
.protocol-copy-icon {
  width: 24px;
  height: 24px;
}

.protocol-button-group {
  position: relative;
}

.page-content.no-sidebar {
  margin-left: 0;
  width: 100%;
}
