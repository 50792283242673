.upload-to-tool {
  margin-top: 20px;
}

.tool-page-file-button {
  background-color: var(--color-primary);
  color: var(--color-gray-ultra-light);
  border: none;
  border-radius: 8px;
  padding: 12px 24px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.tool-page-file-button:hover {
  background-color: var(--color-secondary);
}

.tool-page-file-button:focus {
  outline: none;
  box-shadow: 0 0 0 2px var(--color-secondary);
}

.selected-files {
  margin-top: 24px;
  padding: 16px;
  background: var(--color-background-light);
  border-radius: 8px;
}

.selected-files h3 {
  margin-bottom: 16px;
  font-size: 1.1rem;
  color: var(--color-text);
}

.selected-files-list {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.selected-file {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 6px 12px;
  background: var(--color-gray-ultra-light);
  border: 1px solid var(--color-border);
  border-radius: 4px;
  font-size: 0.9rem;
}

.remove-file-button {
  background: none;
  border: none;
  padding: 2px;
  cursor: pointer;
  color: var(--color-text-light);
  display: flex;
  align-items: center;
  transition: color 0.2s ease;
}

.remove-file-button:hover {
  color: var(--color-error);
}
