.order-dna-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--color-overlay-background);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.order-dna-modal {
  background-color: var(--color-input-background);
  padding: 24px;
  border-radius: 12px;
  width: 1000px;
  max-width: 95%;
  box-shadow: 0 8px 30px var(--color-shadow-medium);
  border: 1px solid var(--color-border-transparent);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  z-index: 1001;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.order-dna-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.order-dna-header h2 {
  color: var(--color-text);
  margin: 0;
  font-size: var(--font-size-xl);
  font-weight: var(--font-weight-semibold);
}

.order-dna-close {
  background: none;
  border: none;
  font-size: var(--font-size-xl);
  cursor: pointer;
  padding: 0px 8px;
  color: var(--color-text);
  border-radius: 4px;
}

.order-dna-content {
  margin-bottom: 24px;
  color: var(--color-text);
}

.order-dna-content p {
  margin: 0 0 16px 0;
}

.order-dna-footer {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  margin-top: 16px;
}

.order-dna-button {
  padding: 12px 24px;
  border-radius: 6px;
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-semibold);
}

.order-dna-submit {
  background-color: var(--color-primary);
}

.order-dna-submit:hover {
  background-color: var(--color-secondary);
}

.order-dna-cancel {
  background-color: var(--color-gray-medium);
}

.order-dna-cancel:hover {
  background-color: var(--color-primary);
}

.order-dna-form {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.order-dna-form-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.order-dna-label {
  color: var(--color-text);
  font-weight: var(--font-weight-medium);
}

.order-dna-input {
  padding: 12px 16px;
  border: 1px solid var(--color-input-border);
  border-radius: 6px;
  font-size: var(--font-size-lg);
  background-color: var(--color-input-background);
  color: var(--color-text);
  transition: border-color 0.3s ease;
}

.order-dna-select {
  padding: 12px 16px;
  border: 1px solid var(--color-input-border);
  border-radius: 6px;
  font-size: var(--font-size-lg);
  background-color: var(--color-input-background);
  color: var(--color-text);
  transition: border-color 0.3s ease;
}

.order-dna-files {
  border: 1px solid var(--color-input-border);
  border-radius: 6px;
  padding: 16px;
}

.order-dna-file {
  padding: 8px;
  margin: 4px 0;
  background-color: var(--color-gray-medium);
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.order-dna-checkbox-label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.order-dna-checkbox-label input[type="checkbox"] {
  width: 18px !important;
  height: 18px !important;
  cursor: pointer;
  accent-color: var(--color-secondary);
}

.order-dna-process {
  margin: 0 0 20px 0;
  padding: 24px;
  background-color: var(--color-gray-extra-light);
  border-radius: 8px;
  width: 100%;
}

.order-dna-process-title {
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-semibold);
  color: var(--color-text);
  margin-bottom: 20px;
}

.order-dna-process-steps {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  padding: 0;
}

.order-dna-process-step {
  display: grid;
  grid-template-rows: auto minmax(32px, auto) minmax(40px, auto);
  align-items: start;
  gap: 8px;
  text-align: center;
  width: 140px;
  height: 120px;
}

.order-dna-step-number {
  margin: 0 auto;
  width: 32px;
  height: 32px;
  background-color: var(--color-primary);
  color: var(--color-background);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-semibold);
}

.order-dna-step-text {
  font-size: var(--font-size-sm);
  color: var(--color-text);
  font-weight: var(--font-weight-medium);
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.order-dna-step-description {
  font-size: var(--font-size-xs);
  color: var(--color-text-light);
  line-height: 1.2;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
}

.order-dna-step-arrow {
  color: var(--color-primary);
  font-size: var(--font-size-lg);
  height: 32px;
  margin-top: -84px;
}

.order-dna-shipping-note {
  font-size: var(--font-size-sm);
  color: var(--color-text-light);
  font-style: italic;
  margin-top: 8px;
}

.order-dna-summary {
  display: flex;
  flex-direction: column;
  gap: 12px;
  background-color: var(--color-gray-light);
  border-radius: 8px;
  padding: 20px;
}

.order-dna-summary-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
  border-bottom: 1px solid var(--color-gray-medium-light);
}

.order-dna-summary-item:last-child {
  border-bottom: none;
}

.order-dna-summary-value {
  font-weight: var(--font-weight-semibold);
  color: var(--color-text);
}

.order-dna-verify-option {
  grid-column: 1 / -1;
  padding: 16px 0;
  border-bottom: 1px solid var(--color-gray-medium-light);
}

.order-dna-verify-text {
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.order-dna-verify-text span:first-child {
  font-weight: var(--font-weight-medium);
  color: var(--color-text);
}

.order-dna-verify-price {
  font-size: var(--font-size-sm);
  color: var(--color-text-light);
}

.order-dna-total {
  background-color: transparent;
  margin: 0;
  padding: 12px 0;
  border-bottom: 1px solid var(--color-gray-medium-light);
}

.order-dna-total-row {
  font-weight: var(--font-weight-bold);
}

.order-dna-price-detail {
  display: block;
  font-size: var(--font-size-sm);
  color: var(--color-text-light);
  font-weight: var(--font-weight-normal);
}

.order-dna-beta-message {
  margin-top: 24px;
  padding: 16px;
  background-color: var(--color-gray-extra-light);
  border-radius: 6px;
  color: var(--color-text-medium);
  font-size: var(--font-size-sm);
  line-height: 1.5;
  grid-column: 1 / -1;
}

.order-dna-input:disabled {
  background-color: var(--color-gray-light);
  color: var(--color-text-light);
  cursor: not-allowed;
}

.order-dna-summary-value-light {
  font-weight: var(--font-weight-normal);
  color: var(--color-text);
}

.order-dna-summary-value-bold {
  font-weight: var(--font-weight-bold);
  color: var(--color-text);
}

.order-dna-verify-right {
  display: flex;
  align-items: center;
}

.order-dna-total-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.order-dna-verify-left {
  display: flex;
  align-items: center;
  gap: 12px;
}

.order-dna-sequence-control {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.order-dna-replicate-selector {
  display: flex;
  gap: 8px;
  margin: 0 8px;
}

.order-dna-replicate-button {
  width: 32px;
  height: 32px;
  border-radius: 6px;
  border: 1px solid var(--color-gray-medium);
  background-color: var(--color-gray-light);
  color: var(--color-text);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: var(--font-weight-medium);
  transition: all 0.2s ease;
}

.order-dna-replicate-button:hover {
  background-color: var(--color-gray-medium-light);
}

.order-dna-replicate-button.active {
  background-color: var(--color-primary);
  color: var(--color-background);
  border-color: var(--color-primary);
}

.order-dna-sequence-left {
  display: flex;
  align-items: center;
  gap: 16px;
}

.order-dna-cost-line {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.order-dna-cost-per-bp {
  color: var(--color-text-light);
  font-size: var(--font-size-sm);
  margin-left: 8px;
}
