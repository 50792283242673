.lims-wrapper {
  padding: var(--spacing-xl);
  width: 100%;
  max-width: 90%;
  margin: 0 auto;
}

.lims-wrapper .file-explorer-content {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.lims-wrapper .pagination {
  margin-top: auto;
}

.lims-wrapper .file-explorer-inline-content {
  width: 100%;
  height: 100%;
}

/* Loading styles will be inherited from index.css */
