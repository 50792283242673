.page-layout {
  display: flex;
  flex: 1;
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.artifact-main-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  min-width: 0;
  background-color: var(--color-gray-extra-light);
}

.chat-panel {
  display: flex;
  flex-direction: column;
  background-color: var(--color-gray-extra-light);
  position: relative;
  overflow: hidden;
  border-left: 1px solid var(--color-divider);
}

.vertical-resize-handle {
  width: 12px;
  height: 100%;
  background-color: transparent;
  cursor: col-resize;
  position: relative;
  margin: 0 -6px;
  z-index: 1;
  display: block;
}

.chat-panel:not(.closed) .vertical-resize-handle {
  display: block;
}

.vertical-resize-handle:hover {
  background-color: var(--color-hover-overlay);
}

.vertical-resize-handle:active,
.vertical-resize-handle.dragging {
  background-color: var(--color-shadow-medium);
}

/* Add a visual indicator for the drag area */
.vertical-resize-handle::after {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 2px;
  height: 100%;
  background-color: var(--color-divider);
}

.vertical-resize-handle:hover::after,
.vertical-resize-handle:active::after,
.vertical-resize-handle.dragging::after {
  background-color: var(--color-handle-hover);
}

.container {
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}

.artifact-panel {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  position: relative;
  background-color: var(--color-gray-extra-light);
  overflow: hidden;
}

.artifact-panel.empty-state {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-empty-background);
}

.empty-artifact-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: var(--color-text-light);
  text-align: center;
  padding: 20px;
}

.empty-artifact-state img {
  margin-bottom: 20px;
  opacity: 0.5;
}

.empty-artifact-state p {
  font-size: 16px;
  color: var(--color-text-light);
  max-width: 300px;
  line-height: 1.5;
}

.artifact-main-container {
  flex: 1;
  display: flex;
  position: relative;
  min-width: 0;
}

.chat-section {
  width: 39%;
  position: relative;
  display: flex;
  flex-direction: column;
  transition: width 0.3s ease;
}

.chat-section.with-artifact {
  border-left: 1px solid var(--color-divider);
}

.artifact-panel {
  min-width: 0;
  max-width: none;
  flex: 1 1 auto;
  box-shadow: none;
  background-color: var(--color-background);
}

.artifact-panel.hidden {
  display: none;
}

.fullscreen-button {
  position: absolute;
  left: 1rem;
  top: 0px;
}

.artifact-buttons-container {
  position: absolute;
  top: 14px;
  left: 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.fullscreen-button svg {
  height: 20px;
}

.fullscreen-button:hover svg {
  fill: var(--color-secondary);
}

.close-button {
  position: absolute;
  right: 1rem;
  top: 6px;
  background: none;
  border: none;
  font-size: 24px;
  font-weight: 200;
  cursor: pointer;
  color: var(--color-text);
  z-index: 10;
}

.close-button.no-hover {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: var(--color-text);
  z-index: 10;
  transition: none;
}

.close-button.no-hover:hover,
.close-button.no-hover:active,
.close-button.no-hover:focus {
  background: none;
  color: var(--color-secondary);
  border: none;
  outline: none;
}

.artifact-content {
  flex: 1;
  width: 100%;
  display: flex;
  justify-content: center;
  overflow-y: hidden;
  overflow-x: hidden;
  background-color: var(--color-gray-extra-light);
  padding: 0;
}

.fullscreen-panel {
  width: 100% !important;
  max-width: 100% !important;
  height: 100vh !important;
  position: fixed !important;
  top: 0;
  right: 0;
  z-index: 1000;
  background-color: white;
}

.design-content-container {
  flex: 1;
  display: flex;
  padding: 0;
}

.forward-button svg,
.back-button svg {
  height: 16px;
  margin-top: 1px;
}

.markdown-content {
  width: 100%;
  max-width: 800px;
  padding: 20px 0;
  color: var(--color-text);
  margin: 0 auto;
}

.markdown-content h1 {
  color: var(--color-secondary);
  margin-bottom: 20px;
}

.markdown-content .input-group,
.markdown-content .checkbox-group,
.markdown-content .dropdown-group {
  margin-bottom: 15px;
}

.markdown-content label {
  display: block;
  margin-bottom: 5px;
  color: var(--color-text);
  font-weight: 500;
}

.markdown-content input[type="text"],
.markdown-content input[type="number"],
.markdown-content select {
  width: 100%;
  padding: 8px 15px;
  font-size: 14px;
  border: 1px solid var(--color-input-border);
  border-radius: 4px;
  background-color: var(--color-input-background);
  color: var(--color-text);
  transition: border-color 0.3s ease;
}

.markdown-content input[type="text"]:focus,
.markdown-content input[type="number"]:focus,
.markdown-content select:focus {
  outline: none;
  border-color: var(--color-secondary);
  box-shadow: 0 0 0 2px var(--color-secondary-shadow);
}

.markdown-content input[type="checkbox"] {
  margin-right: 10px;
}

.markdown-content table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.markdown-content th,
.markdown-content td {
  padding: 12px;
  text-align: left;
  border: 1px solid var(--color-input-border);
}

.markdown-content th {
  background-color: var(--color-gray-medium);
  font-weight: bold;
  color: var(--color-text-dark);
}

.markdown-content tr:nth-child(even) {
  background-color: var(--color-gray-light);
}

.markdown-content tr:hover {
  background-color: var(--sidebar-dropdown-hover);
}

.markdown-content .editable-table input[type="text"] {
  width: 100%;
  padding: 4px 8px;
  font-size: 14px;
  border: 1px solid var(--color-input-border);
  border-radius: 4px;
  background-color: var(--color-input-background);
  color: var(--color-text);
}

.markdown-content .dropdown-group input[type="text"] {
  padding-right: 30px;
}

.markdown-content .dropdown-group {
  position: relative;
}

.markdown-content .dropdown-group::after {
  content: "▼";
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  color: var(--color-background);
}

.markdown-content .result {
  margin-top: 20px;
  padding: 10px;
  background-color: #e8e8e8;
  border-radius: 4px;
  font-weight: bold;
  color: var(--color-secondary);
}

/* 
.submit-button {
  background-color: #f28a22;
  color:var(--color-background);
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
  margin-top: 20px;
}

.submit-button:hover {
  background-color: #d93dea;
} */

.toggle-exclamation {
  padding: 5px 10px;
  background-color: var(--color-secondary);
  color: var(--color-background);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
  transition: background-color 0.3s ease;
}

.toggle-exclamation:hover {
  background-color: var(--color-primary);
}

.markdown-content textarea {
  width: 100%;
  height: 100%;
  min-height: 400px;
  padding: 15px;
  font-size: 14px;
  line-height: 1.6;
  border: 1px solid var(--color-gray-medium);
  border-radius: 4px;
  background-color: var(--color-gray-extra-light);
  color: var(--color-text);
  resize: vertical;
}

.markdown-content textarea:focus {
  outline: none;
  border-color: var(--color-secondary);
  box-shadow: 0 0 0 2px var(--color-secondary-shadow);
}

.bmi-calculator {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  background-color: var(--color-gray-extra-light);
  border-radius: 8px;
  box-shadow: 0 2px 4px var(--color-shadow-soft);
}

.bmi-calculator h2 {
  text-align: center;
  color: var(--color-secondary);
  margin-bottom: 20px;
}

.input-group {
  margin-bottom: 15px;
}

.input-group label {
  display: block;
  margin-bottom: 5px;
  color: var(--color-text);
}

.input-group input {
  width: 100%;
  padding: 8px;
  border: 1px solid var(--color-gray-medium);
  border-radius: 4px;
  font-size: 14px;
}

.calculate-button {
  width: 100%;
  padding: 10px;
  background-color: var(--color-secondary);
  color: var(--color-background);
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.calculate-button:hover {
  background-color: var(--color-primary);
}

.result {
  margin-top: 20px;
  text-align: center;
}

.result p {
  margin: 5px 0;
  font-size: 16px;
  color: var(--color-text);
}

.result p:first-child {
  font-weight: bold;
  color: var(--color-secondary);
}

.protocol-dropdown {
  width: 100%;
  background-color: var(--color-input-background);
  border: 1px solid var(--color-input-border);
  border-radius: 4px;
  color: var(--color-text);
  font-size: 14px;
  padding: 5px;
  margin-top: 5px;
}

.protocol-dropdown:focus {
  outline: none;
  border-color: var(--color-primary);
}

/* .protocol-selection {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 10px;
  width: 100%;
}

.protocol-selection label {
  color:var(--color-background);
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 5px;
} */
/* 
.markdown-controls {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 10px;
  width: 100%;
} */

/* .submit-button {
  align-self: flex-start;
  margin-bottom: 10px;
} */

.artifact-loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--color-loading-overlay);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.artifact-loading-overlay.visible {
  opacity: 1;
}

.artifact-loading-spinner {
  width: 40px;
  height: 40px;
  border: 4px solid var(--color-gray-medium);
  border-top: 4px solid var(--color-primary);
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.empty-chat-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 20px;
  text-align: center;
}

.input-design-container {
  margin-top: auto;
  padding: 20px;
  border-top: 1px solid var(--color-divider);
}

/* Add specific padding for non-sequence content */
.empty-artifact-state,
.markdown-content {
  padding: 20px;
}

/* Ensure sequence viewer takes full width */
.sequence-viewer {
  width: 100%;
  padding: 0;
  margin: 0;
}

.artifact-empty-state {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background-color: var(--color-gray-extra-light);
  padding: 20px;
}

.artifact-empty-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  max-width: 800px;
}

.artifact-empty-content img {
  margin-bottom: 1.5rem;
  opacity: 0.9;
}

.artifact-empty-content h1 {
  font-size: var(--font-size-xl);
  margin-bottom: 0.5rem;
  color: var(--color-text);
}

.artifact-empty-content h3 {
  font-size: var(--font-size-lg);
  color: var(--color-text-light);
  margin-bottom: 2rem;
}

.artifact-buttons-row {
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
  justify-content: center;
  flex-wrap: nowrap;
}

.artifact-buttons-row .large-button {
  white-space: nowrap;
  min-width: 160px;
  padding: 0.75rem 1.25rem;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.artifact-buttons-row .button-icon {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
}

@media screen and (max-width: 768px) {
  .artifact-buttons-row {
    flex-direction: column;
    gap: 1rem;
  }

  .artifact-buttons-row .large-button {
    width: 100%;
  }
}

/* Add specific protocol content container */
.protocol-content {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px;
}

/* Update protocol markdown controls */
.protocol-markdown-controls {
  width: 100%;
  max-width: 800px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding-top: 20px;
}
