.csv-viewer {
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: relative;
}

.csv-table-container {
  flex: 1;
  overflow: auto;
  padding: 20px;
  padding-top: 50px;
}

.csv-table {
  width: 100%;
  border-collapse: collapse;
  background: white;
}

.csv-table th,
.csv-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.csv-table th {
  background-color: #f5f5f5;
  position: sticky;
  top: 0;
  z-index: 1;
}

.csv-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.csv-table tr:hover {
  background-color: #f0f0f0;
}

.csv-controls {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 1;
  display: flex;
  align-items: center;
}

.csv-copy-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  transition: background-color 0.2s;
}

.csv-copy-button:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.csv-copy-icon {
  width: 20px;
  height: 20px;
}

.csv-copy-notification {
  position: absolute;
  left: 40px;
  background-color: #333;
  color: white;
  padding: 6px 12px;
  border-radius: 4px;
  font-size: 14px;
  white-space: nowrap;
}
