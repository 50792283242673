.popup-ephemeral-container {
  box-sizing: border-box;
  width: 400px;
  border: 1px solid #ccc;
  border-radius: 6px;
  background-color: var(--color-gray-extra-light);
  display: flex;
  flex-direction: column;
  max-height: 500px;
  cursor: move;
  position: relative;
  z-index: 2000;
  opacity: 1;
  overflow: visible;
}

.popup-ephemeral-container.with-messages {
  background-color: var(--color-gray-extra-light);
  opacity: 1;
}

.popup-drag-handle {
  border-bottom: 1px solid #ccc;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  box-sizing: border-box;
  padding-right: 8px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  position: relative;
  z-index: 1;
  overflow: visible;
  background-color: var(--color-gray-extra-light);
}

.popup-ephemeral-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 0;
  overflow: visible;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  position: relative;
}

.popup-ephemeral-close {
  position: relative;
  background: none;
  border: none;
  width: 16px;
  height: 16px;
  font-size: 14px;
  cursor: pointer;
  color: #666;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  box-shadow: none;
}

.popup-ephemeral-close:hover {
  color: #333;
}

.popup-ephemeral-messages {
  flex: 1;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  background: var(--color-gray-extra-light);
  min-height: 0;
  max-height: calc(100vh - 150px);
  box-sizing: border-box;
  width: 100%;
  opacity: 1;
}

.popup-ephemeral-input {
  background: white;
  border-top: 1px solid #ccc;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  width: 100%;
  box-sizing: border-box;
  cursor: default;
  opacity: 1;
}

.popup-ephemeral-container.with-messages .popup-ephemeral-input {
  border-radius: 0 0 8px 8px;
  box-shadow: none;
  border-top: 1px solid #eee;
}

.popup-chat-message {
  padding: 8px;
  border-radius: 4px;
  max-width: calc(80% - 10px);
}

.popup-chat-message.user {
  background: #e3f2fd;
  align-self: flex-end;
}

.popup-chat-message.assistant {
  background: #f5f5f5;
  align-self: flex-start;
}

.popup-ephemeral-input .chatinput-container {
  border: none;
  box-shadow: none;
  margin: 0;
  padding: 10px;
}

.popup-ephemeral-input .chatinput-form-container {
  padding: 0;
}

.typing-indicator {
  display: inline-block;
  animation: typing 1.4s infinite;
}

@keyframes typing {
  0%,
  20% {
    content: ".";
  }

  40% {
    content: "..";
  }

  60% {
    content: "...";
  }

  80%,
  100% {
    content: "";
  }
}

.popup-ephemeral-input,
.popup-ephemeral-messages,
.popup-ephemeral-close {
  cursor: default;
}

.popup-ephemeral-close {
  cursor: pointer;
}

.popup-ephemeral-content .chat-messages-list {
  overflow-y: auto;
  max-height: 30vh;
}
