.recent-chats-container {
  padding: 20px;
  margin-top: 10px;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  container-type: inline-size;
}

.recent-chats-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
  width: 100%;
}

.recent-chats-title {
  font-size: 0.85em;
  color: var(--color-text-light);
  font-weight: normal;
  margin: 0;
}

.see-all-button {
  background: none;
  border: none;
  color: var(--color-text);
  font-size: 0.85em;
  cursor: pointer;
  padding: 4px 8px;
  border-radius: 4px;
}

.see-all-button:hover {
  background-color: var(--color-gray-light);
}

.recent-chats-list {
  display: grid;
  grid-template-columns: 1fr;
  gap: 12px;
  width: 100%;
  box-sizing: border-box;
}

@container (min-width: 500px) {
  .recent-chats-list {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@container (max-width: 499px) {
  .recent-chats-list.show-all {
    grid-template-columns: minmax(0, 1fr);
  }
}

@container (min-width: 500px) {
  .recent-chats-list.show-all {
    grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  }
}

.recent-chat-item {
  background-color: var(--color-spec-background);
  padding: 12px 16px;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.2s;
  font-size: 0.9em;
  color: var(--color-text);
  height: 40px;
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  box-sizing: border-box;
  min-width: 0;
}

.recent-chat-item:hover {
  background-color: var(--color-tab-hover);
}

.chat-icon {
  color: var(--color-text-light);
  font-size: 14px;
  flex-shrink: 0;
}

.chat-info {
  display: flex;
  align-items: center;
  gap: 8px;
  min-width: 0;
  flex: 1;
  width: 100%;
}

.chat-name {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0;
}

.chat-time {
  color: var(--color-text-light);
  font-size: 0.8em;
  flex-shrink: 0;
  margin-left: auto;
  padding-left: 8px;
}
