.sequence-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: var(--color-gray-extra-light);
  padding: 0;
}

.viewer-controls {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: space-between;
  gap: 0;
  background-color: var(--color-topbar-background);
  padding: 0;
  margin-bottom: 15px;
  border-bottom: 1px solid var(--color-border);
  height: 52px;
  box-sizing: border-box;
  width: 100%;
}

.protocol-button-group {
  display: flex;
  gap: 10px;
  align-items: flex-end;
  padding-bottom: 6px;
  order: -1;
}

.sequence-protocol-download-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
  display: flex;
  align-items: center;
  margin-bottom: 0px;
  position: relative;
  /* top: -20px; */
}

.protocol-download-icon {
  width: 24px;
  height: 24px;
}

.dropdowns-container {
  display: flex;
  gap: 0;
  flex-wrap: nowrap;
  align-items: stretch;
  flex: 1;
}

.viewer-type-container,
.sequence-select-container,
.selection-meta,
.filter-controls,
.zoom-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  padding: 0 20px;
  border-right: 1px solid var(--color-border);
  height: 51px;
  box-sizing: border-box;
}

.viewer-type-label,
.sequence-select-label,
.zoom-label,
.meta-datum .field {
  font-size: 12px;
  color: var(--color-text);
  font-weight: normal;
  height: 32px;
  line-height: 32px;
  margin: 0;
  white-space: nowrap;
  display: flex;
  align-items: center;
}

.viewer-type-dropdown,
.sequence-select-dropdown {
  flex: 1;
  height: 32px;
  line-height: 32px;
  padding: 0 10px;
  font-size: 13px;
  background-color: transparent;
  color: var(--color-text);
  border: 1px solid var(--color-secondary);
  border-radius: 4px;
  cursor: pointer;
  outline: none;
  transition: filter 0.2s;
  margin-top: 0;
  min-width: 120px;
}

.viewer-type-dropdown:hover,
.sequence-select-dropdown:hover {
  filter: brightness(0.9);
}

.viewer-type-dropdown:focus,
.sequence-select-dropdown:focus {
  outline: 1px solid var(--color-secondary);
}

/* 
.error-message {
  background-color: var(--color-error);
  color: var(--color-text);
  padding: 10px;
  margin-top: 10px;
  border-radius: 4px;
  font-size: 14px;
}

.error-message-box {
  background-color: var(--color-error);
  border: 1px solid var(--color-primary);
  border-radius: 4px;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
} */

/* SeqViz general styles */
.la-vz-viewer-circular,
.la-vz-linear-scroller {
  background-color: var(--color-gray-extra-light) !important;
}

/* Reduce padding in circular view */
.la-vz-viewer-circular {
  padding: 0 !important;
  background-color: var(--color-gray-extra-light) !important;
}

.la-vz-viewer-circular > svg {
  margin: 0 !important;
}

/* Text elements */
.la-vz-viewer-event-router text {
  fill: var(--color-text) !important;
  color: var(--color-text) !important;
}

/* Sequence text */
.la-vz-viewer-seq,
.la-vz-viewer-comp-seq {
  fill: var(--color-text) !important;
}

/* Lines and ticks */
.la-vz-viewer-circular .la-vz-index-line,
.la-vz-viewer-circular .la-vz-index-tick,
.la-vz-viewer-circular .la-vz-label-line,
.la-vz-linear-scroller .la-vz-index-line,
.la-vz-linear-scroller .la-vz-index-tick {
  stroke: var(--color-text) !important;
}

/* Selection and edges */
.la-vz-viewer-circular .la-vz-selection,
.la-vz-viewer-circular .la-vz-selection-edge,
.la-vz-linear-scroller .la-vz-selection,
.la-vz-linear-scroller .la-vz-selection-edge {
  fill: var(--color-selection) !important;
  stroke: var(--color-selection-edge) !important;
}

/* Annotations */
.la-vz-viewer-circular .la-vz-annotation,
.la-vz-linear-scroller .la-vz-annotation {
  fill-opacity: 0.7 !important;
}

/* Annotation labels */
.la-vz-annotation-label {
  fill: var(--color-text) !important;
  color: var(--color-text) !important;
}

/* Adjust the circular viewer text */
.la-vz-viewer-circular text {
  fill: var(--color-text) !important;
}

/* Style the plasmid name and base pair count */
.la-vz-viewer-circular text[font-size="20"],
.la-vz-viewer-circular text[y="903"] {
  fill: var(--color-text) !important;
}

/* Ensure the selection text is visible */
.la-vz-selection-edge {
  stroke: var(--color-selection-edge) !important;
}

/* Adjust other elements as needed */
.la-vz-index-line,
.la-vz-index-tick {
  stroke: var(--color-text-light) !important;
}

/* Change the selection highlight color */
rect[data-testid="la-vz-selection-block"] {
  fill: var(--color-gray-medium) !important;
  shape-rendering: auto;
}

.sequence-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* Add some padding */
}

.viewer-type-label {
  font-size: 12px;
  color: var(--color-text);
}

.viewer-type-dropdown,
.sequence-select-dropdown {
  width: 100%;
  /* Make the dropdowns fill their container */
  padding: 6px 10px;
  font-size: 12px;
  background-color: transparent;
  color: var(--color-text);
  border: 1px solid var(--color-secondary);
  border-radius: 4px;
  cursor: pointer;
  outline: none;
}

/* Ensure the SeqViz component takes full width */
.sequence-content > div {
  width: 100%;
}

.la-vz-linear-scroller {
  overflow: auto !important;
}

.sequence-controls-right {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-left: 0;
  height: 51px;
  box-sizing: border-box;
}

.sequence-transform-container {
  display: flex;
  flex-direction: column;
  width: 175px;
}

.sequence-transform-label {
  font-size: 12px;
  color: var(--color-text);
  margin-bottom: 5px;
}

.sequence-transform-dropdown {
  width: 100%;
  padding: 6px 10px;
  font-size: 12px;
  background-color: transparent;
  color: var(--color-text);
  border: 1px solid var(--color-secondary);
  border-radius: 4px;
  cursor: pointer;
  outline: none;
}

.sequence-button-group {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-left: 10px;
}

.sequence-transform-button {
  padding: 8px 16px;
  font-size: 14px;
  color: var(--color-background);
  background-color: var(--color-secondary);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: filter 0.2s;
}

.sequence-transform-button:hover {
  filter: brightness(0.9);
}

.sequence-transform-button:active {
  filter: brightness(0.8);
}

/* Popup styles */
.sequence-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--color-overlay-background);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.sequence-popup-dialog {
  background-color: var(--color-background);
  padding: 32px;
  border-radius: 8px;
  max-width: 600px;
  width: 90%;
  box-shadow: 0 4px 20px var(--color-shadow-medium);
  position: relative;
}

.sequence-popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.sequence-popup-header h2 {
  margin: 0;
  font-size: 20px;
  color: var(--color-secondary);
}

.sequence-popup-content {
  margin: 20px 0;
}

.sequence-popup-content p {
  margin: 10px 0;
  font-size: 14px;
  line-height: 1.4;
  color: var(--color-text);
}

.sequence-popup-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  margin-top: 32px;
}

.sequence-popup-close {
  position: absolute;
  top: 16px;
  right: 16px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: var(--color-text);
  padding: 8px;
}

.sequence-confirm-button {
  background-color: var(--color-secondary);
  color: var(--color-gray-extra-light);
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
}

.sequence-cancel-button {
  background-color: var(--color-error);
  color: var(--color-gray-extra-light);
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
}

/* Add these styles to your existing CSS */
.enzyme-controls {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-left: 20px;
  border-left: 1px solid var(--color-secondary);
  padding-left: 20px;
}

.enzyme-controls-label {
  font-size: 12px;
  color: var(--color-text);
}

.enzyme-checkbox-group {
  display: flex;
  flex-direction: row;
  gap: 16px;
  flex-wrap: wrap;
}

.enzyme-checkbox-group label {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 12px;
  color: var(--color-text);
  cursor: pointer;
  user-select: none;
  white-space: nowrap;
}

.enzyme-checkbox-group input[type="checkbox"] {
  margin: 0;
  cursor: pointer;
}

.zoom-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  padding: 10px 20px;
  min-width: 200px;
  border-right: 1px solid var(--color-border);
  height: 100%;
}

.zoom-slider {
  flex: 1;
  width: auto;
  height: 4px;
  -webkit-appearance: none;
  appearance: none;
  background: var(--color-secondary);
  border-radius: 2px;
  outline: none;
  padding: 0;
  margin: 0;
  min-width: 120px;
}

.zoom-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: var(--color-text);
  cursor: pointer;
  transition: filter 0.2s;
}

.zoom-slider::-moz-range-thumb {
  width: 16px;
  height: 16px;
  border: 0;
  border-radius: 50%;
  background: var(--color-text);
  cursor: pointer;
  transition: filter 0.2s;
}

.selection-meta {
  display: flex;
  gap: 20px;
  align-items: center;
  padding: 10px 20px;
  min-width: 300px;
  border-right: 1px solid var(--color-border);
  height: 100%;
}

.meta-datum {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  height: 32px;
  padding: 0;
  margin: 0;
}

.meta-datum .field {
  margin: 0;
  text-align: right;
  min-width: 45px;
}

.meta-datum .value {
  font-size: 13px;
  color: var(--color-text);
  margin: 0;
  font-weight: bold;
  white-space: nowrap;
  height: 32px;
  line-height: 32px;
  min-width: 60px;
  display: flex;
  align-items: center;
}

.filter-controls {
  position: relative;
  padding: 0 20px;
  display: flex;
  align-items: center;
  border-right: 1px solid var(--color-border);
  height: 51px;
  box-sizing: border-box;
}

.filter-button {
  background-color: var(--color-secondary);
  color: var(--color-background);
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  cursor: pointer;
  transition: filter 0.2s;
  font-size: 14px;
}

.filter-button:hover {
  filter: brightness(0.9);
}

.filter-button:active {
  filter: brightness(0.8);
}

.filter-popup-container {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 10000;
}

.filter-popup {
  position: fixed;
  background-color: var(--color-background);
  border: 1px solid var(--color-secondary);
  border-radius: 4px;
  min-width: 300px;
  box-shadow: 0 2px 8px var(--color-shadow-soft);
  user-select: none;
  z-index: 10000;
}

.filter-popup.dragging {
  opacity: 0.9;
}

.filter-popup-header {
  padding: 12px 16px;
  background-color: var(--color-gray-light);
  border-bottom: 1px solid var(--color-border);
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: move;
  border-radius: 4px 4px 0 0;
}

.filter-popup-header h3 {
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  color: var(--color-text);
}

.filter-popup-content {
  padding: 16px;
}

.filter-popup-close {
  position: static;
  background: none;
  border: none;
  font-size: 18px;
  line-height: 1;
  color: var(--color-text-light);
  cursor: pointer;
  padding: 4px 8px;
  border-radius: 4px;
  transition: all 0.2s ease;
  z-index: 2;
}

.filter-popup-close:hover {
  color: var(--color-text);
  background-color: var(--color-gray-light);
}

.filter-section {
  margin-bottom: 16px;
}

.filter-section:last-child {
  margin-bottom: 0;
}

.filter-section h3 {
  font-size: 12px;
  color: var(--color-text);
  margin: 0 0 8px 0;
  font-weight: 500;
}

.annotation-checkbox-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.annotation-checkbox-group label {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 12px;
  color: var(--color-text);
  cursor: pointer;
  user-select: none;
}

.annotation-checkbox-group input[type="checkbox"] {
  cursor: pointer;
  margin: 0;
}

.annotation-search {
  width: 100%;
  padding: 6px 10px;
  font-size: 12px;
  color: var(--color-text);
  background-color: transparent;
  border: 1px solid var(--color-secondary);
  border-radius: 4px;
  outline: none;
}

.annotation-search::placeholder {
  color: var(--color-text-light);
}

.filter-nav-group {
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: center;
  width: auto;
}

.nav-buttons {
  display: flex;
  gap: 4px;
  position: relative;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  padding: 0 20px;
  height: 51px;
  box-sizing: border-box;
}

.nav-button {
  color: var(--color-secondary);
  background: none;
  border: none;
  padding: 6px 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 32px;
  transition: filter 0.2s;
}

.nav-button:hover:not(:disabled) {
  filter: brightness(0.9);
}

.nav-button:disabled {
  color: var(--color-disabled);
  cursor: not-allowed;
}

.nav-tooltip {
  position: absolute;
  top: calc(100% + 8px);
  right: 0;
  background-color: var(--color-gray-ultra-light);
  border: 1px solid var(--color-secondary);
  border-radius: 4px;
  padding: 6px 10px;
  font-size: 12px;
  color: var(--color-text);
  white-space: nowrap;
  z-index: 1000;
  box-shadow: 0 2px 4px var(--color-shadow-soft);
  pointer-events: none;
}

@media (max-width: 768px) {
  .viewer-controls {
    height: auto;
  }

  .viewer-type-container,
  .sequence-select-container,
  .selection-meta,
  .filter-controls,
  .zoom-container,
  .sequence-controls-right,
  .nav-buttons {
    height: auto;
    padding: 10px 15px;
  }
}

@media (max-width: 480px) {
  .viewer-type-container,
  .sequence-select-container,
  .zoom-container {
    width: 100%;
  }

  .selection-meta {
    width: 100%;
  }
}

/* Add these styles to handle disabled options */
.viewer-type-dropdown option:disabled {
  color: var(--color-text-light);
  font-style: italic;
  background-color: var(--color-gray-light);
}
