.ft-tab {
  display: flex;
  align-items: center;
  padding: 0 8px;
  height: 32px;
  background-color: var(--color-gray-light);
  border-right: 1px solid var(--color-border);

  cursor: pointer;
  user-select: none;
  width: fit-content;
  min-width: 40px;
  max-width: 200px;
  margin-right: 0;
  position: relative;
  margin-top: -1px;
}

.ft-tab .ft-tab-name {
  color: var(--color-text-light);
}

.ft-tab.active {
  background-color: var(--color-gray-medium-light);
  border-right: 1px solid var(--color-gray-medium-dark);
  border-left: 1px solid var(--color-gray-medium-dark);
  margin-left: -1px;
}

.ft-tab.active::after {
  content: "";
  position: absolute;
  bottom: -2px;
  left: 0;
  right: 0;
  height: 2px;
  background-color: var(--color-primary);
  z-index: 1;
}

.ft-tab.active .ft-tab-name {
  color: var(--color-text);
  font-weight: var(--font-weight-semibold);
}

.ft-tab-icon {
  font-size: var(--font-size-sm);
  margin-right: 6px;
  flex-shrink: 0;
}

.ft-tab-name {
  flex: 1;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: var(--font-size-sm);
  padding-right: 8px;
}

.ft-tab-close {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  padding: 2px;
  /* border-radius: 3px; */
  background: none;
  border: none;
  color: var(--color-text-light);
  cursor: pointer;
}

.ft-tab-close:hover {
  background-color: var(--color-gray-medium);
  color: var(--color-text);
}

.ft-tab.active .ft-tab-close:hover {
  background-color: var(--color-gray-medium-light);
}
