.manage-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--color-overlay-background);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1100;
}

.manage-modal-content {
  background-color: var(--color-input-background);
  padding: 32px;
  border-radius: 12px;
  width: 500px;
  max-width: 90%;
  max-height: 90vh;
  overflow-y: auto;
  box-shadow: 0 8px 30px var(--color-shadow-medium);
}

.manage-modal-title {
  font-size: var(--font-size-xl);
  color: var(--color-text);
  font-weight: var(--font-weight-semibold);
  margin: 0;
}

.manage-modal-divider {
  border: none;
  border-top: 1px solid var(--color-border);
  margin: 16px 0;
}

.manage-section {
  background-color: var(--color-background);
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 16px;
  box-shadow: 0 2px 4px var(--color-shadow-soft);
}

.manage-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.manage-buttons {
  display: flex;
  gap: 8px;
}

.action-button {
  background-color: var(--color-button-primary);
  color: var(--color-text);
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.action-button:hover {
  background-color: var(--color-secondary);
  transform: translateY(-1px);
}

.action-button.active {
  background-color: var(--color-secondary);
  transform: translateY(-1px);
}

.delete-button {
  background-color: var(--color-error);
  color: var(--color-text);
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.delete-button:hover {
  background-color: var(--color-secondary);
  transform: translateY(-1px);
}

.manage-form {
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  background-color: var(--color-input-background);
  padding: 16px;
  border-radius: 4px;
}

.manage-form input {
  padding: 10px;
  border: 1px solid var(--color-border);
  border-radius: 4px;
  font-size: var(--font-size-md);
}

.form-buttons {
  display: flex;
  gap: 8px;
  justify-content: flex-end;
}

.form-buttons button {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.form-buttons button[type="submit"] {
  background-color: var(--color-button-primary);
  color: var(--color-text);
}

.form-buttons button[type="button"] {
  background-color: var(--color-gray-medium);
  color: var(--color-text);
}

.form-buttons button:hover {
  background-color: var(--color-secondary);
  transform: translateY(-1px);
}

.member-list {
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-height: 200px;
  overflow-y: auto;
  padding-right: 8px;
}

.member-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  background-color: var(--color-input-background);
  border-radius: 4px;
  position: relative;
}

.remove-icon {
  opacity: 0;
  cursor: pointer;
  transition: opacity 0.2s ease;
  padding: 4px;
}

.member-item:hover .remove-icon {
  opacity: 1;
}

.remove-icon:hover {
  background-color: var(--color-hover-overlay);
  border-radius: 4px;
}

.leave-button {
  background-color: var(--color-error);
  color: var(--color-text);
  border: none;
  padding: 6px 12px;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s ease;
  opacity: 0;
}

.member-item:hover .leave-button {
  opacity: 1;
}

.leave-button:hover {
  background-color: var(--color-secondary);
  transform: translateY(-1px);
}

h3 {
  color: var(--color-text);
  margin: 0;
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-medium);
}

.member-list::-webkit-scrollbar {
  width: 8px;
}

.member-list::-webkit-scrollbar-track {
  background: var(--color-gray-light);
  border-radius: 4px;
}

.member-list::-webkit-scrollbar-thumb {
  background: var(--color-gray-medium);
  border-radius: 4px;
}

.member-list::-webkit-scrollbar-thumb:hover {
  background: var(--color-gray);
}

.group-actions {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  margin-bottom: 16px;
}

.group-actions button {
  flex: 1;
  min-width: 120px;
}

.credits-input-container {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.credits-available {
  color: var(--color-text-light);
  font-size: var(--font-size-sm);
  margin-top: 4px;
}

.manage-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.manage-back-button {
  background-color: transparent;
  color: var(--color-text);
  border: none;
  padding: 12px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: var(--font-weight-medium);
  transition: color 0.2s ease;
  height: fit-content;
  display: flex;
  align-items: center;
  gap: 4px;
}

.manage-back-button:hover {
  color: var(--color-text-light);
}

.manage-group-error-message {
  background-color: var(--color-error-light);
  color: var(--color-error);
  padding: 12px;
  border-radius: 4px;
  border: 1px solid var(--color-error-border);
  margin: 8px 0;
  font-size: var(--font-size-sm);
}
