.fb-file-bar-container {
  display: flex;
  align-items: center;
  background-color: var(--color-gray-extra-light);
  border-bottom: 1px solid var(--color-border);
  position: relative;
  height: 36px;
  overflow: hidden;
  margin-top: -1px;
}

.fb-file-bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow-x: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  scroll-behavior: smooth;
  flex: 1;
  padding: 0 8px;
  height: 100%;
  padding-left: 24px;
  padding-right: 24px;
  margin: 0 -24px;
}

.fb-file-bar::-webkit-scrollbar {
  display: none;
}

.fb-scroll-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 100%;
  background-color: var(--color-gray-extra-light);
  border: none;
  cursor: pointer;
  position: absolute;
  z-index: 1;
  padding: 0;
  transition: opacity 0.2s ease;
  opacity: 0;
  pointer-events: none;
}

.fb-scroll-button.visible {
  opacity: 1;
  pointer-events: auto;
}

.fb-scroll-button:hover {
  background-color: var(--color-gray-light);
}

.fb-scroll-left {
  left: 0;
  border-right: 1px solid var(--color-border);
  background: var(--color-gray-extra-light);
}

.fb-scroll-right {
  right: 0;
  border-left: 1px solid var(--color-border);
  background: var(--color-gray-extra-light);
}

.fb-file-bar::-webkit-scrollbar {
  height: 4px;
}

.fb-file-bar::-webkit-scrollbar-track {
  background: var(--color-gray-extra-light);
}

.fb-file-bar::-webkit-scrollbar-thumb {
  background: var(--color-gray-medium);
  border-radius: 2px;
}

.fb-tab {
  display: flex;
  align-items: center;
  padding: 0 8px;
  height: 32px;
  background-color: var(--color-gray-light);
  border-right: 1px solid var(--color-border);
  font-size: var(--font-size-sm);
  cursor: pointer;
  user-select: none;
  width: fit-content;
  min-width: 40px;
  max-width: 200px;
  position: relative;
  /* border-radius: 6px 6px 0 0; */
  margin-right: 1px;
}

.fb-tab .fb-tab-name {
  color: var(--color-text-light);
}

.fb-tab:first-child {
  border-left: 1px solid var(--color-border);
}

.fb-tab.active {
  background-color: var(--color-gray-medium-light);
}

.fb-tab.active::after {
  content: "";
  position: absolute;
  bottom: -2px;
  left: 0;
  right: 0;
  height: 2px;
  background-color: var(--color-primary);
  z-index: 1;
}

.fb-tab.active .fb-tab-name {
  color: var(--color-text);
  font-weight: var(--font-weight-semibold);
}

.fb-tab:hover {
  background-color: var(--color-gray-medium);
}

.fb-tab-name {
  flex: 1;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: 8px;
}

.fb-tab-close {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  padding: 2px;
  /* border-radius: 3px; */
  background: none;
  border: none;
  color: var(--color-text-light);
  cursor: pointer;
}

.fb-tab-close:hover {
  background-color: var(--color-gray-medium);
  color: var(--color-text);
}

.fb-tab.active .fb-tab-close {
  color: var(--color-text);
}

.ft-tab {
  display: flex;
  align-items: center;
  padding: 0 8px;
  height: 32px;
  background-color: var(--color-gray-light);
  border-right: 1px solid var(--color-border);
  font-size: var(--font-size-sm);
  cursor: pointer;
  user-select: none;
  min-width: fit-content;
  white-space: nowrap;
  position: relative;
  margin-right: 0;
}

.ft-tab-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
  /* Adjust this value as needed */
}
