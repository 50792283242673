.group-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--color-overlay-background);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.group-modal-content {
  background-color: var(--color-input-background);
  padding: 24px;
  border-radius: 12px;
  width: 90%;
  max-width: 500px;
  box-shadow: 0 4px 6px var(--color-shadow-soft);
  box-sizing: border-box;
}

.group-modal-title {
  color: var(--color-text);
  margin: 0 0 16px 0;
  font-size: var(--font-size-xl);
  font-weight: var(--font-weight-semibold);
}

.group-modal-divider {
  border: none;
  border-top: 1px solid var(--color-border);
  margin: 16px 0;
}

.group-list {
  margin: 20px 0;
}

.group-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  background-color: var(--color-background);
  border-radius: 8px;
  margin-bottom: 12px;
}

.group-info h3 {
  margin: 0;
  font-size: var(--font-size-lg);
  color: var(--color-text);
}

.group-info p {
  margin: 4px 0 0;
  font-size: var(--font-size-sm);
  color: var(--color-text-light);
  display: flex;
  align-items: center;
}

.group-info p::before {
  content: "•";
  margin: 0 6px;
}

.group-info p:first-of-type {
  margin-left: 0;
}

.group-info p:first-of-type::before {
  display: none;
}

.group-manage-button {
  background-color: var(--color-secondary);
  color: var(--color-text);
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.group-manage-button:hover {
  background-color: var(--color-button-primary);
}

.group-add-button {
  width: 100%;
  background-color: var(--color-button-primary);
  color: var(--color-text);
  border: none;
  padding: 12px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: var(--font-weight-medium);
  transition: background-color 0.2s ease;
}

.group-add-button:hover {
  background-color: var(--color-secondary);
}

.group-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.group-management-back-button {
  background-color: transparent;
  color: var(--color-text);
  border: none;
  padding: 12px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: var(--font-weight-medium);
  transition: color 0.2s ease;
  height: fit-content;
  display: flex;
  align-items: center;
  gap: 4px;
  margin-top: -12px;
}

.group-management-back-button:hover {
  color: var(--color-text-light);
}

.group-description {
  background-color: var(--color-background-transparent);
  padding: 16px;
  border-radius: 8px;
  margin: 0 0 16px 0;
}

.group-description p {
  color: var(--color-text);
  font-size: var(--font-size-sm);
  line-height: 1.5;
  margin: 0;
}
