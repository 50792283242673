.chat-panel-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  /* height: calc(100vh - 32px);
    box-sizing: border-box;
    background-color: var(--color-gray-extra-light);
    overflow-y: hidden;
    position: relative; */
  max-height: 100%;
}

/* .chat-panel-messages {
    flex: 1;
    width: 100%;
    overflow-y: auto;
    box-sizing: border-box;
    position: relative;
    min-height: 0;
} */

.chat-panel-input {
  /* flex: 0 0 auto;
    width: 100%;
    box-sizing: border-box;
    background-color: var(--color-gray-extra-light);
    border-top: 1px solid #eee;
    padding: 8px;
    position: relative;
    z-index: 1; */
  flex: 0;
}
