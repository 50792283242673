/* Import monospace fonts if needed */
@import url("https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@400;500;600&display=swap");

.py-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
}

.py-editor-container {
  width: 100%;
  box-sizing: border-box;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  min-height: 0;
  position: relative;
  background-color: var(--color-gray-light);
}

.py-content-section {
  display: flex;
  flex: 1;
  overflow: hidden;
  position: relative;
}

/* Vertical Layout (default) */
.py-editor-container.vertical .py-content-section {
  flex-direction: column;
}

.py-editor-container.vertical .py-top-section {
  min-height: 20%; /* Allow output to take up to 80% */
  max-height: 95%;
  height: 90%; /* default height */
  flex: none;
}

.py-editor-container.vertical .py-output-section {
  min-height: 24px; /* Just enough for the header */
  max-height: 80%; /* Maximum height for output */
  flex: 1;
  border-top: none;
}

/* Horizontal Layout */
.py-editor-container.horizontal .py-content-section {
  flex-direction: row;
  height: 100%;
}

.py-editor-container.horizontal .py-top-section {
  width: 60%;
  min-width: 40%;
  flex: 1;
  min-height: 100%;
}

.py-editor-container.horizontal .py-output-section {
  width: 40%;
  min-width: 30%;
  border-left: 2px solid var(--color-border);
  min-height: 100%;
}

.py-top-section {
  display: flex;
  flex-direction: column;
  min-height: 50%;
  flex: 1;
}

.py-toolbar {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  padding: 0.25rem;
  background-color: var(--color-gray-medium-light);
  position: relative;
  width: 100%;
  box-sizing: border-box;
  height: 28px;
}

.py-button {
  padding: 0.25rem;
  border: 1px solid var(--color-border);
  background-color: var(--color-gray-medium);
  cursor: pointer;
  font-size: var(--font-size-sm);
  border-radius: 4px;
  color: var(--color-text);
  transition: all 0.2s ease;
  font-family: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  position: relative;
}

.py-button:hover::after {
  content: attr(data-tooltip);
  position: absolute;
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--color-gray-dark);
  color: white;
  padding: 2px 6px;
  border-radius: 3px;
  font-size: 11px;
  white-space: nowrap;
  z-index: 1000;
}

.py-button svg {
  width: 14px;
  height: 14px;
}

.py-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

/* Monaco Editor container styles */
.py-monaco-container {
  flex: 1;
  overflow: hidden;
  border: 1px solid var(--color-border);
}

/* Force proper font rendering in Monaco */
.monaco-editor .view-line span {
  font-family: "Source Code Pro" !important;
}

/* Output section styles */
.py-output-section {
  min-height: 30%;
  border-top: 2px solid var(--color-border);
  background-color: var(--color-gray-medium-light);
  display: flex;
  flex-direction: column;
}

.py-output-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: var(--color-gray-medium);
  margin: 0.15rem; /* Even smaller margin */
  border-radius: 4px;
  border: 1px solid var(--color-border);
  overflow: hidden;
  min-height: 24px; /* Match the header height */
}

.py-output-header {
  padding: 0.15rem 0.5rem;
  background-color: var(--color-gray-medium-light);
  border-bottom: 1px solid var(--color-border);
  font-family: "Source Code Pro", monospace !important;
  font-size: 12px;
  color: var(--color-text);
  height: 18px;
  line-height: 18px;
  font-weight: 500;
  letter-spacing: 0.5px;
}

.py-output-content {
  padding: 0.25rem 0.5rem;
  font-family: "Source Code Pro", monospace;
  white-space: pre-wrap;
  color: var(--color-text);
  overflow-y: auto;
  flex: 1;
  font-size: 14px;
  line-height: 21px;
  min-height: 0;
}

.py-error {
  color: var(--color-error);
}

/* Diff view specific styles */
.py-diff-container {
  flex: 1;
  overflow: hidden;
  border-radius: 4px;
  border: 1px solid var(--color-border);
}

/* Ensure the Monaco diff editor takes full height */
.py-diff-container > div {
  height: 100% !important;
}

/* Custom scrollbar for output */
.py-output-content::-webkit-scrollbar {
  width: 12px;
}

.py-output-content::-webkit-scrollbar-track {
  background: var(--color-gray-medium);
}

.py-output-content::-webkit-scrollbar-thumb {
  background-color: var(--color-gray-dark);
  border-radius: 6px;
  border: 3px solid var(--color-gray-medium);
}

/* Adjust output container margins for both layouts */
.py-editor-container.vertical .py-output-container {
  margin: 0.25rem;
}

.py-editor-container.horizontal .py-output-container {
  margin: 0 0.25rem 0 0.25rem;
}

/* Make sure both sections fill available space */
.py-top-section,
.py-output-section {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.py-save-status {
  font-size: 12px;
  color: #666;
  margin-left: auto;
  min-width: 60px;
  text-align: right;
}

.py-stdout {
  overflow-x: auto;
  padding: 10px;
}

.py-stdout img {
  max-width: 100%;
  height: auto;
  margin: 2px 0;
  border-radius: 2px;
}

.py-stdout pre {
  margin: 0;
  white-space: pre-wrap;
  word-wrap: break-word;
  font-family: "Source Code Pro", monospace;
  font-size: 14px;
  line-height: 21px;
}

.py-error pre {
  margin: 0;
  white-space: pre-wrap;
  word-wrap: break-word;
  color: var(--color-error);
  font-family: "Source Code Pro", monospace;
  font-size: 14px;
  line-height: 21px;
}

/* Add markdown table styles similar to ChatMessages */
.py-stdout table {
  border-collapse: collapse;
  width: 100%;
  margin: 10px 0;
  background: var(--color-background);
  font-family: "Source Code Pro", monospace;
  font-size: 14px;
  line-height: 21px;
}

.py-stdout th,
.py-stdout td {
  border: 1px solid var(--color-border);
  padding: 8px;
  text-align: left;
}

.py-stdout th {
  background-color: var(--color-gray-medium-light);
  font-weight: 600;
}

.py-stdout tr:nth-child(even) {
  background-color: var(--color-gray-light);
}

.py-stdout tr:hover {
  background-color: var(--color-hover);
}

.py-output-image {
  max-width: 100%;
  height: auto;
  margin: 2px 0;
  border-radius: 2px;
}

/* Style all text content in the output section */
.py-output-content,
.py-output-content p,
.py-output-content span,
.py-output-content div,
.py-output-content code,
.py-output-content pre {
  font-family: "Source Code Pro", monospace !important;
  font-size: 14px;
  line-height: 21px;
}

/* Style markdown content */
.py-stdout,
.py-stdout p,
.py-stdout span,
.py-stdout div,
.py-stdout code,
.py-stdout pre,
.py-stdout td,
.py-stdout th {
  font-family: "Source Code Pro", monospace !important;
  font-size: 14px;
  line-height: 21px;
}

/* Update resize handle styles */
.py-resize-handle {
  width: 100%;
  height: 8px; /* Slightly taller for easier grabbing */
  background-color: var(--color-gray-medium-light);
  cursor: row-resize;
  position: relative;
  flex-shrink: 0;
  z-index: 1; /* Ensure it's above other elements */
  border-top: 1px solid var(--color-border);
  border-bottom: 1px solid var(--color-border);
}

.py-resize-handle:hover,
.dragging .py-resize-handle {
  background-color: var(--color-gray-medium);
}

.py-resize-handle::after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 30px;
  height: 2px;
  background-color: var(--color-gray-dark);
  border-radius: 1px;
}

/* Add these new styles */
.dragging {
  cursor: row-resize !important;
  user-select: none !important;
}

.dragging .py-monaco-container,
.dragging .py-output-container {
  pointer-events: none;
}

/* Ensure the content sections don't collapse */
.py-top-section,
.py-output-section {
  min-height: 0;
  overflow: hidden;
}
