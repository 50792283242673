.singlefile-popup {
  position: absolute;
  bottom: 80px;
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--color-background);
  border: 1px solid var(--color-border);
  border-radius: 8px;
  box-shadow: 0 2px 8px var(--color-shadow-medium);
  display: flex;
  align-items: center;
  padding: 8px;
  gap: 12px;
  z-index: 1000;
  min-width: 200px;
}

.singlefile-content {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  flex: 1;
}

.singlefile-filename {
  font-size: 13px;
  color: var(--color-text);
  font-weight: 500;
}

.singlefile-filename.clickable {
  cursor: pointer;
  color: var(--color-primary);
  text-decoration: underline;
}

.singlefile-filename.clickable:hover {
  color: var(--color-primary-dark);
}

.singlefile-ellipsis {
  color: var(--color-text);
  font-weight: normal;
}

.singlefile-button {
  padding: 2px 8px;
  border-radius: 4px;
  font-size: 12px;
  cursor: pointer;
  border: none;
  transition: all 0.2s ease;
  height: 20px;
  line-height: 16px;
  display: flex;
  align-items: center;
}

.singlefile-button.reject {
  background: none;
  color: var(--color-text);
  opacity: 0.7;
}

.singlefile-button.reject:hover {
  opacity: 1;
}

.singlefile-button.accept {
  background-color: var(--color-primary);
  color: var(--color-text);
}

.singlefile-button.accept:hover {
  background-color: var(--color-primary);
  opacity: 0.8;
}

.singlefile-nav-button {
  background: none;
  border: none;
  color: var(--color-text-light);
  cursor: pointer;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  min-width: 24px;
}

.singlefile-nav-button:hover {
  background-color: var(--color-hover-overlay);
  color: var(--color-text);
}

.singlefile-nav-button svg {
  width: 14px;
  height: 14px;
  flex-shrink: 0;
}

.singlefile-nav-filename {
  font-size: 12px;
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.singlefile-nav-button.left .singlefile-nav-filename {
  margin-left: 4px;
}

.singlefile-nav-button.right .singlefile-nav-filename {
  margin-right: 4px;
}
