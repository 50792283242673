.up-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--color-overlay-background);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.up-modal-content {
  background-color: var(--color-input-background);
  padding: 32px 40px;
  border-radius: 12px;
  width: 90%;
  max-width: 800px;
  height: 90vh;
  max-height: 900px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 8px 30px var(--color-shadow-medium);
  border: 1px solid var(--color-border-transparent);
  position: relative;
}

.up-modal-content h2 {
  color: var(--color-text);
  margin: 0 0 24px 0;
  font-size: 24px;
  font-weight: 600;
}

.up-fields-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex: 1;
  overflow-y: auto;
  padding-right: 12px;
  margin-bottom: 16px;
  scrollbar-width: thin;
  scrollbar-color: var(--color-text-light) var(--color-divider);
  padding-bottom: 80px;
  mask-image: linear-gradient(
    to bottom,
    var(--color-text) 85%,
    rgba(0, 0, 0, 0.8) 90%,
    rgba(0, 0, 0, 0.6) 95%,
    transparent 100%
  );
  -webkit-mask-image: linear-gradient(
    to bottom,
    var(--color-text) 85%,
    rgba(0, 0, 0, 0.8) 90%,
    rgba(0, 0, 0, 0.6) 95%,
    transparent 100%
  );
}

.up-fields-container::-webkit-scrollbar {
  width: 8px;
  background: var(--color-divider);
}

.up-fields-container::-webkit-scrollbar-track {
  background: var(--color-divider);
  border-radius: 4px;
}

.up-fields-container::-webkit-scrollbar-thumb {
  background-color: var(--color-text-light);
  border-radius: 4px;
  min-height: 40px;
}

.up-fields-container::-webkit-scrollbar-thumb:hover {
  background-color: var(--color-gray);
}

.up-section {
  margin-bottom: 8px;
}

.up-section-header {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 16px 20px;
  background-color: var(--color-tab-active);
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.2s ease, border-radius 0.3s ease;
}

.up-section-header:hover {
  background-color: var(--color-divider);
}

.up-section-title {
  color: var(--color-text);
  font-size: 18px;
  font-weight: 600;
  margin: 0;
  flex: 1;
}

.up-section-icon {
  color: var(--color-text-light);
  font-size: 20px;
  transition: transform 0.3s ease;
}

.up-section.expanded .up-section-icon {
  transform: rotate(180deg);
}

.up-category {
  background-color: var(--color-spec-background);
  border-radius: 0 0 8px 8px;
  max-height: 0;
  padding: 0 24px;
  margin-top: 2px;
  overflow: hidden;
  transition: all 0.25s ease-in-out;
  opacity: 0;
}

.up-section.expanded .up-category {
  max-height: none;
  padding: 24px;
  opacity: 1;
}

.up-category-content {
  opacity: 0;
  transform: translateY(-10px);
  transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
}

.up-section.expanded .up-category-content {
  opacity: 1;
  transform: translateY(0);
}

.up-field {
  display: flex;
  flex-direction: column;
  padding: 16px 0;
  border-bottom: 1px solid var(--color-divider);
}

.up-field-row {
  display: flex;
  align-items: center;
  gap: 24px;
  margin-bottom: 6px;
  width: 100%;
  flex-wrap: nowrap;
}

.up-field-row label {
  width: 250px;
  text-align: right;
  flex-shrink: 0;
  font-size: 14px;
  font-weight: 500;
  color: var(--color-text);
}

.up-field-description {
  font-size: 12px;
  color: var(--color-text-light);
  margin-left: calc(250px + 24px);
  margin-top: 4px;
  max-width: 275px;
  padding-right: 12px;
}

.up-field:last-child {
  border-bottom: none;
}

.up-field-content {
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex: 1;
}

.up-field label {
  font-size: 14px;
  font-weight: 500;
  color: var(--color-text);
  width: 250px;
  text-align: right;
  flex-shrink: 0;
}

.up-field-help {
  font-size: 12px;
  color: var(--color-text-light);
  margin-top: 4px;
  margin-left: calc(250px + 16px);
}

.up-input,
.up-dropdown {
  width: 275px;
  height: 36px;
  padding: 8px 12px;
  border: 1px solid var(--color-disabled);
  border-radius: 6px;
  background-color: var(--color-gray-ultra-light);
  font-size: 14px;
  text-align: center;
  flex-shrink: 0;
}

.up-input:hover,
.up-dropdown:hover {
  border-color: var(--color-text-light);
}

.up-input:focus,
.up-dropdown:focus {
  outline: none;
  border-color: var(--color-primary);
  box-shadow: 0 0 0 2px var(--color-secondary-shadow);
}

/* Save button styling */
.up-save-button {
  background-color: var(--color-primary);
  color: var(--color-gray-ultra-light);
  border: none;
  padding: 10px 28px;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.up-save-button:hover {
  background-color: var(--color-button-primary-hover);
}

.up-success-message {
  background-color: var(--color-success);
  border: 1px solid var(--color-success);
  color: var(--color-gray-ultra-light);
  padding: 8px 16px;
  border-radius: 4px;
  margin-bottom: 16px;
  text-align: center;
}

.up-success-message p {
  margin: 0;
}

/* Fix the footer at the bottom */
.up-modal-footer {
  position: sticky;
  bottom: 0;
  background-color: var(--color-input-background);
  padding: 16px 0;
  margin-top: auto;
  border-top: 1px solid var(--color-disabled);
  z-index: 1;
  display: flex;
  justify-content: flex-end;
  gap: 12px;
}

.up-reset-button {
  background-color: var(--color-text-light);
  color: var(--color-gray-ultra-light);
  border: none;
  padding: 10px 28px;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.up-reset-button:hover {
  background-color: var(--color-gray);
}

.up-description {
  color: var(--color-text-light);
  font-size: 14px;
  line-height: 1.5;
  margin: -16px 0 24px 0;
  padding-bottom: 16px;
  border-bottom: 1px solid var(--color-divider);
}

.up-unsaved-indicator {
  color: var(--color-primary);
  margin-left: 8px;
  font-size: 20px;
}

/* Optional: Style for the save button when there are unsaved changes */
.up-save-button.has-changes {
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 var(--color-secondary-shadow);
  }

  70% {
    box-shadow: 0 0 0 6px transparent;
  }

  100% {
    box-shadow: 0 0 0 0 transparent;
  }
}

/* Add a container for the main content to prevent shifting */
.up-main-content {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 0;
  padding-top: 16px;
}

.up-input[type="number"]::-webkit-outer-spin-button,
.up-input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Make dropdowns match input styling */
.up-dropdown {
  text-align-last: center;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 8px center;
  background-size: 16px;
  padding-right: 32px;
}

.up-protocol-group {
  margin-bottom: 16px;
  background-color: var(--color-gray-light);
  border-radius: 8px;
  overflow: hidden;
}

.up-protocol-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  cursor: pointer;
  background-color: var(--color-gray-medium-light);
  transition: background-color 0.2s ease;
}

.up-protocol-header:hover {
  background-color: var(--color-gray-medium);
}

.up-protocol-header h4 {
  margin: 0;
  font-size: 16px;
  font-weight: 500;
  color: var(--color-text);
}

.up-protocol-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
  background-color: var(--color-gray-extra-light);
}

.up-protocol-group.expanded .up-protocol-content {
  max-height: 1000px;
  padding: 16px;
}

.up-protocol-group.expanded .up-section-icon {
  transform: rotate(180deg);
}

.up-input-group {
  position: relative;
  display: inline-flex;
  align-items: center;
}

.up-input-suffix {
  position: absolute;
  right: 12px;
  color: #666;
  pointer-events: none;
  font-size: 14px;
}

/* Adjust the input padding to prevent overlap with suffix */
.up-input-group .up-input {
  padding-right: 36px;
}

.up-subsection {
  padding: 16px 0;
  border-bottom: 1px solid var(--color-border);
}

.up-subsection:last-child {
  border-bottom: none;
}

.up-subsection-title {
  color: var(--color-text);
  font-size: 15px;
  font-weight: 500;
  margin: 0 0 16px 0;
  padding-bottom: 8px;
  border-bottom: 1px solid var(--color-border);
}

/* Update existing styles */
.up-subsection .up-field {
  padding: 8px 0;
  border-bottom: none;
}

.up-subsection .up-field:last-child {
  padding-bottom: 0;
}

.up-protocol-section {
  margin: 8px 0;
  border-radius: 6px;
}

.up-protocol-section-header {
  color: var(--color-text-medium);
  font-size: 14px;
  font-weight: 500;
  padding: 12px 16px;
  background-color: var(--color-gray-light);
  border-radius: 6px 6px 0 0;
}

.up-protocol-section-content {
  padding: 16px;
  background-color: var(--color-gray-extra-light);
  border-radius: 0 0 6px 6px;
}

/* Remove any previous dividers */
.up-divider {
  display: none;
}

/* Style the optgroups in dropdowns */
.up-dropdown optgroup {
  font-weight: 600;
  color: var(--color-text-light);
  background-color: var(--color-gray-extra-light);
}

.up-dropdown option {
  font-weight: normal;
  color: var(--color-text);
  background-color: var(--color-background);
  padding: 8px;
}

/* Add this new style */
.up-section-description {
  color: var(--color-text-light);
  font-size: 14px;
  line-height: 1.5;
  margin: 0 0 16px 0;
  padding: 0 4px;
}

.up-saving-indicator {
  font-size: 14px;
  color: var(--color-text-light);
  font-weight: normal;
  margin-left: 12px;
  animation: fadeInOut 1s infinite;
}

@keyframes fadeInOut {
  0% {
    opacity: 0.6;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0.6;
  }
}

.up-input-wrapper {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.up-field-error {
  color: var(--color-error);
  font-size: 12px;
  margin-top: 4px;
}

.up-tooltip {
  max-width: 300px !important;
  z-index: 10 !important;
  opacity: 1 !important;
  background-color: var(--color-text) !important;
  color: var(--color-gray-ultra-light) !important;
  padding: 8px 12px !important;
  border-radius: 6px !important;
  font-size: 13px !important;
  line-height: 1.4 !important;
  box-shadow: 0 2px 8px var(--color-shadow-soft) !important;
  pointer-events: none !important;
}

.up-select-option {
  transition: background-color 0.2s ease;
  width: 100%;
}

.up-select-option:hover {
  background-color: var(--color-spec-background) !important;
}

/* Ensure tooltips appear above the modal */
[data-tooltip-id] {
  z-index: 1100;
}

/* Add styles for the floating tooltip */
[data-floating] {
  position: fixed !important;
}

/* Update the arrow to point downward */
.up-tooltip:after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: -6px;
  transform: translateX(-50%);
  border-width: 6px 6px 0 6px;
  border-style: solid;
  border-color: var(--color-background) transparent transparent transparent;
}

/* Add these new styles */
.up-select-container {
  position: relative;
  width: 275px;
  flex-shrink: 0;
}

.up-select-wrapper {
  width: 275px;
  flex-shrink: 0;
}

/* Ensure tooltips appear above the modal and select dropdown */
[data-tooltip-id] {
  z-index: 1100;
}

.up-select-option {
  transition: background-color 0.2s ease;
  width: 100%;
}

.up-select-option:hover {
  background-color: var(--color-spec-background) !important;
}

.up-warning-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--color-gray-ultra-light);
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0 4px 12px var(--color-shadow-soft);
  z-index: 1100;
  width: 400px;
  max-width: 90vw;
}

.up-warning-content {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.up-warning-content h3 {
  margin: 0;
  color: var(--color-text);
  font-size: 18px;
}

.up-warning-content p {
  margin: 0;
  color: var(--color-text-light);
  font-size: 14px;
  line-height: 1.5;
}

.up-warning-buttons {
  display: flex;
  gap: 12px;
  justify-content: flex-end;
  margin-top: 8px;
}

.up-save-button {
  background-color: var(--color-primary);
  color: var(--color-background);
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.up-save-button:hover {
  background-color: var(--color-button-primary-hover);
}

.up-discard-button {
  background-color: var(--color-error);
  color: var(--color-background);
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.up-discard-button:hover {
  background-color: var(--color-error);
}

.up-cancel-button {
  background-color: var(--color-gray-medium);
  color: var(--color-background);
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.up-cancel-button:hover {
  background-color: var(--color-gray-dark);
}

/* Add these new styles */
.up-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.up-modal-header h2 {
  margin: 0;
}

.up-close-button {
  background: none;
  border: none;
  font-size: 24px;
  color: var(--color-text-light);
  cursor: pointer;
  padding: 4px 8px;
  border-radius: 4px;
  transition: all 0.2s ease;
  line-height: 1;
}

.up-close-button:hover {
  background-color: var(--color-divider);
  color: var(--color-text);
}

/* Update the footer styles */
.up-modal-footer {
  position: sticky;
  bottom: 0;
  background-color: var(--color-input-background);
  padding: 16px 0;
  margin-top: auto;
  border-top: 1px solid var(--color-disabled);
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.up-footer-right {
  display: flex;
  gap: 12px;
}

/* Update the save button styles */
.up-save-button {
  background-color: var(--color-primary);
  color: var(--color-background);
  border: none;
  padding: 10px 28px;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.up-save-button:hover:not(:disabled) {
  background-color: var(--color-button-primary-hover);
}

.up-save-button:disabled {
  background-color: var(--color-disabled);
  cursor: not-allowed;
  opacity: 0.7;
}

.up-save-button.has-changes {
  animation: pulse 2s infinite;
}

/* Add these new styles for react-select components */
.up-select .select__control {
  min-height: 36px;
  height: 36px;
  width: 275px !important;
  border-color: var(--color-border);
  border-radius: 6px;
  box-shadow: none;
  flex-shrink: 0;
}

.up-select .select__control:hover {
  border-color: var(--color-text-light);
}

.up-select .select__value-container {
  height: 36px;
  padding: 0 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.up-select .select__input {
  margin: 0;
  padding: 0 6px;
  text-align: center;
  position: absolute;
  width: 100%;
}

.up-select .select__option {
  padding: 8px 12px;
  background-color: var(--color-background);
  color: var(--color-text);
  cursor: pointer;
  text-align: center;
}

.up-select .select__option--is-focused {
  background-color: var(--color-gray-light);
}

.up-select .select__option:hover {
  background-color: var(--color-gray-light);
}

.up-select .select__menu {
  margin-top: 4px;
  box-shadow: 0 2px 8px var(--color-shadow-medium);
  z-index: 10;
}

.up-select .select__menu-list {
  max-height: 200px;
}

.up-select .select__placeholder {
  color: var(--color-text-light);
  font-size: 14px;
  text-align: center;
}

.up-select .select__single-value {
  color: var(--color-text);
  font-size: 14px;
  text-align: center;
  width: calc(100% - 16px);
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  left: 8px;
  margin: 0;
  padding-left: 8px;
  padding-right: 8px;
}

.up-select .select__indicators-container {
  height: 34px;
}

.up-select .select__dropdown-indicator {
  padding: 8px;
}

.up-select {
  width: 275px !important;
  flex-shrink: 0;
}

/* Add these styles to handle vendor grouping in the ladder dropdown */
.up-select .select__group-heading {
  color: var(--color-text-light);
  font-size: 12px;
  font-weight: 600;
  padding: 8px 12px;
  background-color: var(--color-gray-light);
  margin: 0;
}

.up-select .select__group {
  padding: 0;
}

/* Ensure the ladder dropdown has enough width for the longer names */
.up-select[aria-label="DNA Ladder"] .select__control,
.up-select[aria-label="DNA Ladder"] {
  width: 300px !important;
}

.up-theme-toggle {
  display: flex;
  gap: 8px;
  background-color: var(--color-gray-medium-light);
  padding: 4px;
  border-radius: 6px;
  width: 275px;
}

.up-theme-button {
  flex: 1;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  background-color: transparent;
  color: var(--color-text-medium);
}

.up-theme-button:hover {
  background-color: var(--color-gray-medium);
  color: var(--color-text);
}

.up-theme-button.active {
  background-color: var(--color-primary);
  color: var(--color-background);
}

.up-theme-button.active:hover {
  background-color: var(--color-secondary);
}

/* Update modal content background for dark mode */
:root[data-theme="dark"] .up-modal-content {
  background-color: var(--color-gray-extra-light);
}

:root[data-theme="dark"] .up-modal-footer {
  background-color: var(--color-gray-extra-light);
  border-top-color: var(--color-border);
}

:root[data-theme="dark"] .up-section-header {
  background-color: var(--color-gray-light);
}

:root[data-theme="dark"] .up-category {
  background-color: var(--color-gray-medium-light);
}

:root[data-theme="dark"] .up-input,
:root[data-theme="dark"] .up-select__control {
  background-color: var(--color-gray-light);
  border-color: var(--color-border);
  color: var(--color-text);
}

:root[data-theme="dark"] .up-input:hover,
:root[data-theme="dark"] .up-select__control:hover {
  border-color: var(--color-gray-medium);
}

:root[data-theme="dark"] .up-input:focus,
:root[data-theme="dark"] .up-select__control--is-focused {
  border-color: var(--color-primary);
  box-shadow: 0 0 0 2px var(--color-secondary-shadow);
}

:root[data-theme="dark"] .up-select__menu {
  background-color: var(--color-gray-light);
}

:root[data-theme="dark"] .up-select__option {
  color: var(--color-text);
}

:root[data-theme="dark"] .up-select__option:hover,
:root[data-theme="dark"] .up-select__option--is-focused {
  background-color: var(--color-gray-medium);
}

:root[data-theme="dark"] .up-select__single-value {
  color: var(--color-text);
}

:root[data-theme="dark"] .up-close-button {
  color: var(--color-text-light);
}

:root[data-theme="dark"] .up-close-button:hover {
  background-color: var(--color-gray-medium);
  color: var(--color-text);
}

:root[data-theme="dark"] .up-select .select__control {
  background-color: var(--color-gray-light);
  border-color: var(--color-border);
  color: var(--color-text);
}

:root[data-theme="dark"] .up-select .select__menu {
  background-color: var(--color-gray-light);
}

:root[data-theme="dark"] .up-select .select__option {
  background-color: var(--color-gray-light);
  color: var(--color-text);
}

:root[data-theme="dark"] .up-select .select__option--is-focused,
:root[data-theme="dark"] .up-select .select__option:hover {
  background-color: var(--color-gray-medium);
}

:root[data-theme="dark"] .up-select .select__group-heading {
  background-color: var(--color-gray-medium-light);
  color: var(--color-text-light);
}

:root[data-theme="dark"] .up-select .select__menu-list {
  background-color: var(--color-gray-light);
}

:root[data-theme="dark"] .up-select .select__single-value {
  color: var(--color-text);
}

:root[data-theme="dark"] .up-select .select__placeholder {
  color: var(--color-text-light);
}

/* Add these new styles at the end of the file */

.up-ai-rules-description {
  color: var(--color-text-light);
  font-size: 14px;
  margin: 0 0 16px 0;
  line-height: 1.5;
}

.up-ai-rules-input {
  width: 100%;
  min-height: 120px;
  padding: 12px;
  border: 1px solid var(--color-disabled);
  border-radius: 6px;
  background-color: var(--color-gray-ultra-light);
  font-size: 14px;
  line-height: 1.5;
  resize: vertical;
  color: var(--color-text);
}

.up-ai-rules-input:hover {
  border-color: var(--color-text-light);
}

.up-ai-rules-input:focus {
  outline: none;
  border-color: var(--color-primary);
  box-shadow: 0 0 0 2px var(--color-secondary-shadow);
}

.up-character-count {
  text-align: right;
  color: var(--color-text-light);
  font-size: 12px;
  margin-top: 4px;
}

/* Dark mode support */
:root[data-theme="dark"] .up-ai-rules-input {
  background-color: var(--color-gray-light);
  border-color: var(--color-border);
  color: var(--color-text);
}

:root[data-theme="dark"] .up-ai-rules-input:hover {
  border-color: var(--color-gray-medium);
}

:root[data-theme="dark"] .up-ai-rules-input:focus {
  border-color: var(--color-primary);
  box-shadow: 0 0 0 2px var(--color-secondary-shadow);
}
