/* src/components/tools/ToolComplexityAnalyzer.css */
.tool-complexityanalyzer-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 32px;
  background-color: var(--color-gray-ultra-light);
  border-radius: 24px;
}

.tool-complexityanalyzer-title {
  font-size: 2.4rem;
  font-weight: 600;
  margin-bottom: 16px;
  color: var(--color-text);
}

.tool-complexityanalyzer-description {
  font-size: 1.2rem;
  color: var(--color-text-secondary);
  margin-bottom: 32px;
}

.tool-complexityanalyzer-upload {
  margin-bottom: 24px;
}

.tool-complexityanalyzer-options {
  margin: 24px 0;
  padding: 20px;
  background-color: white;
  border-radius: 8px;
  border: 1px solid var(--color-gray-medium-light);
}

.tool-complexityanalyzer-options h3 {
  margin-bottom: 16px;
  font-size: 18px;
  color: var(--color-text);
}

.tool-complexityanalyzer-radio {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.radio-group {
  display: flex;
  align-items: center;
  padding: 8px 12px;
}

.radio-group input[type="radio"] {
  width: 18px;
  height: 18px;
  margin-right: 12px;
  cursor: pointer;
  accent-color: var(--color-secondary);
}

.radio-group label {
  font-size: 14px;
  color: var(--color-text);
  cursor: pointer;
}

.tool-complexityanalyzer-button {
  background-color: var(--color-primary);
  color: white;
  border: none;
  padding: 12px 24px;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.2s ease;
  margin-top: 24px;
}

.tool-complexityanalyzer-button:hover {
  background-color: var(--color-secondary);
}

.tool-complexityanalyzer-button:disabled {
  background-color: var(--color-gray-light);
  cursor: not-allowed;
}

.tool-complexityanalyzer-warning {
  background-color: var(--color-error-light);
  color: var(--color-error);
  padding: 12px 16px;
  border-radius: 8px;
  margin: 16px 0;
  font-size: 14px;
  font-weight: 500;
  animation: fadeIn 0.3s ease;
}

.tool-complexityanalyzer-success {
  background-color: var(--color-success-light);
  color: var(--color-success);
  padding: 12px 16px;
  border-radius: 8px;
  margin-top: 16px;
  font-size: 14px;
  font-weight: 500;
  animation: fadeIn 0.3s ease;
}

.tool-complexityanalyzer-credits {
  margin: 16px 0 8px;
  font-size: 14px;
  color: var(--color-text-secondary);
  text-align: left;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}
